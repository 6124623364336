import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { getCustomerTodayActivity } from '../../services/dashboardService';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import Loading from '../../components/Loading/Loading';
import { ReactComponent as NewOrderIcon } from '../../assets/images/svg/neworderwhite.svg';
import { ReactComponent as ProcessOrderIcon } from '../../assets/images/svg/processorderwhite.svg';
import { ReactComponent as GlassIcon } from '../../assets/images/svg/hourglasswhite.svg';
import { ReactComponent as DelayIcon } from '../../assets/images/svg/delaywhite.svg';
import { ReactComponent as NavArrow } from '../../assets/images/svg/navArrow.svg';
import { Backdrop, Modal } from '@material-ui/core';
import OrderListModal from './OrderListModal';
import { Mediumtext, flex, flexcenter, flexspacebetween } from '../../font-variable';
import Button from '../../components/Button/Button';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MinContact = styled.div`
  position: relative;
  width: 100%;
`;

const ShotcutDiv = styled.div`
  width: 100%;
  height: 320px;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const HeadTitle = styled.h4`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0;
`;

const OrderNoWrap = styled.div`
  ${flex};
  ${(props) =>
    props.isOrdersAvailable &&
    css`
      cursor: pointer;
    `}
  svg {
    margin-left: 4px;
  }
`;

const TabelHead = styled.h3`
  ${Mediumtext};
  color: var(--black-light-color);
  font-weight: 600;
  display: block;
  white-space: nowrap;
  margin: 0;
`;

const TableIt = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:first-child td {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border-top: 1px solid var(--border-color);
  padding: 12px 8px;
  white-space: nowrap;
  text-transform: capitalize;
  &:first-child {
    width: 5%;
    padding-left: 0;
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
  }
  &:last-child {
    text-align: right;
    padding-right: 0;
    div {
      justify-content: end;
      span {
        margin: 0;
      }
    }
    svg path {
      fill: var(--black-light-color);
    }
  }
`;

const MainTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
  padding: 0px 16px;
  @media (max-width: 767px) {
    padding: 0 12px;
  }
`;

const IconView = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: var(--primary-color);
  ${flexcenter};
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  gap: 16px;
  position: relative;
  z-index: 0;
  padding: 16px 16px;
  margin-bottom: -5px;
  @media (max-width: 767px) {
    padding: 16px 12px;
  }
`;

const MainTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

class TodaysActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpenOrderListModal: false,
      orderList: undefined,
      title: undefined
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (!companyId) return;
    this.setState({ isLoading: true });
    await this.props.getCustomerTodayActivity(companyId);
    this.setState({ isLoading: false });
  };

  onViewFullLedger = () => {
    this.props.history.push('/ledger-report');
  };

  onOpenOrderListModal = (orderList, title) => {
    this.setState({ isOpenOrderListModal: true, orderList, title });
  };

  onCloseOrderListModal = () => {
    this.setState({ isOpenOrderListModal: false, orderList: undefined, title: undefined });
  };

  render() {
    const { isLoading, isOpenOrderListModal, orderList, title } = this.state;
    const { todaysActivity } = this.props.dashboard;
    const {
      pending_orders,
      due_orders,
      new_orders,
      processed_orders,
      processed_orders_list = [],
      pending_orders_list = [],
      new_orders_list = [],
      due_orders_list = []
    } = todaysActivity || {};
    return (
      <>
        <MinContact>
          <ShotcutDiv>
            <HeaderWrapper>
              <TabelHead>Today’s Activity</TabelHead>
              <Button createbtn isPrimary={true} title='View Full Ledger' onClick={this.onViewFullLedger} />
            </HeaderWrapper>
            {isLoading && !todaysActivity ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <>
                <MainTab>
                  <MainTable>
                    <TableIt>
                      <Tbody>
                        <Tr>
                          <Td>
                            <IconView>
                              <NewOrderIcon />
                            </IconView>
                          </Td>
                          <Td>New Orders</Td>
                          <Td>
                            <OrderNoWrap
                              isOrdersAvailable={new_orders_list?.length > 0}
                              onClick={
                                new_orders_list?.length > 0
                                  ? () => {
                                      this.onOpenOrderListModal(new_orders_list, 'New Orders');
                                    }
                                  : null
                              }>
                              <HeadTitle>{new_orders || 0}</HeadTitle>
                              {new_orders_list?.length > 0 && <NavArrow />}
                            </OrderNoWrap>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>
                            <IconView>
                              <ProcessOrderIcon />
                            </IconView>
                          </Td>
                          <Td>Processed Orders</Td>
                          <Td>
                            <OrderNoWrap
                              isOrdersAvailable={processed_orders_list?.length > 0}
                              onClick={
                                processed_orders_list?.length > 0
                                  ? () => {
                                      this.onOpenOrderListModal(processed_orders_list, 'Processed Orders');
                                    }
                                  : null
                              }>
                              <HeadTitle>{processed_orders || 0}</HeadTitle>
                              {processed_orders_list?.length > 0 && <NavArrow />}
                            </OrderNoWrap>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>
                            <IconView>
                              <GlassIcon />
                            </IconView>
                          </Td>
                          <Td>Pending Orders</Td>
                          <Td>
                            <OrderNoWrap
                              isOrdersAvailable={pending_orders_list?.length > 0}
                              onClick={
                                pending_orders_list?.length > 0
                                  ? () => {
                                      this.onOpenOrderListModal(pending_orders_list, 'Pending Orders');
                                    }
                                  : null
                              }>
                              <HeadTitle>{pending_orders || 0}</HeadTitle>
                              {pending_orders_list?.length > 0 && <NavArrow />}
                            </OrderNoWrap>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>
                            <IconView>
                              <DelayIcon />
                            </IconView>
                          </Td>
                          <Td>Delayed Orders</Td>
                          <Td>
                            <OrderNoWrap
                              isOrdersAvailable={due_orders_list?.length > 0}
                              onClick={
                                due_orders_list?.length > 0
                                  ? () => {
                                      this.onOpenOrderListModal(due_orders_list, 'Delayed Orders');
                                    }
                                  : null
                              }>
                              <HeadTitle>{due_orders || 0}</HeadTitle>
                              {due_orders_list?.length > 0 && <NavArrow />}
                            </OrderNoWrap>
                          </Td>
                        </Tr>
                      </Tbody>
                    </TableIt>
                  </MainTable>
                </MainTab>
              </>
            )}
          </ShotcutDiv>
        </MinContact>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className='modalcenter'
          open={isOpenOrderListModal}
          onClose={this.onCloseOrderListModal}
          closeAfterTransition={true}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <OrderListModal
            onClose={this.onCloseOrderListModal}
            orderList={orderList || []}
            title={title}
            history={this.props.history}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
});

export default connect(mapStateToProps, {
  getCustomerTodayActivity
})(TodaysActivity);
