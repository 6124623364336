import axios from 'axios';
import { REACT_APP_APIURL } from '../global/Environment';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import {
  setOrderListLoader,
  setCancelOrderList,
  setPendingOrderList,
  updateOrderFilteredList,
  setInvoicedOrderList
} from '../actions/orderActions';
import { getAPIResponseError } from '../helper/common';
import { loadListWithPagging } from './paginationService';
import { REQUEST_CANCEL_MESSAGE, SALES_ORDER_STATUS } from '../global/constants';

let ajaxOrderListRequest = null;

/**
 * @desc  Get Order list
 * @param {*} company_id company id
 */
export const getOrderList =
  (company_id, customer_id, isLoadPagging = false, isCancelOrder, isPendingOrder) =>
  async (dispatch) => {
    try {
      dispatch(clearResponseMessage(''));
      if (!company_id) return false;
      dispatch(setOrderListLoader(true));
      if (ajaxOrderListRequest) {
        ajaxOrderListRequest.cancel(REQUEST_CANCEL_MESSAGE);
      }
      ajaxOrderListRequest = axios.CancelToken.source();
      let params = { customer_id };
      const response = await axios.get(`${REACT_APP_APIURL}/customer/order-list/${company_id}`, {
        params,
        cancelToken: ajaxOrderListRequest.token
      });
      const { data } = response.data;
      let pendingOrdersList = [];
      let InvoicedOrderList = [];
      let cancelOrdersList = [];
      data?.forEach((x) => {
        //Set Pending Order List
        if (
          x?.status === SALES_ORDER_STATUS.OPEN ||
          (x?.status === SALES_ORDER_STATUS.PARTIALLY_PROCESSED && x?.total_pending_qty > 0)
        ) {
          if (x?.status === SALES_ORDER_STATUS.PARTIALLY_PROCESSED) {
            const updatedItems = x.pendingItems?.map((item) => {
              item.product.qty = item?.product?.pendingQty;
              return item;
            });
            pendingOrdersList.push({
              ...x,
              items: updatedItems,
              total_amount: x?.total_pending_amount,
              total_qty: x?.total_pending_qty
            });
          } else {
            pendingOrdersList.push(x);
          }
        }
        //Set Invoiced Order List
        if (
          x?.status === SALES_ORDER_STATUS.PROCESSED ||
          (x?.status === SALES_ORDER_STATUS.PARTIALLY_PROCESSED && x?.total_processed_qty > 0) ||
          (x?.status === SALES_ORDER_STATUS.PARTIALLY_CANCELLED && x?.total_pending_qty > 0)
        ) {
          if (
            x?.status === SALES_ORDER_STATUS.PARTIALLY_PROCESSED ||
            x?.status === SALES_ORDER_STATUS.PARTIALLY_CANCELLED
          ) {
            const updatedItems = x.processedItems?.map((item) => {
              item.product.qty = item?.product?.processedQty;
              return item;
            });
            InvoicedOrderList.push({
              ...x,
              items: updatedItems,
              total_amount: x?.total_processed_amount,
              total_qty: x?.total_processed_qty
            });
          } else {
            InvoicedOrderList.push(x);
          }
        }
        //Set Cancel Orders List
        if (
          x?.status === SALES_ORDER_STATUS.CANCELLED ||
          (x?.status === SALES_ORDER_STATUS.PARTIALLY_CANCELLED && x?.total_processed_qty > 0)
        ) {
          if (x?.status === SALES_ORDER_STATUS.PARTIALLY_CANCELLED) {
            const updatedItems = x.pendingItems?.map((item) => {
              item.product.qty = item?.product?.pendingQty;
              return item;
            });
            cancelOrdersList.push({
              ...x,
              items: updatedItems,
              total_amount: x?.total_pending_amount,
              total_qty: x?.total_pending_qty
            });
          } else {
            cancelOrdersList.push(x);
          }
        }
      });
      dispatch(setCancelOrderList(cancelOrdersList || []));
      dispatch(setPendingOrderList(pendingOrdersList || []));
      dispatch(setInvoicedOrderList(InvoicedOrderList || []));

      if (isLoadPagging) {
        if (isCancelOrder) {
          dispatch(updateOrderFilteredList(cancelOrdersList || []));
          dispatch(loadListWithPagging(cancelOrdersList || [], 1, true));
        } else if (isPendingOrder) {
          dispatch(updateOrderFilteredList(pendingOrdersList || []));
          dispatch(loadListWithPagging(pendingOrdersList || [], 1, true));
        } else {
          dispatch(updateOrderFilteredList(InvoicedOrderList || []));
          dispatch(loadListWithPagging(InvoicedOrderList || [], 1, true));
        }
      }
      return true;
    } catch (e) {
      if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
      dispatchOrderError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
      return false;
    } finally {
      dispatch(setOrderListLoader(false));
    }
  };

/**
 * @desc  cancel Order
 * @param {*} company_id company id
 */
export const cancelOrder = (company_id, order_id, obj, currentStatus) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setOrderListLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/customer/order-list/${company_id}/status/${order_id}`, obj);
    const { data } = response.data;
    if (data.status === SALES_ORDER_STATUS.CANCELLED) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchOrderError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};

/**
 * @desc  remove product
 * @param {*} company_id company id
 */
export const removeOrderProduct = (company_id, order_id, product_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setOrderListLoader(true));
    const response = await axios.delete(
      `${REACT_APP_APIURL}/customer/order-list/${company_id}/item/${order_id}/delete/${product_id}`
    );
    const { data } = response.data;
    if (data) {
      return data;
    }
  } catch (e) {
    dispatchOrderError(getAPIResponseError(e) || 'Unable to remove product, please try again later', dispatch);
    return null;
  } finally {
    dispatch(setOrderListLoader(false));
  }
};
function dispatchOrderError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
