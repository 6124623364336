// import { ReactComponent as LogoIcon } from '../../assets/images/svg/logobg.svg';
// import { ReactComponent as NavArrow } from '../../assets/images/svg/navArrow.svg';
import { ReactComponent as Dashboard } from '../../assets/images/svg/dashboard.svg';
import { ReactComponent as ReportCart } from '../../assets/images/svg/report.svg';
import {
  ALL_CATALOGS,
  CART,
  INVOICES,
  LEDGER_REPORTS,
  OUT_OF_STOCK_PRODUCTS,
  SIDEBAR_MENU_ALL_CATALOGS,
  SIDEBAR_MENU_DASHBOARD,
  SIDEBAR_MENU_ORDERS,
  SIDEBAR_MENU_REPORTS
} from '../../global/constants';
import {
  Wrapper,
  MenuItemLink,
  AccordionWrap,
  LogoWrap,
  Navbar,
  AccordionSummaryWrap,
  MainNav,
  TypographyText,
  AccordionDetailsList,
  TypographySubText,
  ProfileNav,
  DisplayName,
  StyledMenu,
  StyledMenu2,
  Menulist,
  ProfileEmail,
  ProfileName,
  ProfileNameBlock,
  CloseView,
  Overlay,
  CardtNum,
  ContactView,
  BottomView,
  ProfileView,
  RightSvg,
  LabelName,
  LabelValue,
  ProfileNameBlock2,
  Logodropdown
} from './style';
import { Link } from 'react-router-dom';
import { Component } from 'react';
import Avtar from '../../components/Avtar/Avtar';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/svg/arrowDown.svg';
import { ReactComponent as AllCatalogs } from '../../assets/images/svg/allCatalogs.svg';
import { ReactComponent as Orders } from '../../assets/images/svg/orders.svg';
import { ReactComponent as CartIcon } from '../../assets/images/svg/mycart.svg';
import { ReactComponent as ContactIcon } from '../../assets/images/svg/contactnew.svg';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { logout } from '../../services/authService';
import { getCustomerAccountManager } from '../../services/customerService';
import { connect } from 'react-redux';
import { toggleSideMenu } from '../../actions/SideMenuAction';
import { isEmpty } from 'lodash';
import { Backdrop, Modal } from '@material-ui/core';
import SettingsModal from './SettingsModal';
export class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded || SIDEBAR_MENU_DASHBOARD,
      anchorEl: null,
      isOpenProfileMenu: false,
      isOpenContactMenu: false,
      isOpenSettingModal: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      await this.props.getCustomerAccountManager(companyId);
    }
  };

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  handleChange = (accordion) => (isExpanded) => {
    let expanded;
    isExpanded ? (expanded = accordion) : (expanded = false);
    this.setState({ expanded });
  };

  openProfileMenu = (e) => {
    this.setState({ isOpenProfileMenu: true, anchorEl: e.currentTarget });
  };

  closeProfileMenu = (e) => {
    this.setState({ isOpenProfileMenu: false, anchorEl: null });
  };
  openContactMenu = (e) => {
    this.setState({ isOpenContactMenu: true, anchorEl: e.currentTarget });
  };

  closeContactMenu = (e) => {
    this.setState({ isOpenContactMenu: false, anchorEl: null });
  };
  onClickLogout = async () => {
    await this.props.logout();
  };

  onOpenSettingsModal = () => {
    this.setState({ isOpenSettingModal: true });
  };

  onCloseSettingsModal = () => {
    this.setState({ isOpenSettingModal: false });
    this.closeProfileMenu();
  };

  render() {
    const displayName = UserPreferenceSingleton.getInstance().getDisplayName();
    const displayEmail = UserPreferenceSingleton.getInstance().getEmail();
    const { expanded, anchorEl, isOpenProfileMenu, isOpenContactMenu, isOpenSettingModal } = this.state;
    const { isShowLayout } = this.props.sideMenu;
    const { cart } = this.props.cart;
    const { customerAccountManager } = this.props.customer;
    const totalCompanies = this.props.auth?.companyList?.length;
    const { customerSettings = {} } = this.props.customer;
    const { hide_price } = customerSettings.profile_settings || {};

    return (
      <Wrapper>
        <MainNav isShowLayout={isShowLayout}>
          <LogoWrap>
            <Logodropdown>
              {/* <LogoIcon /> */}
              <ProfileView onClick={this.openProfileMenu}>
                <Avtar name={displayName} size={28} />
                <DisplayName>{displayName}</DisplayName>
              </ProfileView>
            </Logodropdown>
            <CloseView>
              <CloseIcon onClick={this.toggleSideMenu} />
            </CloseView>
          </LogoWrap>
          <Navbar>
            {!hide_price && (
              <AccordionWrap
                expanded={expanded === SIDEBAR_MENU_DASHBOARD}
                onChange={this.handleChange(SIDEBAR_MENU_DASHBOARD)}>
                <MenuItemLink to={'/dashboard'} onClick={this.toggleSideMenu}>
                  <AccordionSummaryWrap>
                    <Dashboard />
                    <TypographyText>Dashboard</TypographyText>
                  </AccordionSummaryWrap>
                </MenuItemLink>
              </AccordionWrap>
            )}
            <AccordionWrap
              expanded={expanded === SIDEBAR_MENU_ALL_CATALOGS}
              onChange={this.handleChange(SIDEBAR_MENU_ALL_CATALOGS)}>
              <Link to={ALL_CATALOGS}>
                <AccordionSummaryWrap
                // expandIcon={<NavArrow />}
                >
                  <AllCatalogs />
                  <TypographyText>Catalogs</TypographyText>
                </AccordionSummaryWrap>
              </Link>

              <AccordionDetailsList onClick={this.toggleSideMenu}>
                <MenuItemLink to={ALL_CATALOGS} activeClassName='active_menu'>
                  <TypographySubText>All Catalogs</TypographySubText>
                </MenuItemLink>
              </AccordionDetailsList>
              <AccordionDetailsList onClick={this.toggleSideMenu}>
                <MenuItemLink to={'/catalogue'} activeClassName='active_menu'>
                  <TypographySubText>Live Catalogs</TypographySubText>
                </MenuItemLink>
              </AccordionDetailsList>
              {/* <AccordionDetailsList onClick={this.toggleSideMenu}>
                <MenuItemLink to={'/zero-qty-catalogue'} activeClassName='active_menu'>
                  <TypographySubText>Zero(0) Qty. Catalogs</TypographySubText>
                </MenuItemLink>
              </AccordionDetailsList> */}
              <AccordionDetailsList onClick={this.toggleSideMenu}>
                <MenuItemLink to={OUT_OF_STOCK_PRODUCTS} activeClassName='active_menu'>
                  <TypographySubText>Deactivated</TypographySubText>
                </MenuItemLink>
              </AccordionDetailsList>
            </AccordionWrap>
            {!hide_price && (
              <>
                <AccordionWrap
                  expanded={expanded === SIDEBAR_MENU_ORDERS}
                  onChange={this.handleChange(SIDEBAR_MENU_ORDERS)}>
                  <Link to={'/pending-orders'}>
                    <AccordionSummaryWrap
                    // expandIcon={<NavArrow />}
                    >
                      <Orders />
                      <TypographyText>Orders</TypographyText>
                    </AccordionSummaryWrap>
                  </Link>

                  <AccordionDetailsList onClick={this.toggleSideMenu}>
                    <MenuItemLink to={'/pending-orders'} activeClassName='active_menu'>
                      <TypographySubText>Pending Orders</TypographySubText>
                    </MenuItemLink>
                  </AccordionDetailsList>
                  <AccordionDetailsList onClick={this.toggleSideMenu}>
                    <MenuItemLink to={'/invoiced-orders'} activeClassName='active_menu'>
                      <TypographySubText>Invoiced Orders</TypographySubText>
                    </MenuItemLink>
                  </AccordionDetailsList>
                  <AccordionDetailsList onClick={this.toggleSideMenu}>
                    <MenuItemLink to={'/cancel-orders'} activeClassName='active_menu'>
                      <TypographySubText>Cancelled Orders</TypographySubText>
                    </MenuItemLink>
                  </AccordionDetailsList>
                </AccordionWrap>
                <AccordionWrap
                  expanded={expanded === SIDEBAR_MENU_REPORTS || expanded === LEDGER_REPORTS}
                  onChange={this.handleChange(SIDEBAR_MENU_REPORTS)}>
                  <Link to={INVOICES}>
                    <AccordionSummaryWrap
                    // expandIcon={<NavArrow />}
                    >
                      <ReportCart />
                      <TypographyText>Reports</TypographyText>
                    </AccordionSummaryWrap>
                  </Link>
                  <AccordionDetailsList onClick={this.toggleSideMenu}>
                    <MenuItemLink to={INVOICES} activeClassName='active_menu'>
                      <TypographySubText>Invoices</TypographySubText>
                    </MenuItemLink>
                  </AccordionDetailsList>
                  <AccordionDetailsList onClick={this.toggleSideMenu}>
                    <MenuItemLink to={LEDGER_REPORTS} activeClassName='active_menu'>
                      <TypographySubText>Ledger</TypographySubText>
                    </MenuItemLink>
                  </AccordionDetailsList>
                </AccordionWrap>
              </>
            )}
            <AccordionWrap expanded={expanded === CART} onChange={this.handleChange(CART)}>
              <MenuItemLink to={'/cart'} activeClassName='active_menu' onClick={this.toggleSideMenu}>
                <AccordionSummaryWrap>
                  <CartIcon />
                  <TypographyText>
                    My Cart
                    {cart?.length > 0 && (
                      <CardtNum>
                        <span>{cart?.length}</span>
                      </CardtNum>
                    )}
                  </TypographyText>
                </AccordionSummaryWrap>
              </MenuItemLink>
            </AccordionWrap>
            <ProfileNav>
              <BottomView>
                {(!isEmpty(customerAccountManager?.email) || !isEmpty(customerAccountManager?.phone_number)) && (
                  <ContactView onClick={this.openContactMenu}>
                    <ContactIcon />
                    <TypographyText>Account Manager</TypographyText>
                    <RightSvg>
                      <ArrowDown />
                    </RightSvg>
                  </ContactView>
                )}
                {/* <ProfileView onClick={this.openProfileMenu}>
                  <Avtar name={displayName} size={30} />
                  <DisplayName>{displayName}</DisplayName>
                  <RightSvg>
                    <ArrowDown />
                  </RightSvg>
                </ProfileView> */}
              </BottomView>
            </ProfileNav>
            <StyledMenu
              id='customized-menu'
              anchorEl={anchorEl}
              keepMounted
              open={isOpenProfileMenu}
              onClose={this.closeProfileMenu}>
              <ProfileNameBlock>
                <ProfileName>{displayName}</ProfileName>
                <ProfileEmail>{displayEmail}</ProfileEmail>
              </ProfileNameBlock>
              {totalCompanies > 1 && (
                <MenuItemLink to={'/select-organisation'}>
                  <Menulist>Switch Organization</Menulist>
                </MenuItemLink>
              )}
              <Menulist onClick={this.onOpenSettingsModal}>Settings</Menulist>
              <Menulist onClick={this.onClickLogout}>Logout</Menulist>
            </StyledMenu>
            <StyledMenu2
              id='customized-menu'
              anchorEl={anchorEl}
              keepMounted
              open={isOpenContactMenu}
              onClose={this.closeContactMenu}>
              {!isEmpty(customerAccountManager?.name) && (
                <ProfileNameBlock2>
                  <LabelName>Name</LabelName>
                  <LabelValue>{customerAccountManager?.name}</LabelValue>
                </ProfileNameBlock2>
              )}
              {!isEmpty(customerAccountManager?.email) && (
                <ProfileNameBlock2>
                  <LabelName>Email</LabelName>
                  <LabelValue>{customerAccountManager?.email}</LabelValue>
                </ProfileNameBlock2>
              )}
              {!isEmpty(customerAccountManager?.phone_number) && (
                <ProfileNameBlock2 last>
                  <LabelName>Phone</LabelName>
                  <LabelValue>{customerAccountManager?.phone_number}</LabelValue>
                </ProfileNameBlock2>
              )}
            </StyledMenu2>
          </Navbar>
        </MainNav>
        {this.props.children}
        {!isShowLayout && <Overlay onClick={this.toggleSideMenu}></Overlay>}
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={isOpenSettingModal}
          onClose={this.onCloseSettingsModal}
          closeAfterTransition={true}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <SettingsModal onClose={this.onCloseSettingsModal} />
        </Modal>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sideMenu: state.sideMenu,
  cart: state.cart,
  customer: state.customer
});

export default connect(mapStateToProps, {
  logout,
  toggleSideMenu,
  getCustomerAccountManager
})(Layout);
