import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import { CART } from '../../../global/constants';
import { createCatalogOrder } from '../../../services/catalogService';
import { removeCartItems, updateCartItems } from '../../../actions/CartAction';
import { ReactComponent as MenuIcon } from '../../../assets/images/svg/menuicon.svg';
import { ReactComponent as CloseRemove } from '../../../assets/images/svg/closeremove.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/closewhite.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/svg/plusicon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/svg/minusicon.svg';
import { Modal, Backdrop } from '@material-ui/core';
import OrderDetailsModal from '../OrderDetailsModal/OrderDetailsModal';
import MyCart from '../../../assets/images/empty.svg';
import { toggleSideMenu } from '../../../actions/SideMenuAction';
import {
  ContentCart,
  ContentCartWrapper,
  MainContent,
  MainWrapper,
  Table,
  EmptyDetail,
  ProductNav,
  EmptyContain,
  EmptyTitle,
  TextEmpty,
  EmptyImage,
  Cartcount,
  Tr,
  Th,
  Td,
  LeftWrap,
  MenuView,
  CatName,
  CartImg,
  NameList,
  CartName,
  NameSpan,
  CurrSpan,
  BtnView,
  LButton,
  ImgModal,
  ImgClose,
  RemoveButton
} from './style';
import Button from '../../../components/Button/Button';
import { currencyWithDecimal } from '../../../helper/common';
import UserPreferenceSingleton from '../../../helper/UserPreferenceSingleton';

export class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalQty: 0,
      totalAmt: 0,
      isOpenOrderDetailsModal: false,
      isOpenImageModal: false,
      selectedItem: undefined
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { cart } = this.props.cart;
    this.calculateTotalQuantityAndAmount(cart);
  };

  deleteitem = (index) => {
    const { cart } = this.props.cart;
    let newCart = JSON.parse(JSON.stringify(cart)) || [];
    newCart?.splice(index, 1);
    this.props.removeCartItems(newCart);
    UserPreferenceSingleton.getInstance().setCartItems(newCart);
    this.calculateTotalQuantityAndAmount(newCart);
  };

  openOrderDetailsModal = (e, cart) => {
    e.stopPropagation();
    this.setState({ isOpenOrderDetailsModal: true });
  };

  onClickShopNow = () => {
    this.props.history.push('/catalogue');
  };

  onClickPlusQty = (index) => {
    let { cart } = this.props.cart;
    if (cart[index].isFromCatalog) {
      cart[index].itemOrderedQty += 1;
      cart[index].totalAmt += Number(cart[index]?.totalPrice || 0);
      cart[index].orderedQty += Number(cart[index]?.products?.length);
    } else {
      cart[index].totalAmt += Number(cart[index]?.price_sales || 0);
      cart[index].orderedQty += Number(cart[index]?.orderQty);
    }
    this.props.updateCartItems(cart);
    UserPreferenceSingleton.getInstance().setCartItems(cart);
    this.calculateTotalQuantityAndAmount(cart);
  };

  onClickMinusQty = (index) => {
    let { cart } = this.props.cart;
    if (cart[index].isFromCatalog && cart[index].orderedQty > Number(cart[index]?.products?.length)) {
      cart[index].itemOrderedQty -= 1;
      cart[index].totalAmt -= Number(cart[index]?.totalPrice || 0);
      cart[index].orderedQty -= Number(cart[index]?.products?.length || 0);
    } else if (cart[index]?.orderedQty > cart[index]?.orderQty) {
      cart[index].totalAmt -= Number(cart[index]?.price_sales || 0);
      cart[index].orderedQty -= Number(cart[index]?.orderQty || 0);
    }
    this.props.updateCartItems(cart);
    UserPreferenceSingleton.getInstance().setCartItems(cart);
    this.calculateTotalQuantityAndAmount(cart);
  };
  openImageModal = (e, item) => {
    e.stopPropagation();
    this.setState({ isOpenImageModal: true, selectedItem: item });
  };
  closeImageModal = () => {
    this.setState({ isOpenImageModal: false, selectedItem: undefined });
  };
  closeOrderDetailsModal = () => {
    this.setState({ isOpenOrderDetailsModal: false });
  };

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  calculateTotalQuantityAndAmount = (cart) => {
    let totalQuantity = cart?.reduce((sum, val) => sum + Number(val?.orderedQty || 0), 0);
    let totalAmount = cart?.reduce((sum, val) => sum + Number(val?.totalAmt || 0), 0);
    this.setState({ totalQty: totalQuantity, totalAmt: totalAmount });
  };

  render() {
    const { cart } = this.props.cart;
    const { totalQty, totalAmt, isOpenOrderDetailsModal, isOpenImageModal, selectedItem } = this.state;
    const { customerSettings = {} } = this.props.customer;
    const { hide_price } = customerSettings.profile_settings || {};
    return (
      <>
        <Layout expanded={CART}>
          <MainWrapper>
            <MainContent>
              <ContentCart>
                <ProductNav>
                  <LeftWrap>
                    <MenuView onClick={this.toggleSideMenu}>
                      <MenuIcon />
                    </MenuView>
                    <CatName>My Cart</CatName>
                  </LeftWrap>
                  {cart?.length > 0 && (
                    <Button
                      small
                      className='place-order'
                      onClick={(e) => {
                        this.openOrderDetailsModal(e, cart);
                      }}
                      isPrimary={true}
                      title='Place Order'
                    />
                  )}
                </ProductNav>
                {cart?.length > 0 ? (
                  <ContentCartWrapper>
                    <Table className='my-cart'>
                      <thead>
                        <Tr>
                          <Th>SR No.</Th>
                          <Th>Name</Th>
                          <Th>QTY</Th>
                          <Th>{!hide_price && 'Amount'}</Th>
                          <Th></Th>
                        </Tr>
                      </thead>
                      <tbody>
                        {cart?.map((item, index) => {
                          return (
                            <Tr>
                              <Td>{index + 1}</Td>
                              <Td>
                                <NameList>
                                  <CartImg onClick={(e) => this.openImageModal(e, item)}>
                                    <img src={item?.gallery?.[0]?.url || item?.img} alt='product' />
                                  </CartImg>
                                  <CartName>
                                    <NameSpan>{item?.name}</NameSpan>
                                  </CartName>
                                </NameList>
                              </Td>
                              <Td>
                                <BtnView>
                                  <LButton
                                    isPrimary={true}
                                    onClick={() => {
                                      this.onClickMinusQty(index);
                                    }}>
                                    <MinusIcon />
                                  </LButton>
                                  <Cartcount>{item?.orderedQty}</Cartcount>
                                  <LButton
                                    isPrimary={true}
                                    onClick={() => {
                                      this.onClickPlusQty(index);
                                    }}>
                                    <PlusIcon />
                                  </LButton>
                                </BtnView>
                              </Td>
                              <Td>{!hide_price && <>₹ {currencyWithDecimal(item?.totalAmt)}</>}</Td>
                              <Td>
                                <RemoveButton onClick={() => this.deleteitem(index)} isPrimary={true}>
                                  <CloseRemove />
                                </RemoveButton>
                              </Td>
                            </Tr>
                          );
                        })}
                      </tbody>
                      <tbody>
                        <Tr>
                          <Td className='totalspan'>Total</Td>
                          <Td></Td>
                          <Td className='totalspan'>{totalQty}</Td>
                          <Td className='totalspan'>
                            {!hide_price && <CurrSpan>₹ {currencyWithDecimal(totalAmt)}</CurrSpan>}
                          </Td>
                          <Td></Td>
                        </Tr>
                      </tbody>
                      <tbody>
                        <Tr>
                          <Td></Td>
                          <Td></Td>
                          <Td></Td>
                          <Td className='totalspan'>+ GST & Shipping charges if any</Td>
                          <Td></Td>
                        </Tr>
                      </tbody>
                    </Table>
                  </ContentCartWrapper>
                ) : (
                  <EmptyContain>
                    <EmptyDetail>
                      <EmptyImage src={MyCart} alt='my cart'></EmptyImage>
                      <EmptyTitle>Your cart is empty!</EmptyTitle>
                      <TextEmpty>There is nothing in your cart. Let's add some items.</TextEmpty>
                      <Button small isPrimary={true} title='Shop Now' onClick={this.onClickShopNow} />
                    </EmptyDetail>
                  </EmptyContain>
                )}
              </ContentCart>
              <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className='modal'
                open={isOpenOrderDetailsModal}
                onClose={this.closeOrderDetailsModal}
                closeAfterTransition={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500
                }}>
                <div className='paper'>
                  <OrderDetailsModal handleClose={this.closeOrderDetailsModal} />
                </div>
              </Modal>
            </MainContent>
          </MainWrapper>
        </Layout>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={isOpenImageModal}
          onClose={this.closeImageModal}
          closeAfterTransition={true}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}>
          <>
            <ImgModal>
              <img src={selectedItem?.gallery?.[0]?.url || selectedItem?.img} alt='product' />
            </ImgModal>
            <ImgClose>
              <CloseIcon onClick={this.closeImageModal} />
            </ImgClose>
          </>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  message: state.message,
  customer: state.customer,
  cart: state.cart
});

export default connect(mapStateToProps, {
  createCatalogOrder,
  removeCartItems,
  updateCartItems,
  toggleSideMenu
})(Cart);
