import * as Actions from './../actions/types';
const initialState = {
  email: '',
  password: '',
  emailForget: '',
  emailReset: '',
  passwordReset: '',
  confirmPassword: '',
  name: ''
};

export const membershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.UPDATE_MEMBERSHIP_ERROR:
      let cloneState = JSON.parse(JSON.stringify(state));
      cloneState[action.payload.propsName] = action.payload.message;
      return { ...cloneState };
    case Actions.CLEAR_MEMBERSHIP_ERROR:
      return initialState;
    default:
      return state;
  }
};
