import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import ReportDateFilter from '../../components/ReportDateTimeFilter/ReportDateFilter';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getTotalOrderChartData } from '../../services/dashboardService';
import { TOTAL_ORDERS_TOOLTIP } from '../../global/constants';
import { Mediumtext, Normaltext, Smalltext, flexspacebetween } from '../../font-variable';

const MinContact = styled.div`
  height: 100%;
  flex: 1 1 0;
  padding: 0 24px 24px 24px;
  @media (max-width: 767px) {
    padding: 0 16px 16px;
  }
`;

const HeaderWrapper = styled.div`
  padding-bottom: 24px;
  ${flexspacebetween};
  @media (max-width: 767px) {
    padding-bottom: 16px;
  }
`;

const ShotcutDiv = styled.div`
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 8px;
  padding: 16px 16px;
  display: inline-block;
  width: 100%;
  @media (max-width: 767px) {
    padding: 16px 12px;
  }
`;

const LeftHead = styled.div`
  padding: 0 24px 0 0;
  @media (max-width: 767px) {
    padding: 0 16px 0 0;
  }
`;

const TabelHead = styled.span`
  ${Mediumtext};
  font-weight: 600;
  color: var(--black-light-color);
`;

const DateFilter = styled.div`
  margin-left: auto;
`;

const ChartWrapper = styled.div`
  .custom-tooltip {
    background: var(--black-light-color);
    border-radius: 2px;
    padding: 3px 8px;
    p {
      ${Smalltext};
      color: var(--secondary-color);
    }
  }
  .recharts-legend-wrapper {
    bottom: -5px !important;
  }
  .recharts-layer .recharts-cartesian-axis-tick {
    ${Normaltext};
    color: var(--grey-color);
    text-align: center;
  }
  ul {
    li {
      span {
        ${Normaltext};
        color: var(--grey-color) !important;
      }
      svg {
        width: 10px;
      }
    }
  }
`;

var tooltip;
const CustomToolTip = ({ active, payload }) => {
  if (active) {
    if (!tooltip || !payload) return null;
    let count = 0;
    const { payload: payloadItem } = payload?.[0] || {};
    switch (tooltip) {
      case TOTAL_ORDERS_TOOLTIP.Cancelled:
        count = payloadItem?.total_cancelled_count;
        break;
      case TOTAL_ORDERS_TOOLTIP.Fulfilled:
        count = payloadItem?.total_received_count;
        break;
      default:
        break;
    }
    return (
      <div className='custom-tooltip'>
        <p className='label'>{` Count: ${count || 0}`}</p>
        <p className='label'>{` Date: ${payloadItem?.date || ''}`}</p>
      </div>
    );
  }

  tooltip = null;
  return null;
};
class TotalOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 5,
      fromDate: this.getFirstDateOfTheMonth(),
      toDate: new Date()
    };
  }

  getFirstDateOfTheMonth = (date) => {
    if (date) date = new Date(date);
    else date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  onChange = async (option, fromDate, toDate) => {
    const fromDateString = moment(fromDate).format('YYYY-MM-DD');
    const toDateString = moment(toDate).format('YYYY-MM-DD');
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      this.setState({ isLoading: true, option });
      await this.props.getTotalOrderChartData(companyId, fromDateString, toDateString);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { totalOrderChartData = [] } = this.props.dashboard;
    const { fromDate, toDate, option } = this.state;
    return (
      <>
        <MinContact>
          <ShotcutDiv>
            <HeaderWrapper>
              <LeftHead>
                <TabelHead>Total Orders</TabelHead>
              </LeftHead>
              <DateFilter>
                <ReportDateFilter
                  option={option}
                  fromDate={fromDate}
                  toDate={toDate}
                  onChange={this.onChange}
                  backgroundColor={'#f9f9f9'}
                />
              </DateFilter>
            </HeaderWrapper>
            <ChartWrapper>
              <ResponsiveContainer width='100%' height={400}>
                <BarChart barGap={500} barCategoryGap='100%' data={totalOrderChartData}>
                  <XAxis dataKey='date' tickMargin={10} />
                  <YAxis tickMargin={10} tickLine={false} />
                  <Legend
                    align='right'
                    iconType='ciclre'
                    wrapperStyle={{ position: 'absolute', top: 'auto', right: option === 9 ? '250px' : '0' }}
                  />
                  <Tooltip content={<CustomToolTip />} cursor={false} />
                  <CartesianGrid vertical={false} />
                  <Bar
                    radius={[4, 4, 0, 0]}
                    dataKey='total_cancelled_count'
                    fill='var(--red-color)'
                    name='Cancel'
                    barSize={10}
                    onMouseEnter={() => (tooltip = TOTAL_ORDERS_TOOLTIP.Cancelled)}
                  />
                  <Bar
                    radius={[4, 4, 0, 0]}
                    dataKey='total_received_count'
                    fill='var(--green-color)'
                    name='Fulfilled'
                    barSize={10}
                    onMouseEnter={() => (tooltip = TOTAL_ORDERS_TOOLTIP.Fulfilled)}
                  />
                </BarChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </ShotcutDiv>
        </MinContact>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
});

export default connect(mapStateToProps, { getTotalOrderChartData })(TotalOrders);
