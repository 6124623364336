import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as MyImage } from '../../assets/images/svg/inputicon.svg';
import { ReactComponent as DateIcon } from '../../assets/images/svg/dateicon.svg';
import Button from '../../components/Button/Button';
import DatePicker from '../../components/DateTime/DatePicker';
import { Smalltext, flex, flexspacebetween } from '../../font-variable';

const MenuItemWrapper = styled.div`
  padding: 0;
`;

const HeaderRightWrapper = styled.div`
  background: var(--background-dropdown);
  border-radius: 6px;
  ${flexspacebetween};
  cursor: pointer;
  padding: 8px 10px;
  ${(props) =>
    props.backgroundColor &&
    css`
      /* background: ${props.backgroundColor}; */
    `}
`;

const Title = styled.h4`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #81878e;
  margin: 0 6px 0 0;
`;

const DateView = styled.div`
  display: inline-flex;
  padding-right: 5px;
`;

const PopView = styled.div``;

const Border = styled.div`
  border-bottom: 1px solid var(--border-color);
  margin: 12px 0;
`;

const Wrapper = styled.div`
  padding: 0 10px;
`;

const DateTitle = styled.h5`
  ${Smalltext};
  margin: 0 0 6px 0;
  color: var(--grey-color);
  ${(props) =>
    props.margin &&
    css`
      margin-top: 10px;
    `}
`;

const ButtonWrapper = styled.div`
  ${flex};
  gap: 8px;
  margin: 12px 10px 6px 10px;
  button {
    margin: 0;
  }
`;

const selectionConstant = {
  TODAY: 1,
  YESTERDAY: 2,
  LAST_7_DAYS: 3,
  LAST_30_DAYS: 4,
  THIS_MONTH: 5,
  LAST_MONTH: 6,
  THIS_WEEK: 7,
  LAST_WEEK: 8,
  CUSTOM_RANGE: 9
};

const selectionOptions = {
  1: 'Today',
  2: 'Yesterday',
  3: 'Last 7 days',
  4: 'Last 30 days',
  5: 'This Month',
  6: 'Last Month',
  7: 'This Week',
  8: 'Last Week'
};

const ReportDateFilter = (props) => {
  const { onChange, loading } = props;

  const defaultOptions = useMemo(() => {
    const defaultData = {};
    if (props?.option) {
      defaultData['option'] = parseInt(props.option);
      defaultData['fromDate'] = props.fromDate;
      defaultData['toDate'] = props.toDate;
    }
    // if (!defaultData.option) {
    //   const datePreferences = UserPreferenceSingleton.getInstance().getReportDatePreferences();
    //   if (datePreferences) {
    //     defaultData['option'] = parseInt(datePreferences.option);
    //     defaultData['fromDate'] = datePreferences.fromDate;
    //     defaultData['toDate'] = datePreferences.toDate;
    //   }
    // }
    return defaultData;
  }, [props]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [option, setOption] = useState(defaultOptions.option || selectionConstant.TODAY);
  const [fromDate, setFromDate] = useState(defaultOptions.fromDate || new Date());
  const [toDate, setToDate] = useState(defaultOptions.toDate || new Date());
  const [showCustomOptions, setShowCustomOptions] = useState(false);
  const [fromDateforPicker, setFromDateforPicker] = useState(defaultOptions.fromDate || new Date());
  const [toDateforPicker, setToDateforPicker] = useState(defaultOptions.toDate || new Date());

  useEffect(() => {
    if (onChange) {
      onChange(option, new Date(fromDate), new Date(toDate));
    }
  }, [onChange, option, fromDate, toDate]);

  const onOpenReports = useCallback((e) => {
    setMenuAnchorEl(e.currentTarget);
    setIsReportsOpen(true);
  }, []);

  const onCloseReports = useCallback(() => {
    setMenuAnchorEl(null);
    setIsReportsOpen(false);
  }, []);

  const addDays = useCallback((date, number) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + number);
    return newDate;
  }, []);

  const getDate = useCallback((date) => {
    if (date) date = new Date(date);
    else date = new Date();
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }, []);

  const setDateByOption = useCallback(
    (option) => {
      if (!option || option === selectionConstant.CUSTOM_RANGE) return;

      const today = new Date();
      const day = today.getDay();
      const month = today.getMonth();
      const year = today.getFullYear();

      if (option === selectionConstant.TODAY) {
        //Today
        setFromDate(today);
        setToDate(today);
      } else if (option === selectionConstant.YESTERDAY) {
        //Yesterday
        const yesterday = addDays(today, -1);
        setFromDate(yesterday);
        setToDate(yesterday);
      } else if (option === selectionConstant.LAST_7_DAYS) {
        //Last 7 Days,Today included so -6
        setFromDate(addDays(today, -6));
        setToDate(today);
      } else if (option === selectionConstant.LAST_30_DAYS) {
        //Last 30 Days,Today included so -29
        setFromDate(addDays(today, -29));
        setToDate(today);
      } else if (option === selectionConstant.THIS_MONTH) {
        //This month
        const firstDayOfMonth = getDate(new Date(year, month, 1));
        setFromDate(firstDayOfMonth);
        setToDate(today);
      } else if (option === selectionConstant.LAST_MONTH) {
        //Last month
        const firstDayOfMonth = getDate(new Date(year, month - 1, 1));
        const LastDayofMonth = addDays(getDate(new Date(year, month, 1)), -1);
        setFromDate(firstDayOfMonth);
        setToDate(LastDayofMonth);
      } else if (option === selectionConstant.THIS_WEEK) {
        //This Week
        const firstDayOfCurrentWeek = addDays(today, -1 * day + 1);
        setFromDate(firstDayOfCurrentWeek);
        setToDate(today);
      } else if (option === selectionConstant.LAST_WEEK) {
        //Last Week
        const firstDayOfCurrentWeek = addDays(today, -1 * day + 1);
        const lastDayOfLastWeek = addDays(firstDayOfCurrentWeek, -1);
        const firstDayOfLastWeek = addDays(lastDayOfLastWeek, -6);
        setFromDate(firstDayOfLastWeek);
        setToDate(lastDayOfLastWeek);
      }
    },
    [addDays, getDate]
  );

  useEffect(() => {
    setFromDateforPicker(fromDate);
    setToDateforPicker(toDate);
  }, [fromDate, toDate]);

  const handleChange = useCallback(
    (optionNo, fromDate, toDate) => {
      onCloseReports();
      if (optionNo !== option) {
        setOption(optionNo);
        setDateByOption(optionNo);
      }
      if (optionNo === selectionConstant.CUSTOM_RANGE) {
        setFromDate(fromDate);
        setToDate(toDate);
      }
    },
    [onCloseReports, setDateByOption, option]
  );

  // useEffect(() => {
  //   const data = {
  //     option: option,
  //     fromDate: fromDate,
  //     toDate: toDate,
  //   };
  //   // UserPreferenceSingleton.getInstance().setReportDatePreferences(data);
  // }, [option, fromDate, toDate]);

  const formattedFromDate = moment(fromDate).format('DD MMM YYYY');
  const formattedToDate = moment(toDate).format('DD MMM YYYY');

  return (
    <>
      <HeaderRightWrapper onClick={onOpenReports} backgroundColor={props.backgroundColor}>
        <DateView>
          <DateIcon />
        </DateView>
        <Title>
          {option !== selectionConstant.CUSTOM_RANGE
            ? selectionOptions[option]
            : `${formattedFromDate} - ${formattedToDate}`}
        </Title>
        <MyImage />
      </HeaderRightWrapper>
      <Menu
        id='simple-menu-2'
        anchorEl={menuAnchorEl}
        keepMounted
        open={isReportsOpen}
        onClose={onCloseReports}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MenuItemWrapper>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.TODAY);
            }}>
            Today
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.YESTERDAY);
            }}>
            Yesterday
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.LAST_7_DAYS);
            }}>
            Last 7 Days
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.LAST_30_DAYS);
            }}>
            Last 30 days
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.THIS_MONTH);
            }}>
            This Month
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.LAST_MONTH);
            }}>
            Last Month
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.THIS_WEEK);
            }}>
            This Week
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange(selectionConstant.LAST_WEEK);
            }}>
            Last Week
          </MenuItem>
          <MenuItem onClick={() => setShowCustomOptions(!showCustomOptions)}>Custom Range</MenuItem>
          {showCustomOptions && (
            <PopView>
              <Border></Border>
              <Wrapper>
                <DateTitle>From</DateTitle>
                <DatePicker
                  autoOk={true}
                  value={new Date(fromDateforPicker)}
                  onChange={(date) => {
                    setFromDateforPicker(date);
                  }}
                />
                <DateTitle margin>To</DateTitle>
                <DatePicker
                  autoOk={true}
                  value={new Date(toDateforPicker)}
                  onChange={(date) => {
                    setToDateforPicker(date);
                  }}
                />
              </Wrapper>
              <ButtonWrapper>
                <Button
                  title='Apply'
                  onClick={() => {
                    handleChange(selectionConstant.CUSTOM_RANGE, fromDateforPicker, toDateforPicker);
                  }}
                  type='button'
                  disabled={loading}
                  isPrimary={true}
                  small
                />
                <Button
                  isPrimary={false}
                  createbtn
                  title='Cancel'
                  type='button'
                  onClick={onCloseReports}
                  disabled={loading}
                />
              </ButtonWrapper>
            </PopView>
          )}
        </MenuItemWrapper>
      </Menu>
    </>
  );
};

export default ReportDateFilter;
