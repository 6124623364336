import axios from 'axios';
import jwt_decode from 'jwt-decode';
import {
  setCurrentUser,
  setAuthLoader,
  clearAuthData,
  setSelectedCompany,
  setCompanyList
} from '../actions/authActions';
import { clearResponseMessage, setErrorMessage, setSuccessMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { clearToken, saveToken } from '../helper/authTokenHelpers';
import { isEmail, getAPIResponseError, isEmpty } from '../helper/common';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import { clearUser, trackActivity } from './analyticsService';
import { clearMembershipError, updateMembershipError } from '../actions/membershipActions';
/**
 * @desc Login - Get User Token
 * @param {*} obj Data Obj
 */
export const login = (payload) => async (dispatch) => {
  try {
    dispatch(clearMembershipError());
    dispatch(clearResponseMessage(''));
    let validationError = false;
    if (!payload) {
      dispatch(updateMembershipError({ propsName: 'email', message: 'Username is Required' }));
      validationError = true;
    } else if (!payload.email) {
      dispatch(updateMembershipError({ propsName: 'email', message: 'Email is Required' }));
      validationError = true;
    } else if (isEmail(payload.email) === false) {
      dispatch(updateMembershipError({ propsName: 'email', message: 'Invalid email' }));
      validationError = true;
    }
    if (isEmpty(payload.password)) {
      dispatch(updateMembershipError({ propsName: 'password', message: 'Password is Required' }));
      validationError = true;
    }
    if (validationError) {
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/customer/auth/login`, payload);
    const { data } = response.data;
    dispatch(setLoginToken(data.token));
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Unable to login please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Signup - signup user and set login information after successfuly signup
 * @param {*} obj Data Obj
 */
export const signup = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!obj) {
      dispatchAuthError('Email is Required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchAuthError('Email is Required', dispatch);
      return;
    } else if (isEmail(obj.email) === false) {
      dispatchAuthError('Invalid email', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    } else if (!obj.confirmPassword) {
      dispatchAuthError('Confirm password is Required', dispatch);
      return;
    } else if (obj.password !== obj.confirmPassword) {
      dispatchAuthError('Password and Confirm Password must be same', dispatch);
      return;
    }

    dispatch(setAuthLoader(true));

    const response = await axios.post(`${REACT_APP_APIURL}/customer/auth/signup`, obj);
    const { data } = response.data;
    dispatch(setLoginToken(data.token));
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Unable to signup, please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc  Get current user profile
 */
export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/user/me`);
    const { data } = response.data;
    if (data) {
      dispatch(setCurrentUser(data));
    } else {
      dispatch(setCurrentUser({}));
    }
    return data;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Auth - change Password
 * @param {*} obj Data Obj
 */
export const changePassword = (payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!payload) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    } else if (!payload.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    } else if (!payload.new_password) {
      dispatchAuthError('New Password is Required', dispatch);
      return;
    } else if (!payload.confirm_password) {
      dispatchAuthError('Confirm Password is Required', dispatch);
      return;
    } else if (payload.new_password !== payload.confirm_password) {
      dispatchAuthError('New Password and Confirm Password must be same', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/user/update-password`, payload);
    if (response.data.status === 'ok') {
      dispatchAuthsucess('Password Changed SucessFull', dispatch);
    }
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc ForgotPassword - forgot User Password
 * @param {*} obj Data Obj
 */
export const forgotPassword = (obj) => async (dispatch) => {
  try {
    dispatch(clearMembershipError());
    dispatch(clearResponseMessage(''));
    let validationError = false;
    if (!obj) {
      dispatch(updateMembershipError({ propsName: 'emailForget', message: 'Email is Required' }));
      validationError = true;
    } else if (!obj.email) {
      dispatch(updateMembershipError({ propsName: 'emailForget', message: 'Email is Required' }));
      validationError = true;
    } else if (isEmail(obj.email) === false) {
      dispatch(updateMembershipError({ propsName: 'emailForget', message: 'Invalid email' }));
      validationError = true;
    }
    if (validationError) return;
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/auth/forgot-password`, obj);
    const { message } = response.data;
    dispatchAuthsucess(message, dispatch);
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Unable to Sent Email, please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc ResetPassword - Reset Password
 * @param {*} obj Data Obj
 */
export const resetPassword = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!obj) {
      dispatchAuthError('Email is Required', dispatch);
      return;
    } else if (!obj.email) {
      dispatchAuthError('Email is Required', dispatch);
      return;
    } else if (isEmail(obj.email) === false) {
      dispatchAuthError('Invalid email', dispatch);
      return;
    } else if (!obj.password) {
      dispatchAuthError('Password is Required', dispatch);
      return;
    } else if (!obj.confirm_password) {
      dispatchAuthError('Confirm Password is Required', dispatch);
      return;
    } else if (obj.password !== obj.confirm_password) {
      dispatchAuthError('New Password and Confirm Password must be same', dispatch);
      return;
    }
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/auth/reset-password`, obj);
    const { data } = response;
    if (data) {
      dispatchAuthsucess('Password updated successfully, Please retry login with the updated password.', dispatch);
    }
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Unable to Reset Password, please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc Auth - Update current user profile
 * @param {*} obj Data Obj
 */
export const updateUserProfile = (obj) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const response = await axios.post(`${REACT_APP_APIURL}/user/profile`, obj);
    const { data } = response;
    if (data.status === 'ok') {
      dispatchAuthsucess('User profile updated successfully', dispatch);
    }
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

/**
 * @desc company - Get company List
 * @param {*} obj Data Obj
 */
export const getCompanyList = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    dispatch(setAuthLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/customer/company`);
    const { data } = response.data;
    if (data.length > 0) {
      const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
      let selectedCompany = {};
      if (!companyId) selectedCompany = data[0];
      else selectedCompany = data.find((x) => x.id === companyId);
      dispatch(setCompanyList(data));
      dispatch(setSelectedCompany(selectedCompany));
      UserPreferenceSingleton.getInstance().setCompanyId(selectedCompany.id);
    }
    return true;
  } catch (e) {
    dispatchAuthError(getAPIResponseError(e) || 'Unable to get company please try again', dispatch);
    return false;
  } finally {
    dispatch(setAuthLoader(false));
  }
};

function dispatchAuthsucess(msg, dispatch) {
  dispatch(setSuccessMessage(msg));
}

function dispatchAuthError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}

/**
 * @desc set login token and set user
 */
export const setLoginToken = (access_token) => (dispatch) => {
  // save auth deteils and set token in header for request
  saveToken(access_token);
  // Decode token to get user data
  const decoded = jwt_decode(access_token);
  // Set current user
  UserPreferenceSingleton.getInstance().setEmail(decoded.email);
  UserPreferenceSingleton.getInstance().setDisplayName(decoded.name);
  dispatch(setCurrentUser(decoded));
};

/**
 * @desc Log user out
 * @param source for analytics
 */
export const logout = (source) => (dispatch) => {
  /**
   * Remove token from localStorage
   * Remove auth header for future requests
   * Set current user to {} which will set isAuthenticated to false
   */
  clearToken();
  dispatch(clearAuthData());
  //clear user for analytics
  clearUser();
  UserPreferenceSingleton.getInstance().clearStoredUserData();
  trackActivity('signed out', { source: source });

  //clear reducer data
};
