import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as DateIcon } from '../../assets/images/svg/dashdateicon.svg';
// import SVGIcon from '../../assets/svg/SVGIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 13,
      lineHeight: 1,
      color: 'var(--grey-color)',
      border: 'none',
      '& .MuiButtonBase-root': {
        position: 'relative'
      },
      '& .MuiInputBase-input': {
        paddingLeft: 0
      },
      '&.MuiInput-underline:before,&.MuiInput-underline:after': {
        display: 'none'
      },
      '&.MuiInput-underline .MuiInputBase-input': {
        border: '1px solid var(--secondary-border-color)',
        borderRadius: 4,
        padding: '6px 6px 6px 10px',
        height: 'auto',
        color: 'var(--black-light-color)',
        '&:focus': {
          border: '1px solid var(--input-border-hover-color)'
        },
        '&:hover': {
          border: '1px solid var(--input-border-hover-color)'
        }
      },
      '&.MuiInput-underline .MuiIconButton-root': {
        padding: '0px 6px 3px 0px',
        borderRadius: '0px',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '& .MuiInputAdornment-root': {
        position: 'absolute',
        top: 2,
        right: 0,
        bottom: 0,
        maxHeight: '27px',
        height: 'auto'
      }
    }
  }
}));

const DatePicker = ({ label, value, format = 'dd/MM/yyyy', onChange, ...props }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        margin='none'
        label={label}
        value={value}
        format={format}
        onChange={onChange}
        // keyboardIcon={<SVGIcon name='icon-calendar' fill='var(--gray-icon-color)' size={18} />}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        classes={{
          root: classes.root,
          checked: classes.checked
        }}
        keyboardIcon={<DateIcon />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  format: PropTypes.string
};
