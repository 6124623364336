import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { MenuItem, Select } from '@material-ui/core';
import { ReactComponent as LeftArrow } from '../../assets/images/svg/Leftarrow.svg';
import { ReactComponent as RightArrow } from '../../assets/images/svg/Rightarrow.svg';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { itemPerPage } from '../../data/raw';
import { Smalltext, flex, flexcenter, flexspacebetween } from '../../font-variable';
import { isEmpty } from '../../helper/common';

const FooterList = styled.div`
  ${flexspacebetween};
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 24px 14px;
  z-index: 5;
  background-color: var(--secondary-color);
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
  ${(props) =>
    props.isSelectedItems &&
    css`
      @media (max-width: 640px) {
        flex-direction: column;
        gap: 10px;
      }
    `}
`;

const FooterForm = styled.form``;

const PerPage = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  margin-left: 8px;
`;

const PageNo = styled.span`
  ${Smalltext};
  color: var(--grey-color);
`;

const ArrowButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 2px;
  margin: 0px 8px;
  ${flexcenter};
  border: 1px solid var(--secondary-border-color);
  background-color: var(--secondary-color);
  &:last-child {
    margin: 0;
  }
  svg {
    width: 12px;
    height: 12px;
  }
  :hover {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    transition: 0.3s all ease;
    cursor: pointer;
    svg {
      path {
        stroke: var(--secondary-color);
      }
    }
  }
  &:disabled {
    background: var(--border-color);
    border: 1px solid var(--border-color);
    box-shadow: none;
    svg {
      path {
        stroke: var(--grey-color);
      }
    }
  }
`;

const LeftSide = styled.div`
  ${flex};
`;

const RightSide = styled.div`
  ${flex};
`;

const SelectWrap = styled(Select)`
  width: 100%;
  background: var(--secondary-color);
  color: #393945 !important;
  border: 1px solid var(--secondary-border-color);
  border-radius: 0px;
  font-family: Inter !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  min-width: 55px;
  min-height: 28px;
  &:hover::before {
    border-bottom: none !important;
  }
  &::before,
  &::after {
    border-bottom: none !important;
  }
`;

const MenuItemList = styled(MenuItem)``;

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordsPerPage: 10,
      firstRecord: 0,
      lastRecord: 0,
      isShow: true,
      isPrev: false,
      isNext: false,
      current: props.current || 1,
      total: props.total || 1,
      totalRecords: props.totalRecords || 1
    };
  }

  componentDidMount() {
    this.getPageRecord();
    this.checkIsNextPrev();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.current !== this.props.current ||
      newProps.total !== this.props.total ||
      newProps.totalRecords !== this.props.totalRecords
    ) {
      this.setState({ current: newProps.current, total: newProps.total, totalRecords: newProps.totalRecords }, () => {
        this.checkIsNextPrev();
      });
    }
  }

  onNext = () => {
    if (this.state.isNext) {
      this.checkIsNextPrev();
      const { current } = this.state;
      if (this.props.onChange) this.props.onChange(current + 1);
    }
  };

  onPrev = () => {
    if (this.state.isPrev) {
      this.checkIsNextPrev();
      const { current } = this.state;
      if (this.props.onChange) this.props.onChange(current - 1);
    }
  };

  onChangeRecordPerPage = (e) => {
    const perPageRecord = e.target.value;
    UserPreferenceSingleton.getInstance().setPageCount(perPageRecord);
    this.getPageRecord();
    if (this.props.onChange) this.props.onChange(1);
  };

  getPageRecord = () => {
    let recordsPerPage = UserPreferenceSingleton.getInstance().getPageCount();
    this.setState({ recordsPerPage });
  };

  checkIsNextPrev = () => {
    let { current, total, totalRecords, recordsPerPage, firstRecord, lastRecord, isShow, isPrev, isNext } = this.state;
    if (current > 1) isPrev = true;
    else isPrev = false;

    if (current < total) isNext = true;
    else isNext = false;

    firstRecord = (current - 1) * recordsPerPage + 1;
    if (firstRecord <= 0) firstRecord = 1;

    lastRecord = (current - 1) * recordsPerPage + recordsPerPage;
    if (lastRecord > totalRecords) lastRecord = totalRecords;

    if (totalRecords <= 10) isShow = false;
    else isShow = true;

    this.setState({ firstRecord, lastRecord, isShow, isPrev, isNext });
  };

  render() {
    const { textPerPage, bulkAction } = this.props;
    const { isShow, firstRecord, lastRecord, isPrev, isNext, recordsPerPage, totalRecords } = this.state;
    if (!isShow) return null;
    return (
      <FooterList isSelectedItems={!isEmpty(bulkAction)}>
        <LeftSide>
          <FooterForm>
            <SelectWrap value={recordsPerPage} onChange={this.onChangeRecordPerPage}>
              {itemPerPage &&
                itemPerPage.length > 0 &&
                itemPerPage.map((item) => (
                  <MenuItemList key={String(item.value)} value={item.value}>
                    {item.text}
                  </MenuItemList>
                ))}
            </SelectWrap>
          </FooterForm>
          <PerPage>{textPerPage} per page</PerPage>
        </LeftSide>
        {bulkAction}
        <RightSide>
          <PageNo>
            {firstRecord} - {lastRecord} of {totalRecords}
          </PageNo>
          <ArrowButton onClick={this.onPrev} disabled={!isPrev}>
            <LeftArrow />
          </ArrowButton>
          <ArrowButton onClick={this.onNext} disabled={!isNext}>
            <RightArrow />
          </ArrowButton>
        </RightSide>
      </FooterList>
    );
  }
}

export default Pagination;
