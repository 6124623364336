import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Layout from '../../components/Layout/Layout';
import { Backdrop, Menu, MenuItem, Modal } from '@material-ui/core';
import {
  CANCEL_ORDERS,
  ORDER_STATUS,
  PENDING_ORDERS,
  SALES_ORDER_STATUS,
  SIDEBAR_MENU_ORDERS
} from '../../global/constants';
// import { ReactComponent as Excel } from '../../assets/images/svg/exel.svg';
// import { ReactComponent as Pdf } from '../../assets/images/svg/pdf.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/svg/menuicon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import { ReactComponent as NoteIcon } from '../../assets/images/svg/note.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/svg/delete.svg';
// import { ReactComponent as VerticalMoreIcon } from '../../assets/images/svg/Verticalmore.svg';
import { currencyWithDecimal, isEmpty } from '../../helper/common';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/Loading/Loading';
import Message from '../../components/Message';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getOrderList, cancelOrder, removeOrderProduct } from '../../services/orderService';
import { updateOrderFilteredList } from '../../actions/orderActions';
import { ReactComponent as SearchIcon } from '../../assets/images/svg/searchmobile.svg';
import { loadListWithPagging } from '../../services/paginationService';
import { ReactComponent as HorizantalIcon } from '../../assets/images/svg/Horizantalmore.svg';
import {
  MainWrapper,
  SearchInput,
  TableIt,
  MainTab,
  MainTable,
  MainContent,
  ContentBlock,
  HeadTitle,
  SpanTag,
  ProductNav,
  CatName,
  LeftWrap,
  RightWrap,
  Thead,
  Td,
  Tr,
  Tbody,
  Th,
  MinContact,
  EmptyContain,
  EmptyDetail,
  EmptyImage,
  TextEmpty,
  MenuView,
  AmountNub,
  SkuRow,
  LText,
  RText,
  ImgSku,
  SkuDetails,
  HeaderWrapper,
  ItemLeft,
  CloseView,
  HeaderHeading,
  SkuModal,
  SkuText,
  Tooltips,
  AmountDiv,
  AmountTag,
  SearchView,
  SearchIconView,
  SerchWrap,
  SearchViewMobile,
  InvoiceNub,
  ErrorWrap,
  LoadView
} from './style';
import EmptyLogo from '../../assets/images/empty-orders.svg';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import { Dialog } from '@material-ui/core';
import { toggleSideMenu } from '../../actions/SideMenuAction';
import SearchBox from '../../components/SearchBox/SearchBox';
import { ReactComponent as CloseIcon2 } from '../../assets/images/svg/closeicon-small.svg';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: undefined,
      isMenuOpen: false,
      menuAnchorEl: null,
      selectedItem: undefined,
      isDeleteConfirmation: false,
      searchText: '',
      isCancelOrders: false,
      isPendingOrders: false,
      isRemoveProductConfirmation: false,
      itemId: undefined,
      isOpenSkuModal: false,
      isShowMobileSearch: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    const customerId = UserPreferenceSingleton.getInstance().getCustomerId();
    const isCancelOrder = this.props.match.url === CANCEL_ORDERS;
    const isPendingOrder = this.props.match.url === PENDING_ORDERS;
    this.setState({ isCancelOrders: isCancelOrder, isPendingOrders: isPendingOrder }, async () => {
      if (companyId) {
        const result = await this.props.getOrderList(companyId, customerId, true, isCancelOrder, isPendingOrder);
        if (result) {
          this.setFilteredList();
        }
      }
    });
  };

  setFilteredList = () => {
    const { paginatedList } = this.props.pagination;
    this.setState({ filteredList: paginatedList });
  };

  loadListWithPaggingIndex = async (index, isSetTotalRecords = false) => {
    const { filteredDataList } = this.props.orderList;
    await this.props.loadListWithPagging(filteredDataList, index, isSetTotalRecords);
    this.setFilteredList();
  };

  clearSerachText = () => {
    const { isShowMobileSearch } = this.state;
    if (isShowMobileSearch) this.setState({ isShowMobileSearch: false });
    this.setState({ searchText: '' });
    this.filterOrder('');
  };

  onSearchChange = (e) => {
    const searchText = e.target.value;
    this.setState({ searchText });
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      this.filterOrder(searchText);
    }, 1000);
  };

  filterOrder = async (searchText) => {
    let newList = [];
    searchText = searchText.trim().toLowerCase();
    const { cancelOrderList, pendingOrderList, invoicedOrderList } = this.props.orderList;
    const { isCancelOrders, isPendingOrders } = this.state;
    let orderList = isCancelOrders ? cancelOrderList : isPendingOrders ? pendingOrderList : invoicedOrderList;
    if (!isEmpty(searchText)) {
      newList = orderList.filter(
        (x) =>
          x.items[0]?.product?.name?.trim().toLowerCase().includes(searchText) ||
          x?.contact?.first_name?.trim().toLowerCase().includes(searchText) ||
          x?.contact?.last_name?.trim().toLowerCase().includes(searchText) ||
          x?.contact?.company_name?.trim().toLowerCase().includes(searchText) ||
          x?.number?.trim().toLowerCase().includes(searchText)
      );
    } else {
      newList = orderList;
    }

    await this.props.updateOrderFilteredList(newList);
    this.loadListWithPaggingIndex(1, true);
  };

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  onOpenMoreMenu = (e, item) => {
    e.stopPropagation();
    this.setState({ isMenuOpen: true, menuAnchorEl: e.currentTarget, selectedItem: item });
  };

  onClickSku = (e, item) => {
    e.stopPropagation();
    this.setState({ isOpenSkuModal: true, menuAnchorEl: e.currentTarget, selectedItem: item });
  };

  onClickInvoiceNumber = (invoiceId) => {
    if (invoiceId) this.props.history.push(`/invoices/invoice-preview/${invoiceId}`);
  };

  onCloseSkuModal = () => {
    this.setState({ isOpenSkuModal: false, menuAnchorEl: null, selectedItem: undefined });
  };

  onCloseMoreMenu = () => {
    this.setState({ isMenuOpen: false, menuAnchorEl: null, selectedItem: undefined });
  };

  onClickCancel = async () => {
    this.onOpenConfirmationDialog();
  };

  onOpenConfirmationDialog = () => {
    this.setState({ isDeleteConfirmation: true });
  };

  onOpenRemoveProductDialog = (e, order, itemId) => {
    e.stopPropagation();
    this.setState({ isRemoveProductConfirmation: true, itemId: itemId, selectedItem: order });
  };

  onCloseConfirmationDialog = () => {
    this.setState({ isDeleteConfirmation: false, isRemoveProductConfirmation: false });
  };

  onCancelConfirmation = () => {
    this.onCloseMoreMenu();
    this.onCloseConfirmationDialog();
  };

  onCancelOrder = async () => {
    const { selectedItem } = this.state;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (selectedItem && selectedItem.id && companyId) {
      const currentStatus = selectedItem?.status;
      const obj = {
        status: SALES_ORDER_STATUS.CANCELLED
      };
      const result = await this.props.cancelOrder(companyId, selectedItem.id, obj, currentStatus);
      this.onCancelConfirmation();
      if (result) {
        this.loadData();
      }
    }
  };

  onRemoveProduct = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    const { selectedItem, itemId, filteredList } = this.state;
    if (companyId && selectedItem?.id && itemId) {
      if (selectedItem?.status === SALES_ORDER_STATUS.OPEN && selectedItem?.items?.length === 1) {
        this.onCancelOrder();
        return;
      }
      const updatedItem = await this.props.removeOrderProduct(companyId, selectedItem?.id, itemId);
      this.onCancelConfirmation();
      if (updatedItem) {
        const index = filteredList?.findIndex((x) => x?.id === updatedItem?.id);
        if (index !== -1) {
          if (updatedItem?.status === SALES_ORDER_STATUS.PROCESSED) filteredList?.splice(index, 1);
          else filteredList[index] = updatedItem;
        }
        await this.props.updateOrderFilteredList(filteredList);
        this.loadListWithPaggingIndex(1, true);
      }
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return (
        <ErrorWrap>
          <Message text={resError} type={'error'} />
        </ErrorWrap>
      );
    }
  }
  onClickMobileSearch = () => {
    const { isShowMobileSearch } = this.state;
    this.setState({ isShowMobileSearch: !isShowMobileSearch });
  };

  render() {
    const { loading } = this.props.orderList;
    const { currentPage, totalPage, totalRecords } = this.props.pagination;
    const {
      filteredList,
      isDeleteConfirmation,
      menuAnchorEl,
      isMenuOpen,
      searchText,
      isPendingOrders,
      isCancelOrders,
      isRemoveProductConfirmation,
      isOpenSkuModal,
      selectedItem,
      isShowMobileSearch
    } = this.state;
    return (
      <Layout expanded={SIDEBAR_MENU_ORDERS}>
        <MainWrapper>
          <MainContent>
            <ProductNav>
              <LeftWrap>
                <MenuView onClick={this.toggleSideMenu}>
                  <MenuIcon />
                </MenuView>
                <CatName>
                  {isCancelOrders ? 'Cancelled Orders' : isPendingOrders ? 'Pending Orders' : 'Invoiced Orders'}
                </CatName>
              </LeftWrap>
              <RightWrap>
                {/* <Excel />
                <Pdf className='pdf' /> */}
                <SearchView>
                  <SearchBox
                    value={searchText}
                    onSearchChange={this.onSearchChange}
                    clearSearch={this.clearSerachText}
                    placeholder='Search Order'
                  />
                </SearchView>
                <SearchIconView onClick={this.onClickMobileSearch}>
                  <SearchIcon />
                </SearchIconView>
                {/* <VerticalMoreIcon /> */}
              </RightWrap>
            </ProductNav>
            {loading && (!filteredList || filteredList?.length === 0) ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <ContentBlock isPagination={totalRecords > 10}>
                {isShowMobileSearch && (
                  <SerchWrap>
                    <SearchViewMobile>
                      <SearchInput
                        type='text'
                        name='search'
                        value={searchText}
                        onChange={this.onSearchChange}
                        autoComplete='off'
                        placeholder={'Search Product'}></SearchInput>
                      <CloseIcon2 className='close' onClick={this.clearSerachText} />
                    </SearchViewMobile>
                  </SerchWrap>
                )}
                {!loading && (!filteredList || filteredList.length === 0) && (
                  <EmptyContain>
                    <EmptyDetail>
                      <EmptyImage src={EmptyLogo} />
                      <TextEmpty>You don't have any orders!</TextEmpty>
                    </EmptyDetail>
                  </EmptyContain>
                )}
                {filteredList && filteredList?.length > 0 && (
                  <MainTab className={totalRecords < 10 && 'table-space'}>
                    {this.renderErrors()}
                    <MainTable>
                      <TableIt>
                        <Thead>
                          <Tr>
                            <Th>Number</Th>
                            <Th>Date</Th>
                            <Th>Sku</Th>
                            {isPendingOrders && <Th>Status</Th>}
                            <Th>Price</Th>
                            {isPendingOrders && <Th></Th>}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredList &&
                            filteredList?.length > 0 &&
                            filteredList?.map((item, i) => {
                              const { status, invoices } = item;
                              return (
                                <Tr key={item?.id + i}>
                                  <Td>
                                    <HeadTitle>
                                      {item?.number}
                                      {(status === SALES_ORDER_STATUS.PROCESSED ||
                                        (status === SALES_ORDER_STATUS.PARTIALLY_PROCESSED && !isPendingOrders) ||
                                        (status === SALES_ORDER_STATUS.PARTIALLY_CANCELLED && !isCancelOrders)) &&
                                        invoices?.length > 0 && (
                                          <>
                                            <InvoiceNub isInvoiceLabel={true}>
                                              Invoice Number:
                                              {invoices?.map((x, i) => {
                                                return (
                                                  <span
                                                    onClick={() => {
                                                      this.onClickInvoiceNumber(x?.id);
                                                    }}>
                                                    {x?.number} {i === invoices?.length - 1 ? '' : ', '}
                                                  </span>
                                                );
                                              })}
                                            </InvoiceNub>
                                          </>
                                        )}
                                    </HeadTitle>
                                  </Td>
                                  <Td>
                                    <HeadTitle>{moment(item?.date).format('DD MMM YYYY')}</HeadTitle>
                                  </Td>
                                  <Td>
                                    {item?.items?.map((x) => {
                                      return (
                                        <SpanTag>
                                          <p
                                            onClick={(e) => {
                                              this.onClickSku(e, x?.product);
                                            }}>
                                            {x?.product?.sku}*<span>{x?.product?.qty}</span>
                                          </p>
                                          {isPendingOrders && (
                                            <AmountNub
                                              onClick={(e) => {
                                                this.onOpenRemoveProductDialog(e, item, x?.id);
                                              }}>
                                              <DeleteIcon />
                                            </AmountNub>
                                          )}
                                        </SpanTag>
                                      );
                                    })}
                                  </Td>
                                  {isPendingOrders && (
                                    <Td>
                                      <HeadTitle>{ORDER_STATUS[item?.status]}</HeadTitle>
                                    </Td>
                                  )}
                                  <Td>
                                    <AmountDiv>
                                      <AmountTag>₹ {currencyWithDecimal(item?.total_amount)}</AmountTag>
                                      {item?.notes && (
                                        <Tooltips title={item?.notes} placement='right' arrow={true}>
                                          <NoteIcon />
                                        </Tooltips>
                                      )}
                                    </AmountDiv>
                                  </Td>
                                  {isPendingOrders && (
                                    <Td>
                                      <HorizantalIcon
                                        onClick={(e) => {
                                          this.onOpenMoreMenu(e, item);
                                        }}
                                      />
                                    </Td>
                                  )}
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </TableIt>
                    </MainTable>
                    <Pagination
                      textPerPage={'Orders'}
                      current={currentPage}
                      total={totalPage}
                      totalRecords={totalRecords}
                      onChange={this.loadListWithPaggingIndex}
                    />
                  </MainTab>
                )}
              </ContentBlock>
            )}
          </MainContent>
          <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={isOpenSkuModal}
            onClose={this.onCloseSkuModal}
            closeAfterTransition={true}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}>
            <SkuModal>
              <HeaderWrapper>
                <ItemLeft>
                  <HeaderHeading>SKU</HeaderHeading>
                </ItemLeft>
                <CloseView>
                  <CloseIcon onClick={this.onCloseSkuModal} />
                </CloseView>
              </HeaderWrapper>
              {selectedItem && (
                <SkuRow>
                  <SkuDetails>
                    <SkuText>
                      <LText> Name :</LText>
                      <RText>{selectedItem?.name}</RText>
                    </SkuText>
                    <SkuText>
                      <LText> Catalog Name :</LText>
                      <RText>{selectedItem?.catalogue}</RText>
                    </SkuText>
                    <SkuText>
                      <LText> Sku :</LText> <RText>{selectedItem?.sku}</RText>
                    </SkuText>
                    <SkuText>
                      <LText> Price :</LText>
                      <RText>₹ {currencyWithDecimal(selectedItem?.price_sales)}</RText>
                    </SkuText>
                  </SkuDetails>
                  <ImgSku>
                    <img src={selectedItem?.gallery?.[0]?.url} alt='product' />
                  </ImgSku>
                </SkuRow>
              )}
            </SkuModal>
          </Modal>
          <Menu
            id='simple-menu'
            anchorEl={menuAnchorEl}
            keepMounted={true}
            open={isMenuOpen}
            onClose={this.onCloseMoreMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <MinContact>
              <MenuItem onClick={this.onClickCancel}>Cancel</MenuItem>
            </MinContact>
          </Menu>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby='confirmation-dialog-title'
            open={isDeleteConfirmation}
            onClose={this.onCancelConfirmation}>
            <ConfirmationModal
              loading={loading}
              message={'Are you sure want to cancel this Order?'}
              confirmButtonText='Confirm'
              onDelete={this.onCancelOrder}
              onCancel={this.onCancelConfirmation}
            />
          </Dialog>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby='confirmation-dialog-title'
            open={isRemoveProductConfirmation}
            onClose={this.onCancelConfirmation}>
            <ConfirmationModal
              loading={loading}
              message={'Are you sure want to Remove this Product?'}
              confirmButtonText='Confirm'
              onDelete={this.onRemoveProduct}
              onCancel={this.onCancelConfirmation}
            />
          </Dialog>
        </MainWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  pagination: state.pagination,
  orderList: state.orderList
});

export default connect(mapStateToProps, {
  getOrderList,
  loadListWithPagging,
  updateOrderFilteredList,
  cancelOrder,
  removeOrderProduct,
  toggleSideMenu
})(OrderList);
