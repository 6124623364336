import * as Actions from './../actions/types';
import { isEmpty } from './../helper/common';

const initialState = {
  isAuthenticated: false,
  user: {},
  user_profile: {},
  errors: {},
  loading: false,
  selectedCompany: {},
  companyList: undefined
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_AUTH_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case Actions.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case Actions.SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload
      };
    case Actions.SET_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload
      };
    case Actions.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };
    case Actions.GET_AUTH_VALIDATION_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case Actions.CLEAR_AUTH_DATA:
      return initialState;
    default:
      return state;
  }
};
