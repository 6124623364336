import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Mediumtext } from '../../font-variable';

const PrimaryButton = styled.button`
  ${Mediumtext}
  text-align: center;
  cursor: pointer;
  padding: 9px 10px;
  border-radius: 6px;
  outline: none;
  user-select: none;
  text-transform: capitalize;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--secondary-color);
  transition: 0.3s all ease;
  :hover {
    background: var(--primary-hover-color);
    border: 1px solid var(--primary-hover-color);
  }

  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
      padding: 9px;
      margin-top: 10px;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 13px;
      line-height: 16px;
      padding: 7px 15px;
    `}
  
  ${(props) =>
    props.apply &&
    css`
      padding: 4px 15px;
      margin: 0px 7px 0px 0px;
    `}
  
  ${(props) =>
    props.clear &&
    css`
      padding: 4px 15px;
      margin: 0px 0px 0px 7px;
      color: var(--black-light-color);
      background: var(--secondary-color);
    `}
  
  ${(props) =>
    props.createbtn &&
    css`
      color: var(--black-light-color);
      background: var(--secondary-color);
      padding: 7px 15px;
      border: 1px solid var(--secondary-border-color);
      /* filer modal */
      font-size: 13px;
      line-height: 16px;
      :hover {
        :hover {
          background: var(--secondary-color);
          border: 1px solid var(--black-light-color);
          color: var(--black-light-color);
          transition: 0.3s all ease;
      }
      
    `} &:disabled {
    opacity: 0.6;
  }
`;

const SecondaryButton = styled(PrimaryButton)`
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;

class Button extends Component {
  render() {
    const { title, type, disabled, isPrimary, onClick } = this.props;
    return (
      <>
        {isPrimary === true && (
          <PrimaryButton {...this.props} type={type} disabled={disabled} onClick={onClick}>
            {title}
          </PrimaryButton>
        )}
        {isPrimary === false && (
          <SecondaryButton {...this.props} type={type} disabled={disabled} onClick={onClick}>
            {title}
          </SecondaryButton>
        )}
      </>
    );
  }
}

export default Button;
