// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const GET_AUTH_VALIDATION_ERRORS = 'GET_AUTH_VALIDATION_ERRORS';
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';

//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';
export const SET_ITEM_ERROR_MSG = 'SET_ITEM_ERROR_MSG';
export const SET_ITEM_SUCESS_MSG = 'SET_ITEM_SUCESS_MSG';

//Company
export const SET_COMPANY_LOADER = 'SET_COMPANY_LOADER';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const CLEAR_COMPANY_DATA = 'CLEAR_COMPANY_DATA';

//Catalog
export const SET_CATALOG_LOADER = 'SET_CATALOG_LOADER';
export const SET_CATALOG_LIST = 'SET_CATALOG_LIST';
export const CLEAR_CATALOG_DATA = 'CLEAR_CATALOG_DATA';
//Inventory
export const SET_INVENTORY_LOADER = 'SET_INVENTORY_LOADER';
export const SET_INVENTORY_ITEMS_LIST = 'SET_INVENTORY_ITEMS_LIST';
export const SET_INVENTORY_ITEM = 'SET_INVENTORY_ITEM';
export const UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';
export const CLEAR_INVENTORY_ITEM_DATA = 'CLEAR_INVENTORY_ITEM_DATA';
export const CLEAR_INVENTORY_DATA = 'CLEAR_INVENTORY_DATA';
export const SET_INVENTORY_WAREHOUSE_LIST = 'SET_INVENTORY_WAREHOUSE_LIST';
export const SET_WAREHOUSE_ITEM = 'SET_WAREHOUSE_ITEM';
export const UPDATE_WAREHOUSE_ITEM = 'UPDATE_WAREHOUSE_ITEM';
export const CLEAR_WAREHOUSE_ITEM_DATA = 'CLEAR_WAREHOUSE_ITEM_DATA';
export const SET_INVENTORY_PRODUCT_DETAILS = 'SET_INVENTORY_PRODUCT_DETAILS';
export const SET_INVENTORY_PRODUCT_CATEGORY_OPTION = 'SET_INVENTORY_PRODUCT_CATEGORY_OPTION';
export const SET_INVENTORY_PRODUCT_TAG_OPTION = 'SET_INVENTORY_PRODUCT_TAG_OPTION';
export const SET_INVENTORY_PRODUCT_VARIANT_OPTION = 'SET_INVENTORY_PRODUCT_VARIANT_OPTION';
export const UPDATE_INVENTORY_ITEM_FILTERED_LIST = 'UPDATE_INVENTORY_ITEM_FILTERED_LIST';
export const SET_INVENTORY_HISTORY_LIST = 'SET_INVENTORY_HISTORY_LIST';

//Inventory Transaction
export const SET_INVENTORY_TRANSACTION_LOADER = 'SET_INVENTORY_TRANSACTION_LOADER';
export const SET_INVENTORY_TRANSACTION_LIST = 'SET_INVENTORY_TRANSACTION_LIST';
export const SET_INVENTORY_TRANSACTION_DETAILS = 'SET_INVENTORY_TRANSACTION_DETAILS';
export const CLEAR_INVENTORY_TRANSACTION_DATA = 'CLEAR_INVENTORY_TRANSACTION_DATA';
export const SET_INVENTORY_TRANSACTION_BY_ITEM = 'SET_INVENTORY_TRANSACTION_BY_ITEM';
export const UPDATE_INVENTORY_TRANSACTION_FILTERED_LIST = 'UPDATE_INVENTORY_TRANSACTION_FILTERED_LIST';

//Product Pricing
export const SET_PRODUCT_PRICING_LOADER = 'SET_PRODUCT_PRICING_LOADER';
export const SET_PRODUCT_PRICING_LIST = 'SET_PRODUCT_PRICING_LIST';
export const SET_PRODUCT_PRICING_ITEM = 'SET_PRODUCT_PRICING_ITEM';
export const UPDATE_PRODUCT_PRICING_ITEM = 'UPDATE_PRODUCT_PRICING_ITEM';
export const CLEAR_PRODUCT_PRICING_ITEM = 'CLEAR_PRODUCT_PRICING_ITEM';
export const SET_PRODUCT_PRICING_PREVIEW = 'SET_PRODUCT_PRICING_PREVIEW';
export const CLEAR_PRODUCT_PRICING_DATA = 'CLEAR_PRODUCT_PRICING_DATA';
export const UPDATE_PRODUCT_PRICING_FILTERED_LIST = 'UPDATE_PRODUCT_PRICING_FILTERED_LIST';
export const SET_PRODUCT_PRICING_VALUES = 'SET_PRODUCT_PRICING_VALUES';

//contact
export const SET_CONTACT_LOADER = 'SET_CONTACT_LOADER';
export const SET_ALL_CONTACT_LIST = 'SET_ALL_CONTACT_LIST';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const ADD_CONTACT_ITEM = 'ADD_CONTACT_ITEM';
export const SET_CONTACT_TAGS_OPTION = 'SET_CONTACT_TAGS_OPTION';
export const SET_CONTACT_ITEM = 'SET_CONTACT_ITEM';
export const UPDATE_CONTACT_ITEM = 'UPDATE_CONTACT_ITEM';
export const CLEAR_CONTACT_ITEM = 'CLEAR_CONTACT_ITEM';
export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS';
export const SET_SUPPLIER_LIST = 'SET_SUPPLIER_LIST';
export const SET_SALES_PERSON_LIST = 'SET_SALES_PERSON_LIST';
export const SET_SALES_PERSON_ITEM = 'SET_SALES_PERSON_ITEM';
export const UPDATE_SALES_PERSON_ITEM = 'UPDATE_SALES_PERSON_ITEM';
export const CLEAR_SALES_PERSON_ITEM = 'CLEAR_SALES_PERSON_ITEM';
export const CLEAR_CONTACT_DATA = 'CLEAR_CONTACT_DATA';
export const UPDATE_CONTACT_FILTERED_DATA_LIST = 'UPDATE_CONTACT_FILTERED_DATA_LIST';

//Accounts
export const SET_BANK_ACCOUNT_LOADER = 'SET_BANK_ACCOUNT_LOADER';
export const SET_BANK_ACCOUNT_LIST = 'SET_BANK_ACCOUNT_LIST';
export const SET_BANKS_ITEM = 'SET_BANKS_ITEM';
export const UPDATE_BANK_ITEM = 'UPDATE_BANK_ITEM';
export const CLEAR_BANK_ITEM = 'CLEAR_BANK_ITEM';
export const SET_BANKS_TRANSACTIONS_LIST = 'SET_BANKS_TRANSACTIONS_LIST';
export const SET_BANKS_TRANSACTIONS_DETAILS = 'SET_BANKS_TRANSACTIONS_DETAILS';
export const UPDATE_BANK_TRANSACTIONS_ITEM = 'UPDATE_BANK_TRANSACTIONS_ITEM';
export const CLEAR_BANK_TRANSACTIONS_ITEM = 'CLEAR_BANK_TRANSACTIONS_ITEM';
export const UPDATE_TRANSFER_FUND_ITEM = 'UPDATE_TRANSFER_FUND_ITEM';
export const CLEAR_TRANSFER_FUND_ITEM = 'CLEAR_TRANSFER_FUND_ITEM';
export const SET_TAX_LIST = 'SET_TAX_LIST';
export const UPDATE_FILTER_DATA_LIST = 'UPDATE_FILTER_DATA_LIST';
export const CLEAR_BANK_ACCOUNT_DATA = 'CLEAR_BANK_ACCOUNT_DATA';

//Setting
export const SET_SETTING_LOADER = 'SET_SETTING_LOADER';
export const SET_USERS_AND_ROLES_LIST = 'SET_USERS_AND_ROLES_LIST';
export const SET_CHART_OF_ACCOUNTS_LIST = 'SET_CHART_OF_ACCOUNTS_LIST';
export const SET_CHART_OF_ACCOUNTS_DETAILS = 'SET_CHART_OF_ACCOUNTS_DETAILS';
export const SET_SETTING_SALES_LIST = 'SET_SETTING_SALES_LIST';
export const SET_OPENING_BALANCE = 'SET_OPENING_BALANCE';
export const UPDATE_OPENING_BALANCE = 'UPDATE_OPENING_BALANCE';
export const CLEAR_USERS_AND_ROLES_DATA = 'CLEAR_USERS_AND_ROLES_DATA';

//sales
export const SET_SALES_LOADER = 'SET_SALES_LOADER';
export const SET_INVOICE_PRODUCT_LIST = 'SET_INVOICE_PRODUCT_LIST';
export const SET_SALES_INVOICE_LIST = 'SET_SALES_INVOICE_LIST';
export const SET_SALES_INVOICE_ITEM = 'SET_SALES_INVOICE_ITEM';
export const UPDATE_SALES_INVOICE_ITEM = 'UPDATE_SALES_INVOICE_ITEM';
export const SET_SALES_INVOICE = 'SET_SALES_INVOICE';
export const CLEAR_SALES_INVOICE_ITEM = 'CLEAR_SALES_INVOICE_ITEM';
export const SET_SALES_INVOICE_PREVIEW = 'SET_SALES_INVOICE_PREVIEW';
export const CLEAR_SALES_DATA = 'CLEAR_SALES_DATA';
export const SET_DUE_INVOICE_LIST = 'SET_DUE_INVOICE_LIST';
export const UPDATE_DUE_INVOICE_LIST = 'UPDATE_DUE_INVOICE_LIST';
export const SET_INVOICE_NUMBER = 'SET_INVOICE_NUMBER';
export const SET_SALES_INVOICE_NUMBER_LIST = 'SET_SALES_INVOICE_NUMBER_LIST';
export const UPDATE_FILTERED_DATA_LIST = 'UPDATE_FILTERED_DATA_LIST';

//Pagination
export const SET_PAGINATED_LIST = 'SET_PAGINATED_LIST';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_TOTAL_PAGE = 'SET_TOTAL_PAGE';
export const SET_TOTAL_RECORDS = 'SET_TOTAL_RECORDS';
export const CLEAR_PAGINATION_DATA = 'CLEAR_PAGINATION_DATA';

//Purchase
export const SET_PURCHASE_LOADER = 'SET_PURCHASE_LOADER';
export const SET_PURCHASE_INVOICE_LIST = 'SET_PURCHASE_INVOICE_LIST';
export const CLEAR_PURCHASE_DATA = 'CLEAR_PURCHASE_DATA';
export const SET_PURCHASE_INVOICE_ITEM = 'SET_PURCHASE_INVOICE_ITEM';
export const UPDATE_PURCHASE_INVOICE_ITEM = 'UPDATE_PURCHASE_INVOICE_ITEM';
export const CLEAR_PURCHASE_INVOICE_ITEM = 'CLEAR_PURCHASE_INVOICE_ITEM';
export const SET_PURCHASE_INVOICE_PREVIEW = 'SET_PURCHASE_INVOICE_PREVIEW';
export const SET_DUE_BILL_LIST = 'SET_DUE_BILL_LIST';
export const UPDATE_DUE_BILL_LIST = 'UPDATE_DUE_BILL_LIST';
export const SET_PURCHASE_NUMBER_LIST = 'SET_PURCHASE_NUMBER_LIST';
export const UPDATE_PURCHASE_FILTERED_LIST = 'UPDATE_PURCHASE_FILTERED_LIST';

//Sales Order
export const SET_SALES_ORDER_LOADER = 'SET_SALES_ORDER_LOADER';
export const SET_SALES_ORDER_LIST = 'SET_SALES_ORDER_LIST';
export const SET_SALES_ORDER = 'SET_SALES_ORDER';
export const UPDATE_SALES_ORDER = 'UPDATE_SALES_ORDER';
export const CLEAR_SALES_ORDER = 'CLEAR_SALES_ORDER';
export const SET_SALES_ORDER_NUMBER = 'SET_SALES_ORDER_NUMBER';
export const SET_SALES_ORDER_PREVIEW = 'SET_SALES_ORDER_PREVIEW';
export const CLEAR_SALES_ORDER_DATA = 'CLEAR_SALES_ORDER_DATA';
export const UPDATE_SALES_ORDER_FILTERED_LIST = 'UPDATE_SALES_ORDER_FILTERED_LIST';

//Order List
export const SET_ORDER_LIST_LOADER = 'SET_ORDER_LIST_LOADER';
export const SET_PENDING_ORDER_LIST = 'SET_PENDING_ORDER_LIST';
export const SET_CANCEL_ORDER_LIST = 'SET_CANCEL_ORDER_LIST';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const CLEAR_ORDER_ITEM = 'CLEAR_ORDER_ITEM';
export const CLEAR_ORDER_LIST_DATA = 'CLEAR_ORDER_LIST_DATA';
export const UPDATE_ORDER_LIST_FILTERED_LIST = 'UPDATE_ORDER_LIST_FILTERED_LIST';
export const SET_INVOICED_ORDER_LIST = 'SET_INVOICED_ORDER_LIST';
//credit Notes
export const SET_CREDIT_NOTES_LIST = 'SET_CREDIT_NOTES_LIST';
export const SET_CREDIT_NOTES_LOADER = 'SET_CREDIT_NOTES_LOADER';
export const CLEAR_CREDIT_NOTES_DATA = 'CLEAR_CREDIT_NOTES_DATA';
export const CLEAR_CREDIT_NOTES_INVOICE_ITEM = 'CLEAR_CREDIT_NOTES_INVOICE_ITEM';
export const UPDATE_CREDIT_NOTES_INVOICE_ITEM = 'UPDATE_CREDIT_NOTES_INVOICE_ITEM';
export const SET_CREDIT_NOTES_INVOICE_ITEM = 'SET_CREDIT_NOTES_INVOICE_ITEM';
export const SET_CREDIT_NOTES_INVOICE = 'SET_CREDIT_NOTES_INVOICE';
export const SET_CREDIT_NOTES_INVOICE_PREVIEW = 'SET_CREDIT_NOTES_INVOICE_PREVIEW';
export const SET_CREDIT_NOTES_INVOICE_NUMBER = 'SET_CREDIT_NOTES_INVOICE_NUMBER';
export const UPDATE_CREDIT_NOTES_FILTERED_LIST = 'UPDATE_CREDIT_NOTES_FILTERED_LIST';

//Debit Notes
export const SET_DEBIT_NOTES_LOADER = 'SET_DEBIT_NOTE_LOADER';
export const SET_DEBIT_NOTES_LIST = 'SET_DEBIT_NOTE_LIST';
export const CLEAR_DEBIT_NOTES_DATA = 'CLEAR_DEBIT_NOTE_DATA';
export const UPDATE_DEBIT_NOTES_ITEM = 'UPDATE_DEBIT_NOTES_ITEM';
export const SET_SELECTED_PURCHASE_INVOICE_ITEM = 'SET_SELECTED_PURCHASE_INVOICE_ITEM';
export const SET_DEBIT_NOTES_ITEM = 'SET_DEBIT_NOTES_ITEM';
export const SET_DEBIT_NOTES_INVOICE_PREVIEW = 'SET_DEBIT_NOTES_INVOICE_PREVIEW';
export const CLEAR_DEBIT_NOTES_INVOICE_ITEM = 'CLEAR_DEBIT_NOTES_INVOICE_ITEM';
export const UPDATE_DEBIT_NOTES_FILTERED_LIST = 'UPDATE_DEBIT_NOTES_FILTERED_LIST';

//Journal Transactions
export const SET_JOURNAL_TRANSACTION_LOADER = 'SET_JOURNAL_TRANSACTION_LOADER';
export const SET_JOURNAL_TRANSACTION_LIST = 'SET_JOURNAL_TRANSACTION_LIST';
export const SET_JOURNAL_TRANSACTION_ITEM = 'SET_JOURNAL_TRANSACTION_ITEM';
export const UPDATE_JOURNAL_TRANSACTION_ITEM = 'UPDATE_JOURNAL_TRANSACTION_ITEM';
export const CLEAR_JOURNAL_TRANSACTION_ITEM = 'CLEAR_JOURNAL_TRANSACTION_ITEM';
export const CLEAR_JOURNAL_TRANSACTION_DATA = 'CLEAR_JOURNAL_TRANSACTION_DATA';

//Activity
export const SET_ACTIVITY_LOADER = 'SET_ACTIVITY_LOADER';
export const SET_ACTIVITY_HISTORY_LIST = 'SET_ACTIVITY_HISTORY_LIST';
export const UPDATE_HISTORY_FILTERED_LIST = 'UPDATE_HISTORY_FILTERED_LIST';
export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA';

//customer
export const SET_CUSTOMER_LOADER = 'SET_CUSTOMER_LOADER';
export const SET_CUSTOMER_ITEM = 'SET_CUSTOMER_ITEM';
export const SET_CUSTOMER_ACCOUNT_MANAGER = 'SET_CUSTOMER_ACCOUNT_MANAGER';
export const CLEAR_CUSTOMER_DATA = 'CLEAR_CUSTOMER_DATA';
export const SET_CUSTOMER_SETTINGS = 'SET_CUSTOMER_SETTINGS';

//toggle side menu
export const TOGGLE_SIDEMENU = 'TOGGLE_SIDEMENU';

// Ledger Report
export const SET_REPORTS_LOADER = 'SET_REPORTS_LOADER';
export const SET_LEDGER_REPORT = 'SET_LEDGER_REPORT';
export const CLEAR_REPORTS_DATA = 'CLEAR_REPORTS_DATA';

// Invoices
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_FILTERED_INVOICE_LIST = 'SET_FILTERED_INVOICE_LIST';
export const SET_INVOICE_PREVIEW = 'SET_INVOICE_PREVIEW';

//Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_CART_ITEMS = 'REMOVE_CART_ITEMS';
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
export const CLEAR_CART_DATA = 'CLEAR_CART_DATA';

//Dashboard
export const SET_DASHBOARD_LOADER = 'SET_DASHBOARD_LOADER';
export const SET_ORDER_OVERVIEW = 'SET_ORDER_OVERVIEW';
export const SET_TOP_SOLD_PRODUCTS = 'SET_TOP_SOLD_PRODUCTS';
export const SET_NEW_CATALOGS = 'SET_NEW_CATALOGS';
export const SET_TOTAL_SALES_AND_BILL = 'SET_TOTAL_SALES_AND_BILL';
export const SET_TODAYS_ACTIVITY = 'SET_TODAYS_ACTIVITY';
export const SET_TOTAL_ORDER_CHART_DATA = 'SET_TOTAL_ORDER_CHART_DATA';
export const SET_ANNOUNCEMENT_LIST = 'SET_ANNOUNCEMENT_LIST';

//Membership
export const UPDATE_MEMBERSHIP_ERROR = 'UPDATE_MEMBERSHIP_ERROR';
export const CLEAR_MEMBERSHIP_ERROR = 'CLEAR_MEMBERSHIP_ERROR';
