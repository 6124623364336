import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, setting, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <>{auth.isAuthenticated === true ? <Component {...props} /> : <Redirect to='/login' />}</>;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting
});

export default connect(mapStateToProps)(PrivateRoute);
