import * as Actions from './types';

/**
 * @desc Set Pagination List
 */
export const setPaginationList = (payload) => {
  return {
    type: Actions.SET_PAGINATED_LIST,
    payload
  };
};

/**
 * @desc Set Current Page
 */
export const setCurrentPage = (payload) => {
  return {
    type: Actions.SET_CURRENT_PAGE,
    payload
  };
};

/**
 * @desc Set Total Pages
 */
export const setTotalPage = (payload) => {
  return {
    type: Actions.SET_TOTAL_PAGE,
    payload
  };
};

/**
 * @desc Set Total Records
 */
export const setTotalRecords = (payload) => {
  return {
    type: Actions.SET_TOTAL_RECORDS,
    payload
  };
};

/**
 * @desc Clear Pagination data
 */
export const clearPaginationData = () => {
  return {
    type: Actions.CLEAR_PAGINATION_DATA
  };
};
