import * as Actions from './types';

/**
 * @desc Set Dashboard Loader
 */
export const setDashboardLoader = (payload) => {
  return {
    type: Actions.SET_DASHBOARD_LOADER,
    payload
  };
};

/**
 * @desc Set Order Overview
 */
export const setOrderOverview = (payload) => {
  return {
    type: Actions.SET_ORDER_OVERVIEW,
    payload
  };
};

/**
 * @desc Set Top Sold Products
 */
export const setTopSoldProducts = (payload) => {
  return {
    type: Actions.SET_TOP_SOLD_PRODUCTS,
    payload
  };
};

/**
 * @desc Set New Catalogs
 */
export const setNewCatalogs = (payload) => {
  return {
    type: Actions.SET_NEW_CATALOGS,
    payload
  };
};

/**
 * @desc Set Total Sales And Bill
 */
export const setTotalSalesAndBill = (payload) => {
  return {
    type: Actions.SET_TOTAL_SALES_AND_BILL,
    payload
  };
};

/**
 * @desc Set Customer's Todays Activity
 */
export const setTodaysActivity = (payload) => {
  return {
    type: Actions.SET_TODAYS_ACTIVITY,
    payload
  };
};

/**
 * @desc Set Customer's Total Order Chart Data
 */
export const setTotalOrderChartData = (payload) => {
  return {
    type: Actions.SET_TOTAL_ORDER_CHART_DATA,
    payload
  };
};

/**
 * @desc Set Customer's Active Announcement List
 */
export const setAnnouncementList = (payload) => {
  return {
    type: Actions.SET_ANNOUNCEMENT_LIST,
    payload
  };
};
