import React, { Component } from 'react';

class ThermalInvoiceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <h2> ThermalInvoiceTemplate </h2>
      </>
    );
  }
}

export default ThermalInvoiceTemplate;
