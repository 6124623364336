import styled from 'styled-components';
import {
  Headertitle,
  Mediumtext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../../font-variable';

const MainWrapper = styled.div`
  height: 100%;
  background: var(--secondary-color);
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  padding: 24px 24px;
  z-index: 2;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const CatName = styled.div`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
`;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const ContentCart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  height: 100%;
`;

const ContentCartWrapper = styled.div`
  margin-left: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 0;
  overflow: auto;
  @media (max-width: 767px) {
    margin: 8px 0 0 16px;
    padding-bottom: 16px;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const Cartcount = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  margin: 0 8px;
  display: block;
`;

const BtnView = styled.div`
  ${flexspacebetween};
  width: 100%;
  max-width: 92px;
  margin: auto;
  border: 1px solid var(--secondary-border-color);
  background: var(--secondary-color);
  border-radius: 6px;
  padding: 7px 8px;
`;

const LButton = styled.div`
  border: 0;
  padding: 0;
  cursor: pointer;
  color: var(--grey-color);
  svg {
    vertical-align: middle;
  }
  :hover {
    border: none !important;
    svg path {
      stroke: var(--black-light-color);
    }
  }
`;

const Table = styled.table`
  border-spacing: 0px;
  width: 100%;
`;

const Tr = styled.tr`
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Smalltext};
  color: var(--grey-color);
  border-bottom: 1px solid var(--border-color);
  background: var(--secondary-color);
  text-align: left;
  padding: 1px 8px 8px;
  text-transform: capitalize;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 0;
  &:first-child {
    width: 100px;
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
    text-align: center;
  }
  &:nth-child(4) {
    text-align: right;
  }
  &:nth-child(5) {
  }
  &:nth-child(6) {
  }
  &:last-child {
    text-align: right;
    padding-right: 24px;
    cursor: pointer;
  }
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border-bottom: 1px solid var(--border-color);
  padding: 16px 8px;
  white-space: nowrap;
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
    text-align: center;
  }
  &:nth-child(4) {
    text-align: right;
  }
  &:nth-child(5) {
  }
  &:nth-child(6) {
  }
  &:last-child {
    text-align: right;
    padding-right: 24px;
    cursor: pointer;
  }
  &.totalspan {
    font-weight: 600;
  }
`;

const NameList = styled.div`
  ${flex};
  gap: 16px;
`;

const CartImg = styled.div`
  width: 44px;
  height: 48px;
  background: var(--background-dropdown);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  ${flexcenter};
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    max-width: 100%;
  }
`;

const EmptyTitle = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 10px 0 0;
  text-transform: capitalize;
`;

const EmptyContain = styled.div`
  ${flexcenter};
  height: 100%;
`;

const EmptyDetail = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
`;

const EmptyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextEmpty = styled.p`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 10px 0 20px;
  text-transform: capitalize;
`;

const CartName = styled.div``;

const NameSpan = styled.span`
  ${Mediumtext};
  color: var(--black-light-color);
`;

const CurrSpan = styled.span``;

const ImgModal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 540px;
  height: 720px;
  @media (max-width: 767px) {
    width: 320px;
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

const ImgClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const RemoveButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 0;
  margin-left: auto;
  cursor: pointer;
  ${flexcenter};
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  :hover {
    background: var(--primary-hover-color);
    border: 1px solid var(--primary-hover-color);
  }
`;

export {
  ContentCart,
  ContentCartWrapper,
  MainContent,
  MainWrapper,
  Table,
  EmptyDetail,
  ProductNav,
  EmptyContain,
  EmptyTitle,
  TextEmpty,
  EmptyImage,
  Cartcount,
  Tr,
  Th,
  Td,
  LeftWrap,
  MenuView,
  CatName,
  CartImg,
  NameList,
  CartName,
  NameSpan,
  CurrSpan,
  BtnView,
  LButton,
  ImgModal,
  ImgClose,
  RemoveButton
};
