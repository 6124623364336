import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { getTopSoldProducts } from '../../services/dashboardService';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import ReportDateFilter from '../../components/ReportDateTimeFilter/ReportDateFilter';
import Loading from '../../components/Loading/Loading';
import { addDaysToDate, currencyWithDecimal } from '../../helper/common';
import { ReactComponent as AvtarIcon } from '../../assets/images/svg/avtardemo.svg';
import { getCatalogList } from '../../services/catalogService';
import { Mediumtext, Normaltext, Smalltext, flex, flexcenter, flexspacebetween } from '../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MinContact = styled.div`
  position: relative;
  width: 100%;
`;

const ShotcutDiv = styled.div`
  width: 100%;
  height: 474px;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const HeadTitle = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  margin: 0;
`;

const Pcs = styled.div`
  ${flex};
`;

const SpanPcs = styled.span`
  ${Normaltext};
  color: var(--grey-color);
  background: var(--background-dropdown);
  border-radius: 30px;
  margin: 0 0 0 4px;
  padding: 2px 8px;
`;

const TabelHead = styled.span`
  ${Mediumtext};
  color: var(--black-light-color);
  font-weight: 600;
  display: block;
  white-space: nowrap;
  margin: 0;
`;

const Nodata = styled.div`
  ${flexcenter};
  ${Mediumtext};
  font-weight: 600;
  color: var(--black-light-color);
  height: 100%;
`;

const TableIt = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Normaltext};
  color: var(--grey-color);
  background: var(--secondary-color);
  text-align: left;
  text-transform: capitalize;
  padding: 8px 8px 12px;
  position: sticky;
  top: 0;
  z-index: 99;
  &:first-child {
    padding-left: 0;
    width: 0%;
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
  }
  &:last-child {
    text-align: right;
    padding-right: 0;
    cursor: pointer;
    > div {
      justify-content: flex-end;
    }
  }
`;

const Td = styled.td`
  ${Smalltext};
  color: var(--black-light-color);
  padding: 12px 8px;
  text-transform: capitalize;
  white-space: nowrap;
  border-top: 1px solid var(--border-color);
  &:first-child {
    padding-left: 0;
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
  }
  &:last-child {
    text-align: right;
    padding-right: 0;
    cursor: pointer;
  }
`;

const MainTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
  padding: 0px 16px;
  @media (max-width: 767px) {
    padding: 0 12px;
  }
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  gap: 16px;
  position: relative;
  z-index: 0;
  padding: 16px 16px;
  margin-bottom: -4px;
  @media (max-width: 767px) {
    padding: 16px 12px;
  }
`;

const MainTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const PlaceHoldImage = styled.div`
  width: 44px;
  height: 48px;
  border-radius: 4px;
  background: var(--background-dropdown);
  border: 1px solid var(--border-color);
  ${flexcenter};
`;

const PlaceImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-width: 100%;
`;

class TopProductsSold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      option: 4,
      //Last 30 Days,Today included so -29
      fromDate: addDaysToDate(new Date(), -29),
      toDate: new Date()
    };
  }

  onGetTopSoldProducts = async () => {
    const { fromDate, toDate } = this.state;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (!companyId) return;
    this.setState({ isLoading: true });
    await this.props.getCatalogList(companyId);
    await this.props.getTopSoldProducts(companyId, fromDate, toDate);
    this.setState({ isLoading: false });
  };

  onChange = async (option, fromDate, toDate) => {
    const fromDateString = moment(fromDate).format('YYYY-MM-DD');
    const toDateString = moment(toDate).format('YYYY-MM-DD');
    this.setState({ fromDate: fromDateString, toDate: toDateString }, () => {
      this.onGetTopSoldProducts();
    });
  };

  onClickProductName = (productId) => {
    let { catalogList = [] } = this.props.catalog;
    const product = catalogList?.find((x) => x?.id === productId);
    let catalogue;
    if (product) catalogue = product?.catalogue;
    const url = `/catalogue/details/${window.btoa(catalogue)}`;
    if (catalogue) this.props.history.push(url);
  };

  render() {
    const { isLoading, option, fromDate, toDate } = this.state;
    const { topSoldProducts } = this.props.dashboard;
    return (
      <>
        <MinContact>
          <ShotcutDiv>
            <HeaderWrapper>
              <TabelHead>Top Products</TabelHead>
              <ReportDateFilter
                option={option}
                fromDate={fromDate}
                toDate={toDate}
                onChange={this.onChange}
                loading={isLoading}
                backgroundColor={'#f9f9f9'}
              />
            </HeaderWrapper>
            {!isLoading && topSoldProducts?.length === 0 && <Nodata> No Data Found</Nodata>}
            {isLoading && (!topSoldProducts || topSoldProducts?.length === 0) ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <>
                {topSoldProducts?.length > 0 && (
                  <MainTab>
                    <MainTable>
                      <TableIt>
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Product Name</Th>
                            <Th>Count</Th>
                            <Th>Amount</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {topSoldProducts?.map((item, i) => {
                            const { product_id, product_image, product_name, total_qty_sold, total_sales_amount } =
                              item || {};
                            return (
                              <Tr key={i}>
                                <Td>
                                  <PlaceHoldImage>
                                    {product_image?.[0]?.url ? (
                                      <PlaceImage src={product_image?.[0]?.url} alt='gallery' />
                                    ) : (
                                      <AvtarIcon />
                                    )}
                                  </PlaceHoldImage>
                                </Td>
                                <Td>
                                  <HeadTitle
                                    onClick={() => {
                                      this.onClickProductName(product_id);
                                    }}>
                                    {product_name}
                                  </HeadTitle>
                                </Td>
                                <Td>
                                  <Pcs>
                                    {total_qty_sold}
                                    <SpanPcs>Pcs</SpanPcs>
                                  </Pcs>
                                </Td>
                                <Td>
                                  <HeadTitle>₹ {currencyWithDecimal(total_sales_amount)}</HeadTitle>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </TableIt>
                    </MainTable>
                  </MainTab>
                )}
              </>
            )}
          </ShotcutDiv>
        </MinContact>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  catalog: state.catalog
});

export default connect(mapStateToProps, {
  getTopSoldProducts,
  getCatalogList
})(TopProductsSold);
