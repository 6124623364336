let singleton;
const singletonEnforcer = Symbol();
const parseDataFile = (defaults) => {
  try {
    const settings = localStorage.getItem('UserPreferences');
    if (settings) return JSON.parse(settings);
    return {};
  } catch (error) {
    return defaults;
  }
};

const containsKey = (obj, key) => ({}.hasOwnProperty.call(obj || {}, key));

class UserPreferences {
  constructor(opts) {
    this.defaults = opts.defaults;
    this.data = parseDataFile(opts.defaults);
  }

  get(key, defaultValue) {
    if (containsKey(this.data, key)) {
      return this.data[key];
    }
    return defaultValue;
  }

  save(settings) {
    localStorage.setItem('UserPreferences', JSON.stringify(settings));
  }

  set(key, value) {
    this.data = parseDataFile(this.defaults);
    this.data[key] = value;
    this.save(this.data);
  }

  remove(key) {
    delete this.data[key];
    this.save(this.data);
  }

  parseDataFile() {
    this.data = parseDataFile(this.defaults);
  }

  contains(key) {
    return Object.prototype.hasOwnProperty.call(this.data, key);
  }
}
export default class UserPreferenceSingleton {
  static get ACCESS_TOKEN() {
    return 'access_tokens';
  }
  static get EMAIL() {
    return 'email';
  }
  static get DISPLAY_NAME() {
    return 'display_name';
  }
  static get COMPANY_ID() {
    return 'company_id';
  }
  static get CUSTOMER_ID() {
    return 'customer_id';
  }
  static get PAGE_COUNT() {
    return 'page_count_per_page';
  }
  static get CART_ITEMS() {
    return 'cart_items';
  }

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) throw new Error('Cannot construct singleton');

    this.userPreferences = new UserPreferences({
      configName: 'user-preferences',
      defaults: {
        windowBounds: { width: 800, height: 600 }
      }
    });
  }

  static getInstance() {
    if (!singleton) {
      singleton = new UserPreferenceSingleton(singletonEnforcer);
    }
    return singleton;
  }

  getUserToken() {
    return this.userPreferences.get(UserPreferenceSingleton.ACCESS_TOKEN, undefined);
  }

  setUserToken(value) {
    return this.userPreferences.set(UserPreferenceSingleton.ACCESS_TOKEN, value);
  }

  getCompanyId() {
    return this.userPreferences.get(UserPreferenceSingleton.COMPANY_ID, undefined);
  }

  setCompanyId(value) {
    return this.userPreferences.set(UserPreferenceSingleton.COMPANY_ID, value);
  }

  getCustomerId() {
    return this.userPreferences.get(UserPreferenceSingleton.CUSTOMER_ID, undefined);
  }

  setCustomerId(value) {
    return this.userPreferences.set(UserPreferenceSingleton.CUSTOMER_ID, value);
  }

  getDisplayName() {
    return this.userPreferences.get(UserPreferenceSingleton.DISPLAY_NAME, undefined);
  }

  setDisplayName(value) {
    return this.userPreferences.set(UserPreferenceSingleton.DISPLAY_NAME, value);
  }

  get(key, defaultValue = null) {
    return this.userPreferences.get(key, defaultValue);
  }

  set(key, value) {
    this.userPreferences.set(key, value);
  }

  setEmail(value) {
    return this.userPreferences.set(UserPreferenceSingleton.EMAIL, value);
  }

  getEmail() {
    return this.userPreferences.get(UserPreferenceSingleton.EMAIL, undefined);
  }

  getServerUrl() {
    return this.userPreferences.get(UserPreferenceSingleton.SERVER_URL, null);
  }

  getPageCount() {
    return this.userPreferences.get(UserPreferenceSingleton.PAGE_COUNT, 10);
  }

  setPageCount(value) {
    return this.userPreferences.set(UserPreferenceSingleton.PAGE_COUNT, value);
  }

  getCartItems() {
    return this.userPreferences.get(UserPreferenceSingleton.CART_ITEMS, undefined);
  }

  setCartItems(value) {
    return this.userPreferences.set(UserPreferenceSingleton.CART_ITEMS, value);
  }

  clearStoredUserData() {
    this.userPreferences.remove(UserPreferenceSingleton.ACCESS_TOKEN);
    this.userPreferences.remove(UserPreferenceSingleton.COMPANY_ID);
    this.userPreferences.remove(UserPreferenceSingleton.CUSTOMER_ID);
    this.userPreferences.remove(UserPreferenceSingleton.DISPLAY_NAME);
    this.userPreferences.remove(UserPreferenceSingleton.EMAIL);
    this.userPreferences.remove(UserPreferenceSingleton.CUSTOMER_ID);
    this.userPreferences.remove(UserPreferenceSingleton.CART_ITEMS);
  }
}
