import React from 'react';
import { ReactComponent as ErrorIcon } from '../../assets/images/svg/validation-error.svg';

import { MainSection } from './styles';
export default function Error({ message, isCenter = false }) {
  return (
    <MainSection isCenter={isCenter}>
      <ErrorIcon />
      <p>{message}</p>
    </MainSection>
  );
}
