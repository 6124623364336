import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import { ReactComponent as DropDownIcon } from '../../../assets/images/svg/dropicon.svg';
import { logout } from '../../../services/authService';
import { getCompanyList } from '../../../services/authService';
import Loader from '../../../components/Loading/Loading';
import UserPreferenceSingleton from '../../../helper/UserPreferenceSingleton';
import { setSelectedCompany } from '../../../actions/authActions';
import { loadRequiredData, clearAllItemData } from '../../../services/baseService';
import { ReactComponent as LeftArrow } from '../../../assets/images/svg/Leftarrow-back.svg';
import Avtar from '../../../components/Avtar/Avtar';
import { Mediumtext, Normaltext, Smalltext, flex, flexcenter, flexspacebetween } from '../../../font-variable';
import { getUrlParam } from '../../../helper/common';

const Container = styled.div`
  background: var(--background-color);
  height: 100%;
  overflow: auto;
  padding-bottom: 20px;
`;

const SwitchList = styled.div`
  max-width: 1040px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
`;

const SwitchHead = styled.div`
  ${flexspacebetween};
  position: sticky;
  top: 0px;
  z-index: 99;
  margin-bottom: 0px;
  padding: 24px 24px;
`;

const SwitchLogo = styled.div`
  display: flex;
  align-items: center;
`;

const LeftArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HeaderHeading = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #070707;
  margin: 0 0 0 4px;
`;

const RightBtn = styled.div`
  ${flex};
`;

const ComapnyName = styled.div`
  margin-top: 15px;
`;

const CompanyText = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  cursor: pointer;
  text-transform: capitalize;
`;

const ModalSpan = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: var(--primary-color);
  }
`;

const SecondRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 25px;
  padding: 56px 15px 40px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 15px;
    padding-top: 20px;
  }
  .company {
    :hover {
      cursor: pointer;
      .companylist {
        border: 1px solid var(--primary-color);
      }
      .labelform {
        color: var(--primary-color);
      }
    }
  }
`;

const CompanyList = styled.div`
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  ${flexcenter};
  border-radius: 8px;
  width: 100%;
  /* height: 100%; */
  min-height: 180px;
  cursor: pointer;
  transition: 0.2s;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid var(--primary-color);
    `}
`;

const Company = styled.div`
  text-align: center;
  margin-bottom: 10px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid transparent;
`;

const IconLogo = styled.div`
  ${flexcenter};
`;

const CompanyLogo = styled.img`
  max-width: 100%;
  height: auto;
`;

const DropDownModal = styled.div`
  ${flex};
  gap: 6px;
  cursor: pointer;
`;

const LabelLogo = styled.label`
  ${Normaltext};
  font-size: 50px;
  color: var(--grey-color);
`;

const LabelForm = styled.label`
  ${Mediumtext};
  color: var(--black-light-color);
  text-transform: capitalize;
`;

const InviteUser = withStyles({
  paper: {
    border: '1px solid var(--secondary-border-color)',
    padding: '16px 16px 6px 16px',
    background: 'var(--secondary-color)',
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    width: '260px',
    marginTop: '18px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

class SwitchOrganisation extends Component {
  constructor(props) {
    super(props);
    const isFromLogin = getUrlParam(this.props.location.search, 'login');
    this.state = {
      anchorEl: null,
      isfromLogin: isFromLogin || false
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    await this.props.getCompanyList();
  };

  setAnchorEl = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  onClickLogout = async () => {
    await this.props.logout();
    this.props.history.push('/login');
  };

  onSwitchCompany = async (item) => {
    if (!item || !item.id) return;
    this.props.setSelectedCompany(item);
    UserPreferenceSingleton.getInstance().setCompanyId(item.id);
    await this.props.clearAllItemData();
    await this.props.loadRequiredData(this.props.history);
    this.props.history.push('/');
  };

  onNavBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { anchorEl, isfromLogin } = this.state;
    const { companyList, loading } = this.props.auth;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    const displayName = UserPreferenceSingleton.getInstance().getDisplayName();

    return (
      <Container>
        <SwitchList>
          <SwitchHead>
            {!isfromLogin && (
              <SwitchLogo>
                <LeftArrowWrapper isPrimary={true} title='Go Back' disabled={loading} onClick={this.onNavBack}>
                  <LeftArrow />
                </LeftArrowWrapper>
                <HeaderHeading>Go Back</HeaderHeading>
              </SwitchLogo>
            )}
            <RightBtn>
              <Avtar name={displayName} />
              <DropDownModal onClick={this.setAnchorEl}>
                <CompanyText>{displayName}</CompanyText>
                <DropDownIcon />
              </DropDownModal>
            </RightBtn>
          </SwitchHead>
          {loading && !companyList ? (
            <Loader />
          ) : (
            <SecondRow>
              {companyList?.map((item) => (
                <Company
                  key={item?.id}
                  onClick={() => {
                    this.onSwitchCompany(item);
                  }}
                  className='company'>
                  <CompanyList border={companyId === item?.id} className='companylist'>
                    {item?.logo_url ? (
                      <IconLogo>
                        <CompanyLogo src={item?.logo_url} />
                      </IconLogo>
                    ) : (
                      <LabelLogo>{item?.name?.charAt(0)?.toUpperCase()}</LabelLogo>
                    )}
                  </CompanyList>
                  <ComapnyName>
                    <LabelForm className='labelform'>{item?.name}</LabelForm>
                  </ComapnyName>
                </Company>
              ))}
            </SecondRow>
          )}
        </SwitchList>
        <InviteUser
          id='customized-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <ModalSpan onClick={this.onClickLogout}>Logout</ModalSpan>
        </InviteUser>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  state: state
});

export default connect(mapStateToProps, {
  logout,
  getCompanyList,
  setSelectedCompany,
  loadRequiredData,
  clearAllItemData
})(SwitchOrganisation);
