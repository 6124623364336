import axios from 'axios';
import { REACT_APP_APIURL } from '../global/Environment';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { getAPIResponseError } from '../helper/common';
import {
  setFilteredInvoiceList,
  setInvoiceList,
  setIvoicePreview,
  setLedgerReport,
  setReportsLoader
} from '../actions/reportActions';
import { loadListWithPagging } from './paginationService';

/**
 * @desc  Get Ledger Detail
 * @param {*} company_id company id
 */
export const getLedgerReport = (company_id, fromdate, todate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setReportsLoader(true));
    let params = { fromdate, todate };
    const response = await axios.get(`${REACT_APP_APIURL}/customer/reports/${company_id}/ledger-details`, { params });
    const { data } = response.data;
    if (data) {
      dispatch(setLedgerReport(data || []));
      return true;
    }
  } catch (e) {
    dispatchReportsError(getAPIResponseError(e) || 'Unable to Fetch Ledger Report, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setReportsLoader(false));
  }
};

/**
 * @desc  Get Invoice List
 * @param {*} company_id company id
 */
export const getInvoiceList = (company_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setReportsLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/customer/invoices/${company_id}`);
    const { data } = response.data;
    if (data) {
      const { invoices } = data;
      dispatch(setInvoiceList(invoices || []));
      dispatch(setFilteredInvoiceList(invoices || []));
      dispatch(loadListWithPagging(invoices || [], 1, true));
    }
    return true;
  } catch (e) {
    dispatchReportsError(getAPIResponseError(e) || 'Unable to Fetch Invoice List, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setReportsLoader(false));
  }
};

/**
 * @desc  Get Sales Invoice Preview
 * @param {*} CompanyId
 */
export const getInvoicePreview = (company_id, id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setReportsLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/customer/invoices/${company_id}/preview/${id}`);
    const { data } = response.data;
    if (data) {
      dispatch(setIvoicePreview(data || {}));
    }
    return true;
  } catch (e) {
    dispatchReportsError(
      getAPIResponseError(e) || 'Unable to Fetch Invoice Preview Data, please try again later',
      dispatch
    );
    return false;
  } finally {
    dispatch(setReportsLoader(false));
  }
};

/**
 * @desc Print Bulk Shipping Invoice
 * @param {*} company_id company id
 */
export const printBulkShippingInvoice = (company_id, payload) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setReportsLoader(true));
    if (payload?.invoiceIds?.length === 0) {
      dispatchReportsError('Invoice Id is required', dispatch);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/sales/${company_id}/pdf-preview/bulk`, payload, {
      responseType: 'blob'
    });
    const { data } = response;
    if (data) {
      var blob = new Blob([data], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      window.open(url);
      return data;
    }
    return null;
  } catch (e) {
    dispatchReportsError(getAPIResponseError(e) || 'Unable to download Invoices, please try again later', dispatch);
    return null;
  } finally {
    dispatch(setReportsLoader(false));
  }
};

function dispatchReportsError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
