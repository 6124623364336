import styled, { css } from 'styled-components';
import { Headertitle, Mediumtext, Smalltext, Textdotdot, flex, flexspacebetween } from '../../../font-variable';

// cart modal
const ContentRightModal = styled.div`
  width: 100%;
  padding: 2px 0px 2px 24px;
  @media (max-width: 767px) {
    padding: 2px 2px 2px 12px;
  }
`;

const CatalougeName = styled.h2`
  ${Mediumtext};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin-bottom: 10px;
  margin: 0px;
  ${Textdotdot};
`;

const ContentLeft = styled.div`
  height: 165px;
  max-width: 120px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--background-image);
`;

const ProductImg = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 767px) {
    object-fit: contain;
  }
`;

const ProductDetail = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  text-transform: uppercase;
`;

const ProductDetailRight = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  text-align: right;
  white-space: nowrap;
`;

const ProductDetailTotal = styled.p`
  ${Mediumtext};
  color: var(--grey-color);
  text-transform: capitalize;
  margin: 0;
`;

const ProductDetailSubTotal = styled.p`
  ${Mediumtext};
  color: var(--grey-color);
  text-transform: capitalize;
  margin: 0;
`;

const ProductDetailRightTotal = styled.span`
  ${Mediumtext};
  font-weight: 600;
  color: var(--black-light-color);
  text-align: right;
  white-space: nowrap;
`;

const ShippingText = styled.p`
  ${Mediumtext};
  font-weight: 600;
  color: var(--black-light-color);
  text-align: right;
  width: 100%;
  margin: 0;
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  flex-direction: column;
  background: var(--secondary-color);
  position: sticky;
  top: 0px;
  z-index: 2;
  margin-bottom: 0px;
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const ItemLeft = styled.div``;

const HeaderHeading = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 0;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const ItemRight = styled.div`
  ${flex};
  svg {
    cursor: pointer;
  }
`;

const HeaderBlock = styled.div`
  ${flexspacebetween};
  width: 100%;
`;

const CartItemList = styled.div``;

const ContentTwo = styled.div`
  background: var(--secondary-color);
  border-bottom: 1px solid var(--secondary-border-color);
  border-radius: 0px;
  margin: 0px 24px 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: 767px) {
    margin: 0px 16px 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ProductInfoTwo = styled.div`
  ${flexspacebetween};
  border-bottom: 1px solid var(--secondary-border-color);
  padding: 12px 0 12px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const Addmain = styled.div`
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 16px 16px;
  }
`;

const Address = styled.span`
  ${Mediumtext};
  font-weight: 700;
  color: var(--header-title-color);
`;

const Adddetail = styled.div`
  background: var(--background-color);
  border-radius: 4px;
  padding: 16px 16px;
  margin-top: 8px;
  display: flex;
  @media (max-width: 767px) {
    padding: 12px 12px;
  }
`;

const AddText = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  margin-left: 12px;
`;

const PriceList = styled.div`
  padding: 0 24px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const TextView = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-top: 24px;
  @media (max-width: 767px) {
    padding: 0 16px;
    margin-top: 16px;
  }
`;

const TextArea = styled.textarea`
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 4px;
  resize: none;
  width: 100%;
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  overflow-x: auto;
  outline: 0;
  ${Mediumtext};
  color: var(--black-light-color);
  padding: 8px 14px;
  ::placeholder {
    color: var(--placeholder-color);
  }
  :hover {
    border: 1px solid var(--input-border-hover-color);
  }
`;

const BtnDiv = styled.div`
  padding: 24px 24px 0;
  @media (max-width: 767px) {
    padding: 16px 16px 0;
  }
  button {
    margin-top: 0px;
  }
`;

const AlertText = styled.span`
  ${Smalltext};
  color: var(--cart-text-color);
  padding-top: 2px;
`;

const BottomDiv = styled.div`
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 16px 16px;
  }
`;

const SubmitButton = styled.div`
  display: inline-block;
`;

const ProductInfoList = styled.div`
  ${flexspacebetween};
  padding: 6px 0 6px;
  &:last-child {
    padding-bottom: 0;
  }
  ${(props) =>
    props.isHidePrice &&
    css`
      padding: 25px 0;
    `}
`;

const ErrorWrap = styled.div``;

export {
  HeaderWrapper,
  ItemLeft,
  HeaderHeading,
  ItemRight,
  HeaderBlock,
  ContentTwo,
  ProductInfoTwo,
  ContentLeft,
  ContentRightModal,
  CatalougeName,
  ProductImg,
  ProductDetail,
  ProductDetailRight,
  ProductDetailSubTotal,
  ProductDetailTotal,
  ProductDetailRightTotal,
  Addmain,
  Address,
  Adddetail,
  AddText,
  PriceList,
  TextView,
  TextArea,
  BtnDiv,
  BottomDiv,
  AlertText,
  SubmitButton,
  ProductInfoList,
  ShippingText,
  CartItemList,
  ErrorWrap
};
