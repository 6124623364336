import React, { Component } from 'react';
import styled from 'styled-components';
import dotImage from '../../assets/images/svg/invoicedot.svg';
import { currencyWithDecimal, getFormattedAddress } from '../../helper/common';
import { Mediumtext, Normaltext, Smalltext, flex, flexcenter } from '../../font-variable';

const InvoiceBlock = styled.div`
  position: relative;
  width: 100%;
  max-width: 97%;
  display: flex;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  .table {
    width: 100%;
    border: 1px solid var(--secondary-border-color);
    background-image: ${(props) => props.paymentStatus};
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 90px 81px;
  }
  .tableone {
    width: 100%;
  }
  td {
    text-align: left;
    padding: 0;
  }
  .invoice-padding {
    padding: 20px 20px;
  }
  .ith-business-name {
    width: 180px;
    padding-right: 40px;
    span {
      width: 120px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    span img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }
  .invoicedetailWrapper {
    h1 {
      ${Mediumtext};
      color: var(--black-light-color);
      margin: 0 0 10px 0;
      text-transform: capitalize;
    }
    p {
      ${Smalltext};
      color: var(--grey-color);
      margin: 0 0 10px 0;
      width: 100%;
      max-width: 330px;
    }
    ul {
      float: left;
      padding: 0px;
      margin: 0 -7px;
      ${flexcenter};
    }
    li {
      ${Smalltext};
      color: var(--grey-color);
      list-style: none;
      padding: 0 7px;
    }
  }
  .ngcontent {
    width: 100%;
    border-width: 1px 0px 0;
    border-style: solid;
    border-color: var(--secondary-border-color);
    background: var(--background-color);
  }
  .tdngcontent {
    border-bottom: 1px solid var(--secondary-border-color);
    padding: 12px 15px 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    h2 {
      ${Smalltext};
      color: var(--black-light-color);
      margin: 0 15px 0 0;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px 12px;
    }
    li {
      ${Smalltext};
      color: var(--grey-color);
      list-style: none;
      display: flex;
    }
    p {
      margin: 0;
    }
  }
  .tableadd {
    border-collapse: collapse;
    width: 100%;
  }
  .tabletr {
    vertical-align: top;
  }
  .tabletd {
    width: 50%;
    padding: 15px 20px 10px 15px;
    border-right: 1px solid var(--secondary-border-color);
    border-bottom: 1px solid var(--secondary-border-color);
  }
  .tabletdtwo {
    width: 50%;
    padding: 15px 20px 10px 15px;
    border-bottom: 1px solid var(--secondary-border-color);
  }
  .tablecd {
    width: 100%;
    border-collapse: collapse;
    .billandshiptotitle {
      ${Smalltext};
      color: var(--grey-color);
    }
  }
  .addresstd {
    padding-top: 5px;
    p {
      ${Smalltext};
      color: var(--grey-color);
      margin: 10px 0;
    }
    .nameofclient {
      ${Smalltext};
      color: var(--black-light-color);
      margin: 0px 0 10px 0;
    }
    .brwidth {
      width: 100%;
      max-width: 285px;
    }
  }
  .tabletwo {
    width: 100%;
    border-collapse: collapse;
    table {
      width: 100%;
    }
    .thead {
      .table-heading {
        background-color: var(--background-color);
        th {
          ${Smalltext};
          color: var(--grey-color);
          border-bottom: 1px solid var(--secondary-border-color);
          text-align: left;
          text-transform: capitalize;
          padding: 12px 6px;

          :nth-child(1) {
            width: 6%;
            padding-left: 12px;
          }
          :nth-child(2) {
            width: 24%;
          }
          :nth-child(3) {
            width: 9%;
            text-align: center;
          }
          :nth-child(4) {
            width: 8%;
            text-align: center;
          }
          :nth-child(5) {
            width: 8%;
            text-align: center;
          }
          :nth-child(6) {
            width: 8%;
            text-align: center;
          }
          :last-child {
            width: 8%;
            padding-right: 12px;
          }
        }
      }
    }
  }
  .tabeltr-heading {
    td {
      ${Smalltext};
      color: var(--grey-color);
      border-bottom: 1px solid var(--secondary-border-color);
      padding: 12px 6px;
      text-align: left;
      text-transform: capitalize;

      :nth-child(1) {
        width: 3%;
        padding-left: 12px;
      }
      :nth-child(2) {
        width: 24%;
        span {
          word-break: break-word;
          display: block;
        }
      }
      :nth-child(3) {
        width: 8%;
        text-align: center;
        div {
          ${flexcenter};
        }
        span {
          ${Normaltext};
          color: var(--grey-color);
          background: var(--background-dropdown);
          border-radius: 30px;
          margin: 0 0 0 4px;
          padding: 2px 8px;
        }
      }
      :nth-child(4) {
        width: 8%;
        text-align: center;
        span {
          ${Normaltext};
          color: var(--grey-color);
          background: var(--background-dropdown);
          border-radius: 30px;
          margin: 0 0 0 4px;
          padding: 2px 8px;
        }
      }
      :nth-child(5) {
        width: 8%;
        text-align: center;
      }
      :nth-child(6) {
        width: 8%;
        text-align: center;
      }
      :last-child {
        width: 8%;
        padding-right: 12px;
      }
    }
  }
  .table-pcs {
    ${Normaltext};
    color: var(--grey-color);
    margin-left: 3px;
  }
  .totaltablewrapper {
    width: 100%;
    border-collapse: collapse;
    .tabletotal {
      border-collapse: collapse;
      /* table-layout: fixed; */
      padding: 10px 0 10px 0;
      width: 100%;
      .table-heading {
        td {
          ${Smalltext};
          color: var(--black-light-color);
          border-bottom: 1px solid var(--secondary-border-color);
          text-align: left;
          padding: 12px 6px;
          text-transform: capitalize;
        }
        td:nth-child(1) {
          width: 3%;
          padding-left: 12px;
        }
        td:nth-child(2) {
          width: 24%;
        }
        td:nth-child(3) {
          width: 8%;
          text-align: center;
        }
        td:nth-child(4) {
          width: 8%;
        }
        td:nth-child(5) {
          width: 8%;
        }
        td:nth-child(6) {
          width: 8%;
        }
        td:last-child {
          width: 8%;
          padding-right: 12px;
        }
      }
    }
    .taxable-amount-wrapper {
      width: 100%;
      height: 100%;
      .gst-and-account-information {
        padding: 0;
        vertical-align: top;
        border-right: 1px solid var(--secondary-border-color);
        width: 70%;
        position: relative;
        text-align: left;
        .tablewrapper {
          width: 100%;
          height: 100%;
          border-collapse: inherit;
          th {
            ${Normaltext};
            color: var(--grey-color);
            border-bottom: 1px solid var(--secondary-border-color);
            border-right: 1px solid var(--secondary-border-color);
            text-transform: capitalize;
            text-align: center;
            padding: 6px 8px;
          }
          .tablewidthone {
            width: 18%;
          }
          .tablewidth:last-child {
            border-right: 0px;
          }
          .tablewidth {
            width: 24%;
          }
          .tabletrwrapper {
            th:last-child {
              border-right: 0px;
            }
          }
          .ng-star-inserted {
            td {
              ${Smalltext};
              color: var(--black-light-color);
              border-bottom: 1px solid var(--secondary-border-color);
              border-right: 1px solid var(--secondary-border-color);
              padding: 9px 0;
              text-align: center;
              :last-child {
                border-right: 0px;
              }
            }
          }
        }
      }
      .tfoot {
        td {
          ${Normaltext};
          color: var(--grey-color);
          border-right: 1px solid var(--secondary-border-color);
          text-transform: capitalize;
          text-align: center;
          padding: 6px 8px;
        }
        td:last-child {
          border-right: 0px;
        }
      }
      .invoice-total-block {
        padding-top: 4px;
        padding-bottom: 0px;
        width: 30%;
        .titeltd {
          ${Smalltext};
          color: var(--grey-color);
          padding: 4px 12px;
          text-align: left;
          &:last-child {
            padding-bottom: 8px;
          }
        }
        .totaltd {
          ${Smalltext};
          color: var(--black-light-color);
          padding: 4px 12px;
          text-align: right;
          &:last-child {
            padding-bottom: 8px;
          }
        }
      }
    }
    .tfootwrapper {
      td {
        ${Smalltext};
        color: var(--black-light-color);
        border-top: 1px solid var(--secondary-border-color);
        padding: 6px 12px !important;
        text-align: right;
        .titeltd {
          padding: 4px 12px;
          color: var(--grey-color);
          text-align: left;
        }
        .totaltd {
          /* padding: 4px 12px; */
          color: var(--black-light-color);
          text-align: right;
        }
      }
    }
    .notewrapper {
      td {
        padding: 10px 12px;
        ${flex};
      }
      label {
        ${Normaltext};
        color: var(--black-light-color);
        display: block;
        margin-right: 6px;
      }
      span {
        ${Normaltext};
        color: var(--grey-color);
        display: block;
      }
    }
    .footer-wrapper {
      .td {
        width: 70%;
        padding: 10px 0 10px 15px;
        vertical-align: top;
        border-top: 1px solid var(--secondary-border-color);
        span {
          ${Mediumtext};
          color: var(--black-light-color);
          margin-bottom: 10px;
          display: block;
        }
        p {
          ${Smalltext};
          color: var(--grey-color);
          margin: 0;
        }
      }
      .tdwrapper {
        width: 30%;
        padding-top: 80px;
        vertical-align: bottom;
        border-top: 1px solid var(--secondary-border-color);
        table {
          width: 100%;
        }
      }
    }
    .authorization-td {
      padding: 10px 15px 10px 20px;
      span {
        ${Normaltext};
        color: var(--grey-color);
        border-top: 1px solid var(--border-color);
        padding-top: 6px;
        white-space: nowrap;
      }
    }
    .rightcompany {
      text-align: right;
      padding: 20px;
      span {
        ${Normaltext};
        color: var(--grey-color);
        border-top: 1px solid var(--border-color);
        padding-top: 6px;
      }
    }
    .bordertop {
      border-top: 1px solid var(--secondary-border-color);
    }
  }
`;

const InvoiceSpan = styled.span`
  border: 1px solid var(--secondary-border-color);
  border-right: 0px;
  width: 28px;
  height: 100%;
  background-image: url(${dotImage});
  background-repeat: repeat-y;
  background-position: center;
  background-size: 26px;
  position: absolute;
  left: -28px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const InvoiceSpanTwo = styled.span`
  border: 1px solid var(--secondary-border-color);
  border-left: 0px;
  width: 28px;
  height: 100%;
  background-image: url(${dotImage});
  background-repeat: repeat-y;
  background-position: center;
  background-size: 26px;
  position: absolute;
  right: -28px;
  left: unset;
  @media (max-width: 1024px) {
    display: none;
  }
`;

class DefaultInvoiceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { company, invoice, taxes, taxes_total } = this.props.invoicePreview;
    const { contact = {}, items } = invoice || {};
    const formattedAdd = getFormattedAddress(company);
    const billingFormattedAdd = getFormattedAddress(invoice?.billing_address);
    const shippingFormattedAdd = getFormattedAddress(invoice?.shipping_address);
    return (
      <InvoiceBlock>
        <InvoiceSpan />
        <table className='table'>
          <tbody>
            <tr>
              <td>
                <table className='tableone'>
                  <tbody>
                    <tr>
                      <td className='invoice-padding'>
                        <table>
                          <tbody>
                            <tr>
                              <td className='ith-business-name'>
                                {company?.logo_url && (
                                  <span>
                                    <img src={company?.logo_url} alt={'Logo'} />
                                  </span>
                                )}
                              </td>
                              <td className='invoicedetailWrapper'>
                                <h1>{company?.name}</h1>
                                <p>{formattedAdd}</p>
                                <ul>
                                  {!!company.gst_number && (
                                    <li>
                                      <span>GSTIN:</span> {company?.gst_number}
                                    </li>
                                  )}
                                  {!!company.email && (
                                    <li>
                                      <span>Email:</span> {company?.email}
                                    </li>
                                  )}
                                  {!!company.mobile && (
                                    <li>
                                      <span>Phone:</span> {company?.mobile}
                                    </li>
                                  )}
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className='ngcontent'>
                          <tbody>
                            <tr>
                              <td className='tdngcontent'>
                                <h2>Invoice</h2>
                                <ul>
                                  <li>Date: {invoice?.date}</li>
                                  <li>Date Due: {invoice?.due_date}</li>
                                  <li>Number: {invoice?.number}</li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table className='tableadd'>
                          <tbody>
                            <tr className='tabletr'>
                              <td className='tabletd'>
                                <table className='tablecd'>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span className='billandshiptotitle'>BILL TO :</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='addresstd'>
                                        <p className='nameofclient'>{contact?.company_name}</p>
                                        <p className='brwidth'>{billingFormattedAdd}</p>
                                        {!!contact?.gst_no && <p>GSTIN: {contact?.gst_no}</p>}
                                        <p>{contact?.email}</p>
                                        {contact?.phone_numbers && contact?.phone_numbers.length > 0 && (
                                          <p>{contact?.phone_numbers.join(', ')}</p>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className='tabletdtwo'>
                                <table className='tablecd'>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <span className='billandshiptotitle'>SHIP TO :</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='addresstd'>
                                        <p className='nameofclient'>{contact?.company_name}</p>
                                        <p className='brwidth'>{shippingFormattedAdd}</p>
                                        {contact?.phone_numbers && contact?.phone_numbers?.length > 0 && (
                                          <p>{contact?.phone_numbers.join(', ')}</p>
                                        )}
                                        <p>{contact?.email}</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className='tabletwo'>
                  <tbody>
                    <tr>
                      <td>
                        <table className='tablewrapper'>
                          <thead className='thead'>
                            <tr className='table-heading'>
                              <th>No</th>
                              <th>Item Name</th>
                              <th>HSN Code</th>
                              <th>Qty</th>
                              <th>Rate</th>
                              <th>Disc</th>
                              <th>GST</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items &&
                              items.length > 0 &&
                              items.map((item, i) => {
                                return (
                                  <tr className='tabeltr-heading' key={item?.id}>
                                    <td>{String(i + 1).padStart(2, '0')}</td>
                                    <td>
                                      {item?.product_name}
                                      <span>{item?.desc}</span>
                                    </td>
                                    <td>{item?.hsn_code}</td>
                                    <td>
                                      {item?.qty}
                                      <span className='table-pcs'>Pcs</span>
                                    </td>
                                    <td>₹{currencyWithDecimal(item.rate)}</td>
                                    <td>{item?.discount}%</td>
                                    <td>{item?.tax_rate}%</td>
                                    <td>₹{currencyWithDecimal(item?.amount)}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className='totaltablewrapper'>
                  <tbody>
                    <tr>
                      <td>
                        <table className='tabletotal'>
                          <tbody>
                            <tr className='table-heading'>
                              <td>Total</td>
                              <td></td>
                              <td></td>
                              <td>{invoice?.total_qty}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td colSpan='10'>
                                <table className='taxable-amount-wrapper'>
                                  <tbody>
                                    <tr>
                                      <td className='gst-and-account-information'>
                                        <table className='tablewrapper'>
                                          <thead>
                                            <tr>
                                              <th className='tablewidthone' rowSpan='2'>
                                                Taxable Amount
                                              </th>
                                              <th className='tablewidth' colSpan='2'>
                                                CGST
                                              </th>
                                              <th className='tablewidth' colSpan='2'>
                                                SGST
                                              </th>
                                              <th className='tablewidth' colSpan='2'>
                                                IGST
                                              </th>
                                            </tr>
                                            <tr className='tabletrwrapper'>
                                              <th>Rate</th>
                                              <th>Amount</th>
                                              <th>Rate</th>
                                              <th>Amount</th>
                                              <th>Rate</th>
                                              <th>Amount</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {taxes &&
                                              taxes.map((tax, i) => {
                                                return (
                                                  <tr key={i} className='ng-star-inserted'>
                                                    <td>₹{tax.amount}</td>
                                                    <td>{tax?.cgst !== 0 ? tax?.tax_rate : 0}%</td>
                                                    <td>₹{currencyWithDecimal(tax?.cgst)}</td>
                                                    <td>{tax?.sgst !== 0 ? tax?.tax_rate : 0}%</td>
                                                    <td>₹{currencyWithDecimal(tax?.sgst)}</td>
                                                    <td>{tax?.igst !== 0 ? tax?.tax_rate : 0}%</td>
                                                    <td>₹{currencyWithDecimal(tax?.igst)}</td>
                                                  </tr>
                                                );
                                              })}
                                          </tbody>
                                          <tfoot className='tfoot'>
                                            <tr>
                                              <td>₹{currencyWithDecimal(taxes_total?.total_taxable_amount)}</td>
                                              <td colSpan='2'>₹{currencyWithDecimal(taxes_total?.total_cgst)}</td>
                                              <td colSpan='2'>₹{currencyWithDecimal(taxes_total?.total_sgst)}</td>
                                              <td colSpan='2'>₹{currencyWithDecimal(taxes_total?.total_igst)}</td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </td>
                                      <td className='invoice-total-block'>
                                        <table>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <table>
                                                  <tbody>
                                                    {invoice?.sub_total_amount !== 0 && (
                                                      <tr>
                                                        <td className='titeltd'>Sub Total</td>
                                                        <td className='totaltd'>
                                                          ₹{currencyWithDecimal(invoice?.sub_total_amount)}
                                                        </td>
                                                      </tr>
                                                    )}
                                                    {invoice?.total_discount !== 0 && (
                                                      <tr>
                                                        <td className='titeltd'>Discount</td>
                                                        <td className='totaltd'>
                                                          (-)₹
                                                          {currencyWithDecimal(invoice?.total_discount)}
                                                        </td>
                                                      </tr>
                                                    )}
                                                    {invoice?.shipping_charge !== 0 && (
                                                      <tr>
                                                        <td className='titeltd'>Shipping</td>
                                                        <td className='totaltd'>
                                                          ₹{currencyWithDecimal(invoice?.shipping_charge)}
                                                        </td>
                                                      </tr>
                                                    )}
                                                    {invoice?.adjustment !== 0 && (
                                                      <tr>
                                                        <td className='titeltd'>Adjustment</td>
                                                        <td className='totaltd'>
                                                          ₹{currencyWithDecimal(invoice?.adjustment)}
                                                        </td>
                                                      </tr>
                                                    )}
                                                    {invoice?.additional_fields?.map((field, i) => {
                                                      return (
                                                        <>
                                                          {field?.amount !== 0 && (
                                                            <tr key={i}>
                                                              <td className='titeltd'>{field?.name}</td>
                                                              <td className='totaltd'>
                                                                ₹{currencyWithDecimal(field?.amount)}
                                                              </td>
                                                            </tr>
                                                          )}
                                                        </>
                                                      );
                                                    })}
                                                  </tbody>
                                                  <tfoot className='tfootwrapper'>
                                                    <tr>
                                                      <td className='titeltd'>Total (INR)</td>
                                                      <td className='totaltd'>
                                                        <span>₹{currencyWithDecimal(invoice?.total_amount)}</span>
                                                      </td>
                                                    </tr>
                                                  </tfoot>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <td colSpan='10' className='bordertop'>
                              <table>
                                <tbody>
                                  <tr className='notewrapper'>
                                    <td>
                                      <label>Rupees in Words:</label>
                                      <span>{invoice.amount_in_word.toUpperCase()}</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <tr className='footer-wrapper'>
                              <td colSpan='7' className='td'>
                                <span>Terms & Conditions</span>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: invoice.notes
                                  }}></p>
                              </td>
                              <td colSpan='3' className='tdwrapper'>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className='authorization-td'>
                                        <span>Authorization Signature</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <InvoiceSpanTwo />
      </InvoiceBlock>
    );
  }
}

export default DefaultInvoiceTemplate;
