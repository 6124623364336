import React, { Component } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/closeicon.svg';
import { ReactComponent as GreenCheak } from '../../../assets/images/svg/greenright.svg';
import Button from '../../../components/Button/Button';
import {
  HeaderWrapper,
  ItemLeft,
  HeaderHeading,
  ItemRight,
  HeaderBlock,
  ContentTwo,
  ProductInfoTwo,
  ContentLeft,
  ContentRightModal,
  CatalougeName,
  ProductImg,
  ProductDetail,
  ProductDetailRight,
  ProductDetailSubTotal,
  ProductDetailTotal,
  ProductDetailRightTotal,
  Addmain,
  Address,
  Adddetail,
  AddText,
  PriceList,
  TextView,
  TextArea,
  BtnDiv,
  BottomDiv,
  AlertText,
  SubmitButton,
  ProductInfoList,
  ShippingText,
  CartItemList,
  ErrorWrap
} from './style';
import { connect } from 'react-redux';
import { currencyWithDecimal, getDate, getFormattedAddress } from '../../../helper/common';
import UserPreferenceSingleton from '../../../helper/UserPreferenceSingleton';
import { createCatalogOrder } from '../../../services/catalogService';
import Message from '../../../components/Message';
import classNames from 'classnames';
import { updateCartItems } from '../../../actions/CartAction';

class OrderDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: ''
    };
  }

  onChangeNotes = (e) => {
    this.setState({ notes: e.target.value });
  };

  renderErrors() {
    const { itemResError } = this.props.message;
    if (itemResError) {
      return (
        <ErrorWrap>
          <Message text={itemResError} type={'error'} />
        </ErrorWrap>
      );
    }
  }

  onClickConfirmOrder = async (totalAmount, totalQuantity) => {
    const company_id = UserPreferenceSingleton.getInstance().getCompanyId();
    const { customerItem } = this.props.customer;
    const { notes } = this.state;
    let { cart } = this.props.cart;
    let finalCart = [];
    cart?.forEach((item) => {
      if (!item?.isFromCatalog) {
        finalCart.push(item);
      } else {
        item?.products?.forEach((productItem) => {
          const product = productItem;
          product.orderedQty = item?.itemOrderedQty;
          finalCart.push(product);
        });
      }
    });
    const items = finalCart.map((product) => {
      return {
        product: {
          id: product?.id,
          name: product?.name
        },
        qty: product?.orderedQty,
        contact: customerItem,
        rate: Number(product?.price_sales),
        amount: Number(product?.price_sales) * Number(product?.orderedQty)
      };
    });
    const postData = {
      date: getDate(new Date()),
      items,
      total_qty: totalQuantity,
      notes,
      status: 1
    };

    const result = await this.props.createCatalogOrder(company_id, postData);
    if (result) {
      this.props.handleClose();
      this.props.updateCartItems([]);
      UserPreferenceSingleton.getInstance().setCartItems([]);
    }
  };

  render() {
    const { notes } = this.state;
    const { cart } = this.props.cart;
    const { loading } = this.props.catalog;
    const { customerItem, customerSettings } = this.props.customer;
    const { hide_price } = customerSettings.profile_settings || {};
    const address =
      customerItem &&
      customerItem?.addresses?.length > 0 &&
      customerItem?.addresses[0] &&
      getFormattedAddress(customerItem?.addresses[0]);
    const totalQuantity = cart?.length;
    const totalAmount = cart?.reduce((sum, val) => sum + Number(val?.totalAmt || 0), 0);
    return (
      <>
        <HeaderWrapper>
          <HeaderBlock>
            <ItemLeft>
              <HeaderHeading>Order Details</HeaderHeading>
            </ItemLeft>
            <ItemRight>
              <CloseIcon onClick={this.props.handleClose} />
            </ItemRight>
          </HeaderBlock>
        </HeaderWrapper>
        {this.renderErrors()}
        <CartItemList>
          {cart?.map((item, index) => {
            return (
              <ContentTwo key={`${item?.id}_${index}`}>
                <ContentLeft>
                  <ProductImg src={!item?.isFromCatalog ? item?.gallery?.[0]?.url : item?.img} />
                </ContentLeft>
                <ContentRightModal>
                  <CatalougeName>{item?.name}</CatalougeName>
                  <ProductInfoList>
                    <ProductDetail>QTY</ProductDetail>
                    <ProductDetailRight>{item?.orderedQty}</ProductDetailRight>
                  </ProductInfoList>
                  <ProductInfoList isHidePrice={hide_price}>
                    {!hide_price && (
                      <>
                        <ProductDetail>Price</ProductDetail>
                        <ProductDetailRight>₹ {currencyWithDecimal(item?.totalAmt)}</ProductDetailRight>
                      </>
                    )}
                  </ProductInfoList>
                </ContentRightModal>
              </ContentTwo>
            );
          })}
        </CartItemList>
        <Addmain>
          <Address>Address</Address>
          <Adddetail>
            <GreenCheak />
            <AddText>{address}</AddText>
          </Adddetail>
          <AlertText>Cash orders need to be ordered only on WhatsApp.</AlertText>
        </Addmain>
        {!hide_price && (
          <PriceList>
            <ProductInfoTwo>
              <ProductDetailSubTotal>subtotal</ProductDetailSubTotal>
              <ProductDetailRightTotal>₹ {currencyWithDecimal(totalAmount)}</ProductDetailRightTotal>
            </ProductInfoTwo>
            <ProductInfoTwo>
              <ProductDetailTotal>Total</ProductDetailTotal>
              <ProductDetailRightTotal>₹ {currencyWithDecimal(totalAmount)}</ProductDetailRightTotal>
            </ProductInfoTwo>
            <ProductInfoTwo>
              <ShippingText>+ GST & Shipping charges if Any</ShippingText>
            </ProductInfoTwo>
          </PriceList>
        )}
        <TextView>
          <TextArea placeholder={'Your Notes...'} value={notes} onChange={this.onChangeNotes}></TextArea>
        </TextView>
        <BtnDiv>
          <SubmitButton className={classNames('btn-save-loading', { loading: loading })}>
            <Button
              fullwidth
              isPrimary={true}
              disabled={loading}
              title={'Confirm Order'}
              onClick={() => {
                this.onClickConfirmOrder(totalAmount, totalQuantity);
              }}
            />
          </SubmitButton>
        </BtnDiv>
        <BottomDiv>
          <AlertText>
            {
              'The total price might vary if you want shipping facility. However, if you use your shipping method, then no charges will be levied.'
            }
          </AlertText>
        </BottomDiv>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  customer: state.customer,
  catalog: state.catalog,
  cart: state.cart
});

export default connect(mapStateToProps, { createCatalogOrder, updateCartItems })(OrderDetailsModal);
