import { css } from 'styled-components';

const flex = css`
  display: flex;
  align-items: center;
`;

const flexcenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const flexspacebetween = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Maintitle = css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

const Headertitle = css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const Mediumtext = css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`;

const Smalltext = css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

const Normaltext = css`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const Textdotdot = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: inherit;
`;

export { Maintitle, Headertitle, Mediumtext, Smalltext, Normaltext, flexcenter, flexspacebetween, flex, Textdotdot };
