import React, { Component } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import { Mediumtext, Smalltext, flex, flexcenter, flexspacebetween } from '../../font-variable';

const ConfirmationModalWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ConfirmationModalBlock = styled.div`
  ${flexspacebetween}
  position: sticky;
  top: 0px;
  z-index: 99;
  padding: 12px 18px;
  margin-bottom: 18px;
  border-radius: 6px 6px 0 0;
  background: var(--secondary-color);
  border-bottom: 1px solid var(--secondary-border-color);
`;

const IconButton = styled.div`
  ${flex};
  margin-left: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    display: block;
  }
`;

const ConfirmationModalSpan = styled.span`
  ${Mediumtext};
  color: var(--header-title-color);
`;

const ModalBlock = styled.div`
  ${Smalltext};
  color: var(--header-title-color);
  text-align: center;
  margin: 0 18px 18px;
`;

const ConfirmationButton = styled.div`
  ${flexcenter};
  padding: 0 18px 18px;
  button {
    ${(props) =>
      props.colors &&
      css`
        background: ${props.colors};
      `}
    ${Smalltext};
    color: var(--text-color);
    border: 1px solid var(--secondary-border-color);
    border-radius: 6px;
    padding: 7px 12px;
    text-align: center;
    margin-right: 8px;
    text-transform: capitalize;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
    :hover {
      background: var(--red-color);
    }
    ${(props) =>
      props.hovercolors &&
      css`
        :hover {
          background: ${props.hovercolors};
        }
      `}
  }
  button:nth-child(2) {
    color: var(--black-light-color);
    background: var(--secondary-color);
    margin-right: 0px;
    :hover {
      border: 1px solid var(--black-light-color);
      color: var(--black-light-color);
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease;
    }
  }
`;

const SubmitButton = styled.div`
  display: inline-block;
`;

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickConfirm = (e) => {
    if (this.props.onDelete) this.props.onDelete();

    if (this.props.onConfirm) this.props.onConfirm(e);
  };

  onClickCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
  };

  render() {
    const { message, loading, confirmButtonText = 'Delete', color = '#FF4B4B', hovercolor = '#f10101' } = this.props;
    return (
      <ConfirmationModalWrapper>
        <ConfirmationModalBlock id='confirmation-dialog-title'>
          <ConfirmationModalSpan>Confirm</ConfirmationModalSpan>
          <IconButton onClick={this.onClickCancel}>
            <CloseIcon />
          </IconButton>
        </ConfirmationModalBlock>
        <ModalBlock>{message}</ModalBlock>
        <ConfirmationButton colors={color} hovercolors={hovercolor}>
          <SubmitButton className={classNames('btn-save-loading btn-red', { loading: loading })}>
            <Button disabled={loading} onClick={this.onClickConfirm}>
              {confirmButtonText}
            </Button>
          </SubmitButton>
          <Button disabled={loading} onClick={this.onClickCancel}>
            Cancel
          </Button>
        </ConfirmationButton>
      </ConfirmationModalWrapper>
    );
  }
}

export default ConfirmationModal;
