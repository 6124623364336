import * as Actions from './types';

/**
 * @desc Set Reports Loader
 */
export const setReportsLoader = (payload) => {
  return {
    type: Actions.SET_REPORTS_LOADER,
    payload: payload
  };
};
// Set Ledger Report
export const setLedgerReport = (payload) => {
  return {
    type: Actions.SET_LEDGER_REPORT,
    payload: payload
  };
};

/**
 * @desc Clear Report Data
 */
export const clearReportData = () => {
  return {
    type: Actions.CLEAR_REPORTS_DATA
  };
};

/**
 * @desc Set Invoice List
 */
export const setInvoiceList = (payload) => {
  return {
    type: Actions.SET_INVOICE_LIST,
    payload: payload
  };
};

/**
 * @desc Set Filtered Invoice List
 */
export const setFilteredInvoiceList = (payload) => {
  return {
    type: Actions.SET_FILTERED_INVOICE_LIST,
    payload: payload
  };
};
/**
 * @desc Set Invoice Preview
 */
export const setIvoicePreview = (payload) => {
  return {
    type: Actions.SET_INVOICE_PREVIEW,
    payload: payload
  };
};
