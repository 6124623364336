import * as Actions from '../actions/types';

const initialState = {
  isShowLayout: true
};

export const SideMenuReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.TOGGLE_SIDEMENU:
      return {
        ...state,
        isShowLayout: !state.isShowLayout
      };
    default:
      return state;
  }
};
