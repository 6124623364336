import * as Actions from './../actions/types';

const initialState = {
  paginatedList: [],
  currentPage: 0,
  totalPage: 0,
  totalRecords: 0
};

export const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_PAGINATED_LIST:
      return {
        ...state,
        paginatedList: action.payload
      };
    case Actions.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };
    case Actions.SET_TOTAL_PAGE:
      return {
        ...state,
        totalPage: action.payload
      };
    case Actions.SET_TOTAL_RECORDS:
      return {
        ...state,
        totalRecords: action.payload
      };
    case Actions.CLEAR_PAGINATION_DATA:
      return initialState;
    default:
      return state;
  }
};
