import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import Message from '../../components/Message';
import { clearResponseMessage } from '../../actions/messageActions';
import {
  SaleWrapper,
  SaleModalWrap,
  HeaderWrapper,
  OverViewDetailsBlock,
  OverViewTabItemWrapper,
  DT,
  HeaderBlock,
  ItemLeft,
  HeaderHeading,
  ItemWrap
} from './style';

class AnnouncementDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount = () => {
    this.props.clearResponseMessage();
  };

  renderErrors() {
    const { itemResError } = this.props.message;
    if (itemResError) {
      return <Message text={itemResError} type={'error'} />;
    }
  }

  onClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { selectedItem } = this.props;
    const { title, description } = selectedItem || {};
    return (
      <>
        <SaleWrapper>
          <SaleModalWrap>
            <HeaderWrapper>
              <HeaderBlock>
                <ItemLeft>
                  <HeaderHeading>{title}</HeaderHeading>
                </ItemLeft>
                <ItemWrap>
                  <CloseIcon onClick={this.onClose} />
                </ItemWrap>
              </HeaderBlock>
              {this.renderErrors()}
            </HeaderWrapper>
            <OverViewTabItemWrapper>
              <OverViewDetailsBlock>
                <DT description>{description}</DT>
              </OverViewDetailsBlock>
            </OverViewTabItemWrapper>
          </SaleModalWrap>
        </SaleWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message
});

export default connect(mapStateToProps, {
  clearResponseMessage
})(AnnouncementDetailModal);
