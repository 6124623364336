import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { setCatalogList, setCatalogLoader } from '../actions/catalogsActions';
import { clearResponseMessage, setErrorMessage, setItemErrorMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../helper/common';
import { REQUEST_CANCEL_MESSAGE } from '../global/constants';

let ajaxCatalogListRequest = null;

/**
 * @desc Catalog - Get Catalog List
 * @param {*} obj Data Obj
 */
export const getCatalogList = (company_id) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    const state = getState();
    if (state?.catalog?.catalogList?.length > 0) return true;
    dispatch(setCatalogLoader(true));
    if (ajaxCatalogListRequest) {
      ajaxCatalogListRequest.cancel(REQUEST_CANCEL_MESSAGE);
    }
    ajaxCatalogListRequest = axios.CancelToken.source();
    const response = await axios.get(`${REACT_APP_APIURL}/customer/products/${company_id}`, {
      cancelToken: ajaxCatalogListRequest.token
    });
    const { data } = response;
    if (data) {
      const sortedData = process(data.data, {
        sort: [{ field: 'name', dir: 'asc' }]
      });
      let list = sortedData?.data;
      dispatch(setCatalogList(list || []));
    }
    return true;
  } catch (e) {
    if (e && e.message === REQUEST_CANCEL_MESSAGE) return false;
    dispatchCatalogError(getAPIResponseError(e) || 'Unable to get Catalog please try again', dispatch);
    return false;
  } finally {
    dispatch(setCatalogLoader(false));
  }
};

/**
 * @desc company - create Catalog Order
 * @param {*} payload Data Obj
 */
export const createCatalogOrder = (company_id, payload) => async (dispatch) => {
  const isModalError = true;
  try {
    dispatch(clearResponseMessage());
    dispatch(setCatalogLoader(true));
    if (!company_id) {
      return false;
    } else if (!payload) {
      dispatchCatalogError('Select Product', dispatch, isModalError);
      return;
    } else if (!payload.total_qty) {
      dispatchCatalogError('Select Atleast One Quantity', dispatch, isModalError);
      return;
    }
    const response = await axios.post(`${REACT_APP_APIURL}/customer/order-list/${company_id}`, payload);
    const { data } = response.data;
    if (data) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatchCatalogError(
      getAPIResponseError(e) || 'Something goes wrong, please try again later',
      dispatch,
      isModalError
    );
    return false;
  } finally {
    dispatch(setCatalogLoader(false));
  }
};

function dispatchCatalogError(msg, dispatch, isModalError = false) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}
