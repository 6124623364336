import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  customerItem: undefined,
  customerAccountManager: undefined,
  customerSettings: undefined
};

export const customerReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_CUSTOMER_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case Actions.SET_CUSTOMER_ITEM:
      return {
        ...state,
        customerItem: action.payload
      };
    case Actions.SET_CUSTOMER_ACCOUNT_MANAGER:
      return {
        ...state,
        customerAccountManager: action.payload
      };
    case Actions.SET_CUSTOMER_SETTINGS:
      return {
        ...state,
        customerSettings: action.payload
      };
    case Actions.CLEAR_CUSTOMER_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
