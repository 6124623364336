import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import './App.scss';
import { createBrowserHistory } from 'history';
import routes from './routes';
import store from './store/store';
import { isDev } from './helper/common';
import PrivateRoutes from './components/PrivateRoutes';
import { setupToken } from './helper/authTokenHelpers';
import { setCurrentUser } from './actions/authActions';
import Loading from './components/Loading/Loading';
import { loadRequiredData } from './services/baseService';
const history = createBrowserHistory();

const token = setupToken();
if (token) {
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    if (isDev()) {
      console.log('Development');
    }
    this.loadData();
  }

  loadData = async () => {
    try {
      if (token) {
        await store.dispatch(loadRequiredData(history));
      }
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }
    const routeComponents = routes.map((r, i) => {
      if (r.private) {
        return <PrivateRoutes key={i} {...r} />;
      } else {
        return <Route key={i} {...r} />;
      }
    });
    return (
      <Provider store={store}>
        <Router history={history}>
          <>
            <Switch>{routeComponents}</Switch>
          </>
        </Router>
      </Provider>
    );
  }
}
export default App;
