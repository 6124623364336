import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Layout from '../../components/Layout/Layout';
import { SIDEBAR_MENU_REPORTS } from '../../global/constants';
import { ReactComponent as MenuIcon } from '../../assets/images/svg/menuicon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/svg/searchmobile.svg';
import { currencyWithDecimal, isEmpty } from '../../helper/common';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/Loading/Loading';
import Message from '../../components/Message';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getOrderList, cancelOrder } from '../../services/orderService';
import { getInvoiceList, printBulkShippingInvoice } from '../../services/reportService';
import { loadListWithPagging } from '../../services/paginationService';
import EmptyLogo from '../../assets/images/empty-report.svg';
import { toggleSideMenu } from '../../actions/SideMenuAction';
import { setFilteredInvoiceList } from '../../actions/reportActions';
import styled, { css } from 'styled-components';
import Button from '../../components/Button/Button';
import { setErrorMessage } from '../../actions/messageActions';
import {
  Headertitle,
  Mediumtext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../font-variable';
import SearchBox from '../../components/SearchBox/SearchBox';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon-small.svg';

const MainWrapper = styled.div`
  height: 100%;
`;

const LoadView = styled.div`
  ${flexspacebetween}
`;

const EmptyContain = styled.div`
  ${flexcenter};
  height: 100%;
`;

const EmptyDetail = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
`;

const EmptyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextEmpty = styled.h3`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 10px 0 0;
  text-transform: capitalize;
`;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const WrapperPlace = styled.div`
  ${flex};
  :hover {
    cursor: pointer;
  }
`;

const RightWrap = styled.div`
  ${flex};
  gap: 15px;
  position: relative;
  .pdf {
    margin: 0 5px;
  }
  a {
    ${flexcenter};
    border: 1px solid var(--secondary-border-color);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    :hover {
      border: 1px solid var(--input-border-hover-color);
    }
    @media (max-width: 767px) {
      width: 28px;
      height: 28px;
    }
  }
`;

const SearchInput = styled.input``;

const CatName = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const HeadTitle = styled.div`
  display: grid;
  grid-gap: 2px;
  ${Mediumtext};
  color: var(--black-light-color);
  text-transform: capitalize;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  height: 100%;
  ${(props) =>
    props.isPagination
      ? css`
          /* height: calc(100vh - 84px); */
        `
      : `
      height: 100vh;
    `}
`;

const SearchIconView = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: inline-flex;
    margin-right: 5px;
  }
`;

const SerchWrap = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    .close {
      position: absolute;
      right: 26px;
      z-index: 11;
      top: 8px;
      vertical-align: middle;
    }
  }
`;

const SearchViewMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    input {
      ${Smalltext};
      margin-right: 0px;
      color: var(--black-light-color);
      background: var(--secondary-color);
      border: 1px solid var(--secondary-border-color);
      border-radius: 4px;
      outline: 0;
      margin: 4px 24px;
      padding: 8px 10px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 11;
      ::placeholder {
        color: var(--placeholder-color);
      }
    }
  }
  @media (max-width: 767px) {
    input {
      margin: 0 16px;
    }
  }
`;

const SearchView = styled.div`
  display: block;
  input {
    ${Smalltext};
    background: var(--secondary-color);
    border: 1px solid var(--secondary-border-color);
    color: var(--black-light-color);
    margin-right: 0px;
    padding: 7px 10px;
    border-radius: 4px;
    outline: 0;
    ::placeholder {
      color: var(--placeholder-color);
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const MainTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  height: 100%;
`;

const MainTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
  margin-left: 24px;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    ${flex};
  }
`;

const TableIt = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Smalltext};
  color: var(--grey-color);
  text-align: left;
  padding: 1px 8px 8px;
  text-transform: capitalize;
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 1;
  background: var(--secondary-color);
  &:first-child {
  }
  &:last-child {
    text-align: right;
    padding-right: 24px;
    cursor: pointer;
  }
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border-bottom: 1px solid var(--border-color);
  padding: 16px 8px;
  white-space: nowrap;
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
  }
  &:last-child {
    cursor: pointer;
    text-align: right;
    padding-right: 24px;
  }
`;

const InputCheck = styled.input``;

const CheckBox = styled.div`
  ${flex};
  input[type='checkbox'] {
    position: relative;
    border: 1px solid var(--grey-color);
    border-radius: 4px;
    background: none;
    cursor: pointer;
    margin: 0 8px 0 0;
    outline: 0;
    padding: 0;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    appearance: none;
    opacity: 1;
  }

  input[type='checkbox']:hover {
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: 1;
  }

  input[type='checkbox']:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 9px;
    border: solid var(--secondary-color);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
`;

const SelectText = styled.div`
  ${flex};
  gap: 10px;
`;

const SelectedItems = styled.span`
  ${Smalltext};
  color: var(--grey-color);
`;

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredList: undefined,
      searchText: '',
      totalSelectedInvoice: 0
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      const result = await this.props.getInvoiceList(companyId);
      if (result) {
        this.setFilteredList();
      }
    }
  };

  setFilteredList = () => {
    const { paginatedList } = this.props.pagination;
    this.setState({ filteredList: paginatedList });
  };

  loadListWithPaggingIndex = async (index, isSetTotalRecords = false) => {
    const { filteredInvoiceList } = this.props.reports;
    await this.props.loadListWithPagging(filteredInvoiceList, index, isSetTotalRecords);
    this.setFilteredList();
  };

  clearSerachText = () => {
    const { isShowMobileSearch } = this.state;
    if (isShowMobileSearch) this.setState({ isShowMobileSearch: false });
    this.setState({ searchText: '' });
    this.filterInvoice('');
  };

  onSearchChange = (e) => {
    const searchText = e.target.value;
    this.setState({ searchText });
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      this.filterInvoice(searchText);
    }, 1000);
  };

  filterInvoice = async (searchText) => {
    const { invoiceList = [] } = this.props.reports;
    let newList = [];
    searchText = searchText.trim().toLowerCase();
    if (!isEmpty(searchText)) {
      newList = invoiceList?.filter(
        (x) =>
          x?.number?.trim().toLowerCase().includes(searchText) ||
          x?.order_number?.trim().toLowerCase().includes(searchText)
      );
    } else {
      newList = invoiceList;
    }
    await this.props.setFilteredInvoiceList(newList || []);
    this.loadListWithPaggingIndex(1, true);
  };

  toggleSideMenu = () => {
    if (this.props.toggleSideMenu) this.props.toggleSideMenu();
  };

  onSelectInvoice = (index, val) => {
    let { filteredList, totalSelectedInvoice } = this.state;
    if (totalSelectedInvoice === 5 && val) {
      filteredList[index].isSelected = false;
      this.props.setErrorMessage('You cannot select more than 5 Invoices');
      setTimeout(() => {
        this.props.setErrorMessage();
      }, 5000);
      return;
    }
    if (val) totalSelectedInvoice++;
    else totalSelectedInvoice--;
    filteredList[index].isSelected = !filteredList[index].isSelected;
    this.setState({ filteredList, totalSelectedInvoice });
  };

  onDownloadBulkInvoice = async () => {
    let { filteredList } = this.state;
    const payload = {};
    const invoiceIds = [];
    filteredList?.forEach((x) => (x?.isSelected ? invoiceIds.push(x?.id) : null));

    payload['invoiceIds'] = invoiceIds;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId && invoiceIds?.length > 0) {
      await this.props.printBulkShippingInvoice(companyId, payload);
    }
  };

  onClickView = (selectedInvoice) => {
    selectedInvoice && this.props.history.push(`/invoices/invoice-preview/${selectedInvoice?.id}`);
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Message text={resError} type={'error'} />;
    }
  }

  renderBulkAction = () => {
    let { totalSelectedInvoice } = this.state;
    if (totalSelectedInvoice > 0) {
      return (
        <SelectText>
          <SelectedItems>Selected Items: {totalSelectedInvoice}</SelectedItems>
          <Button
            small
            onClick={() => {
              this.onDownloadBulkInvoice();
            }}
            isPrimary={true}
            title='Download Invoice'
          />
        </SelectText>
      );
    } else return null;
  };
  onClickMobileSearch = () => {
    const { isShowMobileSearch } = this.state;
    this.setState({ isShowMobileSearch: !isShowMobileSearch });
  };

  render() {
    const { loading } = this.props.reports;
    const { currentPage, totalPage, totalRecords } = this.props.pagination;
    const { searchText, filteredList, isShowMobileSearch } = this.state;
    return (
      <Layout expanded={SIDEBAR_MENU_REPORTS}>
        <MainWrapper>
          <MainContent>
            <ProductNav>
              <LeftWrap>
                <MenuView onClick={this.toggleSideMenu}>
                  <MenuIcon />
                </MenuView>
                <CatName>Invoices</CatName>
              </LeftWrap>
              <RightWrap>
                <SearchView>
                  <SearchBox
                    value={searchText}
                    onSearchChange={this.onSearchChange}
                    clearSearch={this.clearSerachText}
                    placeholder={'Search Invoices'}
                  />
                </SearchView>
                <SearchIconView onClick={this.onClickMobileSearch}>
                  <SearchIcon />
                </SearchIconView>
              </RightWrap>
            </ProductNav>
            {isShowMobileSearch && (
              <SerchWrap>
                <SearchViewMobile>
                  <SearchInput
                    type='text'
                    name='search'
                    value={searchText}
                    onChange={this.onSearchChange}
                    autoComplete='off'
                    placeholder={'Search Product'}></SearchInput>
                  <CloseIcon className='close' onClick={this.clearSerachText} />
                </SearchViewMobile>
              </SerchWrap>
            )}
            {!loading && (!filteredList || filteredList?.length === 0) && (
              <EmptyContain>
                <EmptyDetail>
                  <EmptyImage src={EmptyLogo} />
                  <TextEmpty>You don't have any Invoice!</TextEmpty>
                </EmptyDetail>
              </EmptyContain>
            )}
            {loading && !filteredList ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <ContentBlock isPagination={totalRecords > 10}>
                {filteredList?.length > 0 && (
                  <MainTab>
                    {this.renderErrors()}
                    <MainTable>
                      <TableIt>
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Invoice Date</Th>
                            <Th>Invoice No</Th>
                            <Th>Order No</Th>
                            <Th>Cost</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {filteredList?.map((item, i) => {
                            return (
                              <Tr key={item?.id + i}>
                                <Td>
                                  <CheckBox>
                                    <InputCheck
                                      type='checkbox'
                                      id='check'
                                      name='check'
                                      checked={item.isSelected}
                                      value={item.isSelected}
                                      onChange={() => {
                                        this.onSelectInvoice(i, !item.isSelected);
                                      }}
                                    />
                                  </CheckBox>
                                </Td>
                                <Td>
                                  <HeadTitle>{moment(item?.date).format('DD MMM YYYY')}</HeadTitle>
                                </Td>
                                <Td>
                                  <WrapperPlace
                                    onClick={() => {
                                      this.onClickView(item);
                                    }}>
                                    <HeadTitle>{item?.number}</HeadTitle>
                                  </WrapperPlace>
                                </Td>
                                <Td>
                                  <HeadTitle>{item?.order_number}</HeadTitle>
                                </Td>
                                <Td>
                                  <HeadTitle>₹ {currencyWithDecimal(item?.total_amount)}</HeadTitle>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </TableIt>
                    </MainTable>
                  </MainTab>
                )}
                <Pagination
                  textPerPage={'Invoices'}
                  current={currentPage}
                  total={totalPage}
                  totalRecords={totalRecords}
                  onChange={this.loadListWithPaggingIndex}
                  bulkAction={this.renderBulkAction()}
                />
              </ContentBlock>
            )}
          </MainContent>
        </MainWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  pagination: state.pagination,
  reports: state.reports
});

export default connect(mapStateToProps, {
  getOrderList,
  loadListWithPagging,
  cancelOrder,
  toggleSideMenu,
  getInvoiceList,
  setFilteredInvoiceList,
  printBulkShippingInvoice,
  setErrorMessage
})(Invoices);
