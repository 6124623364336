import axios from 'axios';
import { clearResponseMessage, setErrorMessage, setItemErrorMessage } from '../actions/messageActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../helper/common';
import {
  setAnnouncementList,
  setDashboardLoader,
  setNewCatalogs,
  setOrderOverview,
  setTodaysActivity,
  setTopSoldProducts,
  setTotalOrderChartData,
  setTotalSalesAndBill
} from '../actions/dashboardActions';
import store from '../store/store';
import { loadListWithPagging } from './paginationService';

/**
 * @desc Dashboard - Get Order Overview
 * @param {*} obj Data Obj
 */
export const getOrderOverview = (company_id, fromdate, todate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setDashboardLoader(true));

    const params = {
      fromdate,
      todate
    };
    const response = await axios.get(`${REACT_APP_APIURL}/customer/dashboard/${company_id}/overview`, { params });
    const { data } = response;
    if (data) {
      dispatch(setOrderOverview(data?.data || {}));
    }
    return true;
  } catch (e) {
    dispatchDashboardError(getAPIResponseError(e) || 'Unable to get order overview, please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Get Top Sold Prodcuts
 * @param {*} obj Data Obj
 */
export const getTopSoldProducts = (company_id, fromdate, todate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setDashboardLoader(true));

    const params = {
      fromdate,
      todate
    };
    const response = await axios.get(`${REACT_APP_APIURL}/customer/dashboard/${company_id}/top-sold-products`, {
      params
    });
    const { data } = response;
    if (data) {
      dispatch(setTopSoldProducts(data?.data || []));
    }
    return true;
  } catch (e) {
    dispatchDashboardError(getAPIResponseError(e) || 'Unable to get top sold products, please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Get New Catalogs
 * @param {*} obj Data Obj
 */
export const getNewCatalogs = (company_id, fromdate, todate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setDashboardLoader(true));

    const params = {
      fromdate,
      todate
    };
    const response = await axios.get(`${REACT_APP_APIURL}/customer/dashboard/${company_id}/customer-latest-catalogue`, {
      params
    });
    const { data } = response;
    if (data) {
      dispatch(setNewCatalogs(data?.data || []));
    }
    return true;
  } catch (e) {
    dispatchDashboardError(getAPIResponseError(e) || 'Unable to get new catalogs, please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Get Total Sales And Bills
 * @param {*} obj Data Obj
 */
export const getTotalSalesAndBill = (company_id, fromdate, todate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setDashboardLoader(true));

    const params = {
      fromdate,
      todate
    };
    const response = await axios.get(`${REACT_APP_APIURL}/customer/dashboard/${company_id}/customer-total-sales`, {
      params
    });
    const { data } = response;
    if (data) {
      dispatch(setTotalSalesAndBill(data?.data || {}));
    }
    return true;
  } catch (e) {
    dispatchDashboardError(getAPIResponseError(e) || 'Unable to get total sales and bills, please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Dashboard - Get Customer's Today Activity
 * @param {*} obj Data Obj
 */
export const getCustomerTodayActivity = (company_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return false;
    dispatch(setDashboardLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/customer/dashboard/${company_id}/customer-todays-activities`);
    const { data } = response;
    if (data) {
      dispatch(setTodaysActivity(data?.data || {}));
    }
    return true;
  } catch (e) {
    dispatchDashboardError(getAPIResponseError(e) || 'Unable to get your today"s activity, please try again', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Get Total Order Chart Data
 * @param {*} company_id company id
 */
export const getTotalOrderChartData = (company_id, fromdate, todate) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setDashboardLoader(true));

    let params = { fromdate, todate };
    const response = await axios.get(`${REACT_APP_APIURL}/customer/dashboard/${company_id}/total-order-count-chart`, {
      params
    });
    const { data } = response.data || {};
    if (data) {
      dispatch(setTotalOrderChartData(data));
      return true;
    }
  } catch (e) {
    dispatchDashboardError(
      getAPIResponseError(e) || 'Unable to get total order chart data, please try again later',
      dispatch
    );
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

/**
 * @desc Get Customer's Active Announcement List
 * @param {*} company_id company id
 */
export const getAnnouncementList = (company_id, isRefresh) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    if (!company_id) return;
    dispatch(setDashboardLoader(true));

    const state = store.getState();
    const { announcementList } = state.dashboard;
    if (announcementList?.length > 0 && !isRefresh) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/customer/announcements/${company_id}`);
    const { data } = response.data || {};
    if (data) {
      dispatch(setAnnouncementList(data || []));
      dispatch(loadListWithPagging(data || [], 1, true));
      return true;
    }
  } catch (e) {
    dispatchDashboardError(
      getAPIResponseError(e) || 'Unable to get announcement list, please try again later',
      dispatch
    );
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

function dispatchDashboardError(msg, dispatch, isModalError = false) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}
