import * as Actions from './types';

/**
 * @desc Set Cart Items
 */
export const addToCart = (payload) => {
  return {
    type: Actions.ADD_TO_CART,
    payload
  };
};

/**
 * @desc update Cart Items
 */
export const updateCartItems = (payload) => {
  return {
    type: Actions.UPDATE_CART_ITEMS,
    payload
  };
};

/**
 * @desc Remove Cart Items
 */
export const removeCartItems = (payload) => {
  return {
    type: Actions.REMOVE_CART_ITEMS,
    payload
  };
};

/**
 * @desc Clear Cart Data
 */
export const clearCartData = (payload) => {
  return {
    type: Actions.CLEAR_CART_DATA,
    payload
  };
};
