import * as Actions from './types';

// Set logged in user
export const setCurrentUser = (payload) => {
  return {
    type: Actions.SET_CURRENT_USER,
    payload: payload
  };
};

// Set logged in user's profile
export const setCurrentUserProfile = (payload) => {
  return {
    type: Actions.SET_CURRENT_USER_PROFILE,
    payload: payload
  };
};

/**
 * @desc Set Auth Validation Errors
 */
export const setAuthValidationErrors = (payload) => {
  return {
    type: Actions.GET_AUTH_VALIDATION_ERRORS,
    payload: payload
  };
};

/**
 * @desc Set Auth Loader
 */
export const setAuthLoader = (payload) => {
  return {
    type: Actions.SET_AUTH_LOADER,
    payload: payload
  };
};

/**
 * @desc Set Company List
 */
export const setCompanyList = (payload) => ({
  type: Actions.SET_COMPANY_LIST,
  payload
});

/**
 * @desc Set Selected Company
 */
export const setSelectedCompany = (payload) => ({
  type: Actions.SET_SELECTED_COMPANY,
  payload
});

/**
 * @desc Clear Auth Data
 */
export const clearAuthData = () => {
  return {
    type: Actions.CLEAR_AUTH_DATA
  };
};
