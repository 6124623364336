import axios from 'axios';
import { REACT_APP_APIURL } from '../global/Environment';
import { clearResponseMessage, setErrorMessage, setItemErrorMessage } from '../actions/messageActions';
import {
  setCustomerLoader,
  setCustomerItem,
  setCustomerAccountManager,
  setCustomerSettings
} from '../actions/customerActions';
import { getAPIResponseError } from '../helper/common';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
/**
 * @desc  Get Customer Item
 * @param {*} company_id company id
 */
export const getCustomerItem = (company_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setCustomerLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/customer/contacts/${company_id}/item`);
    const { data } = response.data;
    dispatch(setCustomerItem(data || {}));
    UserPreferenceSingleton.getInstance().setCustomerId(data.id);
    return data;
  } catch (e) {
    dispatchCustomerError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc  Get Customer Account Manager
 * @param {*} company_id company id
 */
export const getCustomerAccountManager = (company_id) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setCustomerLoader(true));
    const response = await axios.get(`${REACT_APP_APIURL}/customer/contacts/${company_id}/account-manager`);
    const { data } = response.data;
    if (data) {
      dispatch(setCustomerAccountManager(data || {}));
    }
    return true;
  } catch (e) {
    dispatchCustomerError(getAPIResponseError(e) || 'Something goes wrong, please try again later', dispatch);
    return false;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc  Get Customer Settings
 * @param {*} company_id company id
 */
export const getCustomerSettings = (company_id, isRefresh, isModalError) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setCustomerLoader(true));

    const state = getState();
    const { customerSettings } = state.customer;
    if (customerSettings && !isRefresh) return true;

    const response = await axios.get(`${REACT_APP_APIURL}/customer/settings`);
    const { data } = response.data;
    if (data?.length > 0) {
      dispatch(setCustomerSettings(data[0] || {}));
    }
    return true;
  } catch (e) {
    dispatchCustomerError(
      getAPIResponseError(e) || 'Unable to get customer settings, please try again later',
      dispatch,
      isModalError
    );
    return false;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

/**
 * @desc  Update Customer Settings
 * @param {*} company_id company id
 */
export const updateCustomerSettings = (company_id, payload, isModalError) => async (dispatch) => {
  try {
    dispatch(clearResponseMessage(''));
    if (!company_id) return false;
    dispatch(setCustomerLoader(true));

    const response = await axios.put(`${REACT_APP_APIURL}/customer/settings`, payload);
    const { data } = response.data;
    if (data?.length > 0) {
      dispatch(setCustomerSettings(data[0] || {}));
      return true;
    }
  } catch (e) {
    dispatchCustomerError(
      getAPIResponseError(e) || 'Unable to get customer settings, please try again later',
      dispatch,
      isModalError
    );
    return false;
  } finally {
    dispatch(setCustomerLoader(false));
  }
};

function dispatchCustomerError(msg, dispatch, isModalError = false) {
  isModalError ? dispatch(setItemErrorMessage(msg)) : dispatch(setErrorMessage(msg));
}
