import Dashboard from './Pages/Dashboard/Dashboard';
import Login from './Pages/Membership/Login/Login';
import ForgotPassWord from './Pages/Membership/Forgotpassword/Forgotpassword';
import ResetPassword from './Pages/Membership/ResetPassword/ResetPassword';
import Signup from './Pages/Membership/Signup/Signup';
import LiveCatalogs from './Pages/LiveCatalogs/LiveCatalogs/LiveCatalogs';
import DigitOtp from './Pages/Membership/DigitOtp/DigitOtp';
import RecoverPassword from './Pages/Membership/RecoverPassword/RecoverPassword';
import OrderList from './Pages/OrderList/OrderList';
import LedgerReport from './Pages/Reports/LedgerReport';
import Invoices from './Pages/Reports/Invoices';
import ViewInvoicePreview from './Pages/Reports/ViewInvoicePreview';
import SwitchOrganisation from './Pages/Membership/SwitchOrganization/SwitchOrganization';
import { ALL_CATALOGS, OUT_OF_STOCK_PRODUCTS } from './global/constants';
import AnnouncementList from './Pages/Announcement/AnnouncementList';
import Cart from './Pages/LiveCatalogs/Cart/Cart';
import CatalogProducts from './Pages/LiveCatalogs/CatalogProducts/CatalogProducts';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/',
    exact: true,
    component: Dashboard,
    private: true
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    private: true
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    private: false
  },
  {
    path: '/signup',
    exact: true,
    component: Signup,
    private: false
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassWord,
    private: false
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    private: false
  },
  {
    path: '/recoverpassword',
    exact: true,
    component: RecoverPassword,
    private: false
  },
  {
    path: '/digitotp',
    exact: true,
    component: DigitOtp,
    private: false
  },
  {
    path: '/catalogue',
    exact: true,
    component: LiveCatalogs,
    private: true
  },
  {
    path: '/catalogue/details/:product',
    exact: true,
    component: CatalogProducts,
    private: true
  },
  {
    path: '/zero-qty-catalogue',
    exact: true,
    component: LiveCatalogs,
    private: true
  },
  {
    path: '/pending-orders',
    exact: true,
    component: OrderList,
    private: true
  },
  {
    path: '/cancel-orders',
    exact: true,
    component: OrderList,
    private: true
  },
  {
    path: '/invoiced-orders',
    exact: true,
    component: OrderList,
    private: true
  },
  {
    path: '/ledger-report',
    exact: true,
    component: LedgerReport,
    private: true
  },
  {
    path: '/invoices/invoice-preview/:id',
    exact: true,
    component: ViewInvoicePreview,
    private: true
  },
  {
    path: '/cart',
    exact: true,
    component: Cart,
    private: true
  },
  {
    path: '/invoices',
    exact: true,
    component: Invoices,
    private: true
  },
  {
    path: ALL_CATALOGS,
    exact: true,
    component: LiveCatalogs,
    private: true
  },
  {
    path: OUT_OF_STOCK_PRODUCTS,
    exact: true,
    component: CatalogProducts,
    private: true
  },
  {
    path: '/select-organisation',
    exact: true,
    component: SwitchOrganisation,
    private: true
  },
  {
    path: '/view/announcement',
    exact: true,
    component: AnnouncementList,
    private: true
  }
];

export default routes;
