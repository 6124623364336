import * as Actions from './../actions/types';

const initialState = {
  cart: undefined
};

export const cartReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.ADD_TO_CART:
      return {
        ...state,
        cart: action.payload
      };
    case Actions.UPDATE_CART_ITEMS:
      return {
        ...state,
        cart: action.payload
      };
    case Actions.REMOVE_CART_ITEMS:
      return {
        ...state,
        cart: action.payload
      };
    case Actions.CLEAR_CART_DATA:
      return initialState;
    default:
      return state;
  }
};
