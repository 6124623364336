import * as Actions from './types';

/**
 * @desc Set Catalog Loader
 */
export const setCatalogLoader = (payload) => {
  return {
    type: Actions.SET_CATALOG_LOADER,
    payload
  };
};

/**
 * @desc Set Catalogue List
 */
export const setCatalogList = (payload) => {
  return {
    type: Actions.SET_CATALOG_LIST,
    payload
  };
};

/**
 * @desc Clear Catalogue Data
 */
export const clearCatalogueData = () => {
  return {
    type: Actions.CLEAR_CATALOG_DATA
  };
};
