import styled, { css } from 'styled-components';
import {
  Headertitle,
  Mediumtext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MainWrapper = styled.div`
  height: 100%;
  background: var(--secondary-color);
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  padding: 24px 24px;
  z-index: 2;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const CatName = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
`;

const RightWrap = styled.div`
  ${flex};
  gap: 15px;
  position: relative;
  .pdf {
    margin: 0 5px;
  }
  a {
    margin-left: 0px;
  }
`;

const SerchWrap = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    .close {
      position: absolute;
      right: 26px;
      z-index: 11;
      top: 8px;
      vertical-align: middle;
    }
  }
`;

const SearchDataMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    background: var(--secondary-color);
    text-align: left;
    padding: 0px 5px 8px 5px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    top: 34px;
    right: 16px;
    left: 16px;
    z-index: 999;
    max-height: 520px;
    overflow: auto;
  }
`;

const SearchData = styled.div`
  background: var(--secondary-color);
  width: 230px;
  text-align: left;
  padding: 0px 5px 8px 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  top: 36px;
  right: 0;
  z-index: 999;
  max-height: 600px;
  overflow: auto;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const ContentBlockLive = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 0;
  overflow: auto;
  position: relative;
`;

const LButton = styled.div`
  ${flex};
  border: 0;
  padding: 0;
  cursor: pointer;
  color: var(--grey-color);
  svg {
    vertical-align: middle;
  }
  :hover {
    border: none !important;
    svg path {
      stroke: var(--black-light-color);
    }
  }
`;

const ContentLive = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  padding: 0 24px;
  .empty-center {
    display: block;
  }
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const ContentWrapperLive = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(354px, 1fr));
  gap: 24px;
  padding-top: 4px;
  padding-bottom: 24px;
  @media (max-width: 767px) {
    grid-template-columns: auto;
    gap: 16px;
    padding-bottom: 16px;
  }
`;

const ContentBox = styled.div`
  width: 100%;
`;

const Content = styled.div`
  ${flex};
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  :hover {
    .hoverIcon {
      padding: 4px;
      background: #ffffff;
      box-shadow: var(--box-shadow);
      border-radius: 50%;
      @media (max-width: 767px) {
        display: none;
        position: unset;
      }
    }
    svg {
      display: block;
    }
    border: 1px solid var(--input-border-hover-color);
  }
`;

const IconRight = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  svg {
    display: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ProductInfo = styled.div`
  padding: 5px 0;
  ${flexspacebetween};
  ${(props) =>
    props.isHidePrice &&
    css`
      padding: 12px 0;
    `}
`;

const ContentRight = styled.div`
  width: 100%;
  padding: 5px 10px 5px 15px;
`;

const CatalougeName = styled.h4`
  ${Mediumtext};
  color: var(--header-title-color);
  margin: 0 0 4px 0;
  text-transform: capitalize;
  ${Textdotdot};
`;

const ProductImg = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 767px) {
    object-fit: contain;
  }
`;

const ProductDetail = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  text-transform: uppercase;
`;

const ProductDetailRight = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  text-align: right;
  white-space: nowrap;
`;

const CatalogueButtonWrapper = styled.div`
  margin-top: 10px;
  button {
    width: 100%;
  }
  @media (max-width: 767px) {
    display: flex !important;
    justify-content: flex-end;
    padding: 5px;
    z-index: 0;
    background: transparent;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
  margin-top: 10px;
  button {
    width: 100%;
    :disabled {
      opacity: 0.8;
    }
  }
`;

const LeftWrapper = styled.div`
  ${flex};
  justify-content: space-evenly;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  /* width: 100%; */
  height: 32px;
  padding: 8px;
  border-radius: 6px;
  button {
    ${Smalltext};
    ${flexcenter};
    box-shadow: none;
    border: 0;
    width: 100%;
    max-width: 33.33%;
    height: 100%;
  }
  button:hover {
    border: 0 !important;
    box-shadow: none !important;
  }
  input {
    ${Smalltext};
    color: var(--black-light-color);
    border: 0;
    padding: 0 5px;
    margin: 0;
    width: 100%;
    max-width: 33.33%;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    :hover {
      border: 0px;
    }
    :focus {
      border: 0px;
    }
    :active {
      border: 0px;
    }
  }
`;

const EmptyContain = styled.div`
  ${flexcenter};
  height: 100%;
`;

const EmptyDetail = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
`;

const EmptyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextEmpty = styled.h3`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 10px 0 0;
  text-transform: capitalize;
`;

const AddCartView = styled.div`
  display: none;
  @media (max-width: 767px) {
    background: var(--primary-color);
    border-radius: 4px;
    padding: 6px;
    display: inline-flex;
    &.prebook {
      background: var(--red-color);
    }
  }
`;

const AddView = styled.div`
  width: 100%;
  button {
    width: 100%;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const SearchList = styled.a`
  margin: 8px 0px 0px;
  border-radius: 6px;
  text-decoration: none;
  padding: 7px 10px;
  display: block;
  :hover {
    background-color: #f4f6f8;
    cursor: pointer;
  }
`;

const TextSearch = styled.span`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0 0 10px 0;
`;

const SearchView = styled.div`
  display: block;
  input {
    ${Smalltext};
    background: var(--secondary-color);
    border: 1px solid var(--secondary-border-color);
    margin-right: 0px;
    padding: 7px 10px;
    border-radius: 4px;
    outline: 0;
    color: var(--black-light-color);
    ::placeholder {
      color: var(--placeholder-color);
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const SearchInput = styled.input``;

const SearchViewMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    input {
      ${Smalltext};
      margin-right: 0px;
      color: var(--black-light-color);
      background: var(--secondary-color);
      border: 1px solid var(--secondary-border-color);
      border-radius: 4px;
      outline: 0;
      padding: 8px 10px;
      margin: 4px 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 11;
      ::placeholder {
        color: var(--placeholder-color);
      }
    }
  }
  @media (max-width: 767px) {
    input {
      margin: 0 16px;
    }
  }
`;

const SearchIconView = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: inline-flex;
    margin-right: 5px;
  }
`;

const ContentLeftImg = styled.div`
  height: 165px;
  min-height: 165px;
  width: 100%;
  max-width: 120px;
`;

const ErrorWrap = styled.div``;

export {
  MainWrapper,
  Content,
  MainContent,
  ContentWrapperLive,
  ProductNav,
  CatName,
  SearchInput,
  RightWrap,
  LeftWrap,
  ProductInfo,
  ProductImg,
  CatalougeName,
  ContentRight,
  ContentLeftImg,
  ProductDetail,
  ProductDetailRight,
  ContentBlockLive,
  ContentLive,
  ContentBox,
  EmptyContain,
  EmptyDetail,
  TextEmpty,
  EmptyImage,
  MenuView,
  SearchData,
  SearchList,
  TextSearch,
  IconRight,
  SearchView,
  SearchIconView,
  SearchViewMobile,
  SearchDataMobile,
  SerchWrap,
  LeftWrapper,
  ButtonWrap,
  AddView,
  CatalogueButtonWrapper,
  AddCartView,
  LButton,
  ErrorWrap,
  LoadView
};
