import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as SearchBarIcon } from '../../assets/images/svg/SearchIcon2.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon-small.svg';
import { isEmpty } from '../../helper/common';

const Searchbox = styled.div`
  position: relative;
  cursor: pointer;
  height: 32px;
  margin-left: -13px;
  margin-right: 0px;
  padding: 0 0 0 34px;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition: width 400ms ease, background 400ms ease;

  ${(props) =>
    props.active &&
    css`
      padding: 0;
      margin-left: 0px;
      .SearchIcon {
        z-index: 3;
        left: auto;
        right: 10px;
        max-width: 200px;
      }
    `};
`;
const SearchInput = styled.input`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #393945;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 9px 10px;
  max-width: 32px;
  height: 32px;
  width: 100%;
  outline: 0;
  cursor: pointer;
  position: relative;
  z-index: 3;
  transition: 0.3s all ease;
  &::-webkit-input-placeholder {
    color: #b5b7be;
  }
  ${(props) =>
    props.active &&
    css`
      max-width: 200px;
      padding: 9px 32px 9px 10px;
      :hover {
        border: 1px solid #c5c8cd;
      }
    `};

  &:focus,
  &:active {
    max-width: 200px;
    padding: 9px 32px 9px 10px;
    :hover {
      border: 1px solid #c5c8cd;
    }
  }
  &:focus ~ .SearchIcon,
  &:active ~ .SearchIcon {
    z-index: 3;
    left: auto;
    right: 10px;
  }
`;

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false
    };
  }

  onClickSearchIcon = () => {
    this.setState({ isSearch: true });
  };

  onClickClose = () => {
    this.setState({ isSearch: false });
    this.props.clearSearch();
  };

  onBlurInput = (value) => {
    if (!isEmpty(value)) {
      return;
    } else {
      this.setState({ isSearch: false });
    }
  };

  render() {
    const { isSearch } = this.state;
    const { value, onSearchChange, placeholder = 'Search', autoComplete = 'on', name = 'search' } = this.props;
    return (
      <>
        <Searchbox active={isSearch}>
          {isSearch && (
            <SearchInput
              type='text'
              placeholder={placeholder}
              name={name}
              value={value}
              active={!!value}
              autoFocus={true}
              onChange={onSearchChange}
              autoComplete={autoComplete}
              onBlur={() => this.onBlurInput(value)}
            />
          )}
          {!isSearch ? (
            <SearchBarIcon className='SearchIcon' onClick={this.onClickSearchIcon} />
          ) : (
            <CloseIcon className='SearchIcon close' onClick={this.onClickClose} />
          )}
        </Searchbox>
      </>
    );
  }
}

export default SearchBox;
