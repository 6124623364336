import React, { Component } from 'react';
import styled from 'styled-components';
import { isEmpty } from '../../helper/common';
import { Mediumtext, Normaltext, Smalltext, flex, flexcenter } from '../../font-variable';

const CatTimer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  z-index: 0;
  text-align: center;
  padding: 12px 8px 0;
  border-radius: 4px;
  transition: 0.3s all;
  background: linear-gradient(180deg, rgba(7, 7, 7, 0) 0%, rgba(7, 7, 7, 0.3) 59.81%);
  @media (max-width: 767px) {
    padding: 12px 5px 0;
  }
`;

const DateView = styled.div`
  ${flexcenter};
  flex-wrap: wrap;
`;

const Time = styled.span`
  ${Mediumtext};
  color: var(--text-color);
  text-align: center;
  @media (max-width: 767px) {
    ${Normaltext};
  }
`;

const DateTextView = styled.div`
  ${Smalltext};
  ${flexcenter};
  gap: 4px;
  flex-wrap: wrap;
  color: var(--black-light-color);
  background-color: var(--secondary-color);
  padding: 7px 8px;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  a {
    color: var(--primary-color);
  }
  @media (max-width: 767px) {
    ${Normaltext};
  }
`;

const DayText = styled.span`
  ${Normaltext};
  color: var(--text-color);
  text-align: center;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileDay = styled.span`
  display: none;
  @media (max-width: 767px) {
    ${Normaltext};
    color: var(--text-color);
    text-align: center;
    display: block;
  }
`;

const ManufactWarapper = styled.div`
  ${Mediumtext};
  color: var(--text-color);
  text-align: center;
  padding-bottom: 8px;
  @media (max-width: 767px) {
    ${Normaltext};
    padding-bottom: 6px;
  }
`;

const ContactUsText = styled.a`
  color: var(--text-color);
  margin-left: 6px;
  :hover {
    text-decoration: underline;
  }
`;

const CartT = styled.div`
  ${flex};
  flex-direction: column;
  padding: 0 6px 8px;
  gap: 2px;
`;

const CartDtails = styled.div`
  margin-bottom: 44px;
  &.delay {
    margin-bottom: 8px;
    @media (max-width: 767px) {
      margin-bottom: 42px;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
`;

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: { days: 0, hours: 0, minutes: 0, seconds: 0 }
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setTimer();
  };

  setTimer = () => {
    const date = this.props.expirationDate;
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = date - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const time = { days, hours, minutes, seconds };
      this.setState({ duration: time });
      if (distance < 0) {
        clearInterval(timer);
        this.setState({ duration: undefined });
      }
    }, 1000);
  };

  render() {
    const { duration } = this.state;
    const { product: item } = this.props;

    return (
      <>
        <CatTimer className={!item?.isShowPlaceOrderButton ? 'change' : 'timer-text'}>
          <CartDtails className={!isEmpty(duration) ? 'timer' : 'delay'}>
            <ManufactWarapper>In Manufacturing...</ManufactWarapper>
            <DateView>
              {!isEmpty(duration) ? (
                <>
                  <CartT>
                    <Time>{duration?.days}</Time>
                    <DayText>Days</DayText>
                    <MobileDay>D</MobileDay>
                  </CartT>
                  <CartT>
                    <Time>{duration?.hours}</Time>
                    <DayText>Hours</DayText>
                    <MobileDay>H</MobileDay>
                  </CartT>
                  <CartT>
                    <Time>{duration?.minutes}</Time>
                    <DayText>Minutes</DayText>
                    <MobileDay>M</MobileDay>
                  </CartT>
                  <CartT>
                    <Time>{duration?.seconds}</Time>
                    <DayText>Seconds</DayText>
                    <MobileDay>S</MobileDay>
                  </CartT>
                </>
              ) : (
                <>
                  <DateTextView>
                    Delayed
                    <ContactUsText
                      href={`mailto:owner@aryadesigns.co.in?subject= sku: ${item?.sku} productName: ${item?.name}`}>
                      {'Contact Us'}
                    </ContactUsText>
                  </DateTextView>
                </>
              )}
            </DateView>
          </CartDtails>
        </CatTimer>
      </>
    );
  }
}

export default Timer;
