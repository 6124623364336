import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import { ALL_CATALOGS, PRODUCT_STATUS, SIDEBAR_MENU_ALL_CATALOGS, ZERO_QTY_CATALOG } from '../../../global/constants';
// import { ReactComponent as Excel } from '../../../assets/images/svg/exel.svg';
// import { ReactComponent as Pdf } from '../../../assets/images/svg/pdf.svg';
import { ReactComponent as MenuIcon } from '../../../assets/images/svg/menuicon.svg';
import { ReactComponent as ChervonRight } from '../../../assets/images/svg/chevron-right.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/svg/searchmobile.svg';
import { ReactComponent as AddCartIcon } from '../../../assets/images/svg/addcarticon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/svg/plusicon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/svg/minusicon.svg';
// import { ReactComponent as VerticalMoreIcon } from '../../../assets/images/svg/Verticalmore.svg';
import Button from '../../../components/Button/Button';
import { getCatalogList } from '../../../services/catalogService';
import UserPreferenceSingleton from '../../../helper/UserPreferenceSingleton';
import Message from '../../../components/Message';
import EmptyLogo from '../../../assets/images/empty-catalog.svg';
import _ from 'lodash';
import { process } from '@progress/kendo-data-query';
import {
  MainWrapper,
  Content,
  MainContent,
  ContentWrapperLive,
  ProductNav,
  CatName,
  SearchInput,
  RightWrap,
  LeftWrap,
  ProductInfo,
  ProductImg,
  CatalougeName,
  ContentRight,
  ContentLeftImg,
  ProductDetail,
  ProductDetailRight,
  ContentBlockLive,
  ContentLive,
  ContentBox,
  EmptyContain,
  EmptyDetail,
  TextEmpty,
  EmptyImage,
  MenuView,
  SearchData,
  SearchList,
  TextSearch,
  IconRight,
  SearchView,
  SearchIconView,
  SearchViewMobile,
  SearchDataMobile,
  SerchWrap,
  LeftWrapper,
  ButtonWrap,
  AddView,
  CatalogueButtonWrapper,
  AddCartView,
  LButton,
  ErrorWrap,
  LoadView
} from './style';
import Loading from '../../../components/Loading/Loading';
import { getUniqueId, currencyWithDecimal, isEmpty, groupBy } from '../../../helper/common';
import { toggleSideMenu } from '../../../actions/SideMenuAction';
import { addToCart } from '../../../actions/CartAction';
import { setSuccessMessage } from '../../../actions/messageActions';
import Input from '../../../components/Input/Input';
import SearchBox from '../../../components/SearchBox/SearchBox';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/closeicon-small.svg';

class LiveCatalogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenOrderDetailsModal: false,
      catalog: undefined,
      allCatalog: undefined,
      searchText: '',
      searchedList: [],
      isShowMobileSearch: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      try {
        this.setState({ isLoading: true });
        await this.props.getCatalogList(companyId);

        const { catalogList } = this.props.catalog;
        const url = this.props.match.url;

        const productsByCatalogue = groupBy(catalogList, 'catalogue');
        const uniqueCatalog = [];
        productsByCatalogue?.forEach((catalogue) => {
          const productsWithImage = catalogue?.products?.filter((x) => x?.gallery?.length > 0);
          let catalog = {};
          if (productsWithImage?.length > 0) catalog = productsWithImage?.[0];
          else catalog = catalogue?.products?.[0];
          uniqueCatalog?.push(catalog);
        });
        let catalog = _.filter(uniqueCatalog, 'catalogue');
        let ZeroQtyCatalogs = catalog?.map((item) => {
          let catalogs = _.filter(catalogList, { catalogue: item?.catalogue });
          const totalCatalogQty = _.sumBy(catalogs, (o) => {
            return o.qty;
          });
          return totalCatalogQty === 0 && item;
        });
        ZeroQtyCatalogs = _.filter(ZeroQtyCatalogs, 'catalogue');

        if (url === ZERO_QTY_CATALOG) {
          catalog = ZeroQtyCatalogs;
        } else if (url === ALL_CATALOGS) {
          this.setState({ catalog, allCatalog: catalog });
        } else {
          catalog = _.differenceWith(catalog, ZeroQtyCatalogs, _.isEqual);
        }
        const sortedData = process(catalog, {
          sort: [{ field: 'catalogue', dir: 'asc' }]
        });
        catalog = sortedData?.data || [];

        catalog = catalog?.map((x) => {
          return {
            ...x,
            isShowOrderButton: true,
            isShowPlaceOrderButton: false,
            orderQty: 1
          };
        });
        this.setState({ catalog, allCatalog: catalog });
      } catch (error) {
        console.log('Error', error);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  onClickCatalog = (catalogName) => {
    const url = `/catalogue/details/${window.btoa(catalogName)}`;
    this.props.history.push(url);
  };

  onClickOrderButton = (e, index) => {
    e.stopPropagation();
    const { catalog } = this.state;
    catalog[index].isShowOrderButton = !catalog[index]?.isShowOrderButton;
    catalog[index].isShowPlaceOrderButton = !catalog[index]?.isShowPlaceOrderButton;
    this.setState({ catalog });
  };

  onClickPlusQty = (e, index) => {
    e.stopPropagation();
    const { catalog } = this.state;
    catalog[index].orderQty += 1;
    this.setState({ catalog });
  };

  onClickMinusQty = (e, index) => {
    e.stopPropagation();
    const { catalog } = this.state;
    if (catalog[index].orderQty > 1) {
      catalog[index].orderQty -= 1;
      this.setState({ catalog });
    }
  };

  onChangeQty = (e, index) => {
    const { catalog } = this.state;
    catalog[index].orderQty = Number(e.target.value);
    this.setState({ catalog });
  };

  isPreBookCatalog = (selectedCatalog) => {
    const preBookItem = selectedCatalog?.filter((x) => x?.qty === 0 && x?.status === PRODUCT_STATUS.ACTIVE);
    if (preBookItem.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  onClickAddToCart = (e, selectedCatalog, index) => {
    e.stopPropagation();
    const { cart = [] } = this.props.cart;
    const { catalog } = this.state;
    let newCart = cart;
    const totalPrice = selectedCatalog?.reduce((sum, val) => sum + Number(val?.price_sales || 0), 0);

    let existedCatalogue = newCart?.find((x) => selectedCatalog?.[0]?.catalogue === x?.name);

    if (existedCatalogue) {
      existedCatalogue.orderedQty += existedCatalogue?.products?.length * Number(catalog[index].orderQty || 1);
      existedCatalogue.itemOrderedQty += Number(catalog[index].orderQty || 1);
      existedCatalogue.totalAmt += Number(totalPrice) * Number(catalog[index].orderQty || 1);
    } else {
      const activeProducts = selectedCatalog?.filter((x) => x?.status === PRODUCT_STATUS.ACTIVE) || [];
      let catalogue = {
        id: getUniqueId(),
        catalogId: '',
        name: selectedCatalog?.[0]?.catalogue,
        img: selectedCatalog?.[0]?.gallery[0]?.url,
        products: activeProducts,
        isFromCatalog: true,
        totalPrice: totalPrice
      };
      catalogue.isFromCatalog = true;
      catalogue.orderedQty = activeProducts?.length * Number(catalog[index].orderQty || 1);
      catalogue.itemOrderedQty = Number(catalog[index].orderQty || 1);
      catalogue.totalAmt = totalPrice * Number(catalog[index].orderQty || 1);
      catalogue.isPreBook = this.isPreBookCatalog(selectedCatalog);
      newCart = [...newCart, catalogue];
    }
    this.props.addToCart(newCart);
    UserPreferenceSingleton.getInstance().setCartItems(newCart);
    this.props.setSuccessMessage('Added to Cart');
    setTimeout(() => {
      this.props.setSuccessMessage('');
    }, 5000);
    catalog[index].orderQty = 1;
    catalog[index].isShowOrderButton = !catalog[index]?.isShowOrderButton;
    catalog[index].isShowPlaceOrderButton = !catalog[index].isShowPlaceOrderButton;
    this.setState({ catalog });
  };

  onClickSearchedItem = (e, catalogName) => {
    e.stopPropagation();
    if (catalogName) {
      const url = `/catalogue/details/${window.btoa(catalogName)}`;
      this.props.history.push(url);
    }
  };

  clearSerachText = () => {
    const { isShowMobileSearch } = this.state;
    if (isShowMobileSearch) this.setState({ isShowMobileSearch: false });
    this.setState({ searchText: '', searchedList: [] });
    this.searchCatalog('');
  };

  onSearchChange = (e) => {
    const searchText = e.target.value;
    const formattedSearchText = searchText?.trim()?.toLowerCase();
    const { allCatalog } = this.state;
    const { catalogList } = this.props.catalog;
    let newList = [];
    if (!isEmpty(formattedSearchText)) {
      const productList = catalogList?.filter(
        (x) => x?.name && x.name?.trim()?.toLowerCase()?.includes(formattedSearchText)
      );

      const filteredCatalogList = allCatalog?.filter(
        (x) => x?.catalogue && x?.catalogue?.trim()?.toLowerCase()?.includes(formattedSearchText)
      );
      newList = [...(productList || []), ...(filteredCatalogList || [])];
    }
    this.setState({ searchedList: newList });
    this.setState({ searchText, searchData: undefined });
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      this.searchCatalog(searchText);
    }, 1000);
  };

  searchCatalog = async (searchText) => {
    let newList = [];
    searchText = searchText.trim().toLowerCase();
    const { allCatalog } = this.state;
    if (!isEmpty(searchText)) {
      newList = allCatalog?.filter((x) => x?.catalogue && x.catalogue.trim().toLowerCase().includes(searchText));
    } else {
      newList = allCatalog;
    }
    this.setState({ catalog: newList });
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return (
        <ErrorWrap>
          <Message text={resError} type={'error'} />
        </ErrorWrap>
      );
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Message text={resSuccess} type={'success'} />;
    }
  }
  onClickMobileSearch = () => {
    const { isShowMobileSearch } = this.state;
    this.setState({ isShowMobileSearch: !isShowMobileSearch });
  };

  render() {
    const { customerSettings = {} } = this.props.customer;
    const { hide_price } = customerSettings.profile_settings || {};
    const { searchText, catalog, searchedList, isShowMobileSearch, isLoading } = this.state;
    const { catalogList } = this.props.catalog;
    const isZeroCatalogPage = ZERO_QTY_CATALOG === this.props.match.url;
    const isAllCatalogPage = ALL_CATALOGS === this.props.match.url;
    const isEmptySearchList = !isLoading && (!catalog || catalog.length === 0);
    return (
      <Layout expanded={SIDEBAR_MENU_ALL_CATALOGS}>
        <MainWrapper>
          <MainContent>
            <ProductNav>
              <LeftWrap>
                <MenuView onClick={this.toggleSideMenu}>
                  <MenuIcon />
                </MenuView>
                <CatName>
                  {isAllCatalogPage ? 'All Catalogs' : isZeroCatalogPage ? 'Zero Qty Catalogs' : 'Catalogs'}
                </CatName>
              </LeftWrap>
              <RightWrap>
                {/* <Excel />
                <Pdf className='pdf' /> */}
                <SearchView>
                  <SearchBox
                    value={searchText}
                    onSearchChange={this.onSearchChange}
                    clearSearch={this.clearSerachText}
                    placeholder={'Search Product'}
                    autoComplete='off'
                  />
                </SearchView>
                <SearchIconView onClick={this.onClickMobileSearch}>
                  <SearchIcon />
                </SearchIconView>
                {/* <VerticalMoreIcon /> */}
                {searchedList?.length > 0 && (
                  <SearchData>
                    {searchedList?.map((item) => {
                      return (
                        <SearchList
                          onClick={(e) => {
                            this.onClickSearchedItem(e, item?.catalogue);
                          }}>
                          <TextSearch>{item?.name || item?.catalogue}</TextSearch>
                        </SearchList>
                      );
                    })}
                  </SearchData>
                )}
              </RightWrap>
            </ProductNav>
            {(!catalog || catalog?.length === 0) && isLoading ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <ContentBlockLive>
                {isShowMobileSearch && (
                  <SerchWrap>
                    <SearchViewMobile>
                      <SearchInput
                        type='text'
                        name='search'
                        value={searchText}
                        onChange={this.onSearchChange}
                        autoComplete='off'
                        placeholder={'Search Product'}></SearchInput>
                      <CloseIcon className='close' onClick={this.clearSerachText} />
                    </SearchViewMobile>
                    {searchedList?.length > 0 && (
                      <SearchDataMobile>
                        {searchedList?.map((item) => {
                          return (
                            <SearchList
                              onClick={(e) => {
                                this.onClickSearchedItem(e, item?.catalogue);
                              }}>
                              <TextSearch>{item?.name || item?.catalogue}</TextSearch>
                            </SearchList>
                          );
                        })}
                      </SearchDataMobile>
                    )}
                  </SerchWrap>
                )}
                {this.renderErrors()}
                {this.renderSuccess()}
                <ContentLive>
                  <ContentWrapperLive className={isEmptySearchList ? 'empty-center' : ''}>
                    {catalog &&
                      catalog.length > 0 &&
                      catalog.map((item, index) => {
                        const { catalogue, gallery, isShowOrderButton, isShowPlaceOrderButton } = item || {};
                        const catalogs = _.filter(catalogList, { catalogue: catalogue });
                        const length = _.size(catalogs);
                        const average = _.meanBy(catalogs, (p) => p.price_sales);
                        const total = _.sumBy(catalogs, function (o) {
                          return o.price_sales;
                        });
                        return (
                          <ContentBox key={`index${index}`}>
                            <Content
                              onClick={() => {
                                this.onClickCatalog(item.catalogue);
                              }}>
                              <ContentLeftImg>
                                <ProductImg src={gallery && gallery.length > 0 && gallery[0]?.url} />
                              </ContentLeftImg>
                              <ContentRight>
                                <CatalougeName>{catalogue}</CatalougeName>
                                <ProductInfo>
                                  <ProductDetail>Pcs</ProductDetail>
                                  <ProductDetailRight>{length}</ProductDetailRight>
                                </ProductInfo>
                                <ProductInfo isHidePrice={hide_price}>
                                  {!hide_price && (
                                    <>
                                      <ProductDetail>Avg</ProductDetail>
                                      <ProductDetailRight>₹ {currencyWithDecimal(average)}</ProductDetailRight>
                                    </>
                                  )}
                                </ProductInfo>
                                <ProductInfo isHidePrice={hide_price}>
                                  {!hide_price && (
                                    <>
                                      <ProductDetail>Total</ProductDetail>
                                      <ProductDetailRight>₹ {currencyWithDecimal(total)}</ProductDetailRight>
                                    </>
                                  )}
                                </ProductInfo>
                                {isShowOrderButton && (
                                  <CatalogueButtonWrapper className={'hideshow'}>
                                    <Button
                                      small
                                      isPrimary={true}
                                      title={'Order Now'}
                                      disabled={isZeroCatalogPage}
                                      onClick={(e) => {
                                        this.onClickOrderButton(e, index);
                                      }}></Button>
                                  </CatalogueButtonWrapper>
                                )}
                                {isShowPlaceOrderButton && (
                                  <ButtonWrap className='live-catalogue'>
                                    <LeftWrapper>
                                      <LButton
                                        isPrimary={true}
                                        onClick={(e) => {
                                          this.onClickMinusQty(e, index);
                                        }}>
                                        <MinusIcon />
                                      </LButton>
                                      <Input
                                        min={0}
                                        type={'number'}
                                        value={item?.orderQty}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) => {
                                          this.onChangeQty(e, index);
                                        }}
                                      />
                                      <LButton
                                        isPrimary={true}
                                        onClick={(e) => {
                                          this.onClickPlusQty(e, index);
                                        }}>
                                        <PlusIcon />
                                      </LButton>
                                    </LeftWrapper>
                                    <AddView>
                                      <Button
                                        small
                                        disabled={isZeroCatalogPage}
                                        isPrimary={true}
                                        title={'Add To Cart'}
                                        onClick={(e) => {
                                          this.onClickAddToCart(e, catalogs, index);
                                        }}
                                      />
                                    </AddView>
                                    <AddCartView
                                      onClick={(e) => {
                                        this.onClickAddToCart(e, catalogs, index);
                                      }}>
                                      <AddCartIcon />
                                    </AddCartView>
                                  </ButtonWrap>
                                )}
                              </ContentRight>
                              <IconRight className='hoverIcon'>
                                <ChervonRight />
                              </IconRight>
                            </Content>
                          </ContentBox>
                        );
                      })}
                  </ContentWrapperLive>
                </ContentLive>
                {isEmptySearchList && (
                  <EmptyContain>
                    <EmptyDetail>
                      <EmptyImage src={EmptyLogo} />
                      <TextEmpty>Sorry! Catalog not found</TextEmpty>
                    </EmptyDetail>
                  </EmptyContain>
                )}
              </ContentBlockLive>
            )}
          </MainContent>
        </MainWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  catalog: state.catalog,
  cart: state.cart,
  customer: state.customer
});

export default connect(mapStateToProps, {
  getCatalogList,
  toggleSideMenu,
  addToCart,
  setSuccessMessage
})(LiveCatalogs);
