import * as Actions from './types';

/**
 * @desc Set customer Loader
 */
export const setCustomerLoader = (payload) => {
  return {
    type: Actions.SET_CUSTOMER_LOADER,
    payload
  };
};

/**
 * @desc Set customer Item
 */
export const setCustomerItem = (payload) => {
  return {
    type: Actions.SET_CUSTOMER_ITEM,
    payload
  };
};

/**
 * @desc Set customer Account Manager
 */
export const setCustomerAccountManager = (payload) => {
  return {
    type: Actions.SET_CUSTOMER_ACCOUNT_MANAGER,
    payload
  };
};

/**
 * @desc Set Customer Settings
 */
export const setCustomerSettings = (payload) => {
  return {
    type: Actions.SET_CUSTOMER_SETTINGS,
    payload
  };
};

/**
 * @desc Clear customer data
 */
export const clearCustomerData = () => {
  return {
    type: Actions.CLEAR_CUSTOMER_DATA
  };
};
