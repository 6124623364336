import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as LogoIcon } from '../../../assets/images/svg/logobg.svg';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import {
  MembershipBody,
  MembershipWrap,
  MembershipBox,
  LoginForm,
  MemberLogin,
  LoginH2,
  LoginBtn,
  Paragarph,
  TextLink,
  PrivacyPolicy,
  LoginText,
  InputBox,
  LabelView,
  ButtonWrap,
  ButtonText
} from '../Signup/styles';
import { signup } from '../../../services/authService';
import { clearResponseMessage } from '../../../actions/messageActions';
import Message from '../../../components/Message';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      // mobileNumber: '',
      password: '',
      confirmPassword: '',
      errorMessage: ''
    };
  }
  componentDidMount() {
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleBackToLogin = () => {
    this.props.history.push('/login');
  };

  onChangeInputValue = (propsNames, e) => {
    this.setState({ [propsNames]: e.target.value });
    this.setState({ errorMessage: '' });
  };

  _showDialog = (errorMessage) => {
    this.setState({ errorMessage });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this._showDialog('');
    const { email, mobileNumber, password, confirmPassword } = this.state;
    const signupObj = {
      email,
      mobileNumber,
      password,
      confirmPassword
    };
    const result = await this.props.signup(signupObj);
    if (result === true) {
      this.props.history.push('/');
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Message text={resError} isSuccess={false} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Message text={resSuccess} isSuccess={true} />;
    }
  }

  render() {
    const { loading } = this.props.auth;
    return (
      <>
        <MembershipBody>
          <MembershipWrap>
            <MembershipBox>
              <LogoIcon />
            </MembershipBox>
            <LoginText>
              <LoginH2>Create New Account</LoginH2>
            </LoginText>
            <LoginForm onSubmit={this.onSubmit}>
              <MemberLogin>
                <InputBox>
                  <LabelView>Email</LabelView>
                  <Input
                    placeholder='Enter your email'
                    type={'text'}
                    onChange={(e) => {
                      this.onChangeInputValue('email', e);
                    }}
                    autoFocus={true}
                  />
                </InputBox>
                {/*<InputBox>
                <LabelView>Mobile Number</LabelView>
                  <Input
                  placeholder='Enter your mobile number'
                  type={'number'}
                  onChange={(e) => {
                    this.onChangeInputValue('mobileNumber', e);
                  }}
                /> 
                </InputBox>*/}
                <InputBox>
                  <LabelView>Password</LabelView>
                  <Input
                    placeholder='Enter password'
                    type={'password'}
                    onChange={(e) => {
                      this.onChangeInputValue('password', e);
                    }}
                  />
                </InputBox>
                <InputBox>
                  <LabelView>Confirm Password</LabelView>
                  <Input
                    placeholder='Re-enter password'
                    type={'password'}
                    onChange={(e) => {
                      this.onChangeInputValue('confirmPassword', e);
                    }}
                  />
                </InputBox>
                {this.renderErrors()}
                {this.renderSuccess()}
                <ButtonWrap className={classNames('btn-save-loading', { loading: loading })}>
                  <Button fullwidth isPrimary={true} title='Create New Account' disabled={loading} />
                </ButtonWrap>
              </MemberLogin>
              <LoginBtn>
                Have an account?
                <ButtonText
                  createbtn
                  fullwidth
                  type='Sign in'
                  isPrimary={false}
                  title='Sign in'
                  onClick={this.handleBackToLogin}
                  disabled={loading}>
                  Sign in
                </ButtonText>
              </LoginBtn>
            </LoginForm>
            <PrivacyPolicy>
              <Paragarph>
                By signing up, you are indicating that you have read and agree to the <TextLink>Terms of Use </TextLink>
                and <TextLink>Privacy Policy</TextLink>
              </Paragarph>
            </PrivacyPolicy>
          </MembershipWrap>
        </MembershipBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  signup
})(Signup);
