import amplitude from 'amplitude-js';
import { REACT_APP_AMPLITUDE_ANALYTICS_KEY } from '../global/Environment';
import { isDev } from '../helper/common';

const analytics = amplitude.getInstance();
analytics.init(REACT_APP_AMPLITUDE_ANALYTICS_KEY);

/**
 * @desc init analytics
 */
export const initAnalytics = () => {
  try {
    if (isDev()) {
      console.log('initAnalytics');
      return;
    }
    analytics.init(REACT_APP_AMPLITUDE_ANALYTICS_KEY);
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc set userid and email for analytics
 */
export const setUser = (user) => {
  try {
    if (isDev()) {
      console.log('setAnalyticUser', user.email);
      return;
    }
    if (user && user.email) {
      analytics.setUserId(user.email);
    } else {
      clearUser();
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc clear user information for analytics
 */
export const clearUser = () => {
  try {
    if (isDev()) {
      console.log('clearUser');
      return;
    }
    analytics.setUserId(null);
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc track activity in analytics
 */
export const trackActivity = (name, params) => {
  try {
    if (isDev()) {
      console.log('trackActivity', name, params);
      return;
    }
    analytics.logEvent(name, params);
  } catch (e) {
    console.log(e);
  }
};
