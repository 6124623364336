import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ReactComponent as NewOrderIcon } from '../../assets/images/svg/neworder.svg';
import { ReactComponent as ConfirmIcon } from '../../assets/images/svg/confirmicon.svg';
import { ReactComponent as HourGlassIcon } from '../../assets/images/svg/hourglass.svg';
import { ReactComponent as CancelOrderIcon } from '../../assets/images/svg/cancelordericon.svg';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getOrderOverview } from '../../services/dashboardService';
import ReportDateFilter from '../../components/ReportDateTimeFilter/ReportDateFilter';
import moment from 'moment';
import { getFirstDateOfTheMonth } from '../../helper/common';
import { Headertitle, Mediumtext, Smalltext, flex, flexcenter, flexspacebetween } from '../../font-variable';

const MainContent = styled.div``;

const ContentLive = styled.div`
  padding: 0 24px 24px;
  @media (max-width: 767px) {
    padding: 0 16px 16px;
  }
`;

const OverviewLabel = styled.h6`
  ${Mediumtext};
  font-weight: 600;
  color: var(--black-light-color);
  margin: 0;
`;

const IconView = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background: var(--background-dropdown);
  ${flexcenter};
  @media (max-width: 767px) {
    width: 45px;
    height: 45px;
  }
`;

const ContentWrapperLive = styled.div`
  padding: 0;
`;

const ContentBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 22px;
  @media (max-width: 767px) {
    gap: 16px;
  }
`;

const Content = styled.div`
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 6px;
  padding: 16px 16px;
  ${flex};
  @media (max-width: 767px) {
    padding: 12px;
  }
`;

const OrderWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const OrderValue = styled.h4`
  ${Headertitle};
  color: var(--black-light-color);
  margin: 0 4px 0 0;
`;

const OrderLabel = styled.p`
  ${Smalltext};
  color: var(--grey-color);
  margin: 0;
`;

const DateFilter = styled.div``;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  gap: 10px;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

const LeftHead = styled.div`
  padding: 0;
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 5,
      fromDate: getFirstDateOfTheMonth(),
      toDate: new Date()
    };
  }

  onChange = async (option, fromDate, toDate) => {
    const fromDateString = moment(fromDate).format('YYYY-MM-DD');
    const toDateString = moment(toDate).format('YYYY-MM-DD');
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      await this.props.getOrderOverview(companyId, fromDateString, toDateString);
    }
  };

  render() {
    const { orderOverview } = this.props.dashboard;
    const { option, fromDate, toDate } = this.state;
    const { total_cancelled, total_pending, total_received, total_orders } = orderOverview || {};
    return (
      <MainContent>
        <ContentLive>
          <HeaderWrapper>
            <LeftHead>
              <OverviewLabel>Overview</OverviewLabel>
            </LeftHead>
            <DateFilter>
              <ReportDateFilter
                option={option}
                fromDate={fromDate}
                toDate={toDate}
                onChange={this.onChange}
                backgroundColor={'#ffffff'}
              />
            </DateFilter>
          </HeaderWrapper>
          <ContentWrapperLive>
            <ContentBox>
              <Content>
                <IconView>
                  <NewOrderIcon />
                </IconView>
                <OrderWrap>
                  <OrderValue>{total_orders}</OrderValue>
                  <OrderLabel>New Order</OrderLabel>
                </OrderWrap>
              </Content>
              <Content>
                <IconView>
                  <ConfirmIcon />
                </IconView>
                <OrderWrap>
                  <OrderValue>{total_received}</OrderValue>
                  <OrderLabel>Confirmed Order</OrderLabel>
                </OrderWrap>
              </Content>
              <Content>
                <IconView>
                  <HourGlassIcon />
                </IconView>
                <OrderWrap>
                  <OrderValue>{total_pending}</OrderValue>
                  <OrderLabel>Pending Order</OrderLabel>
                </OrderWrap>
              </Content>
              <Content>
                <IconView>
                  <CancelOrderIcon />
                </IconView>
                <OrderWrap>
                  <OrderValue>{total_cancelled}</OrderValue>
                  <OrderLabel>Cancelled Order</OrderLabel>
                </OrderWrap>
              </Content>
            </ContentBox>
          </ContentWrapperLive>
        </ContentLive>
      </MainContent>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
});

export default connect(mapStateToProps, {
  getOrderOverview
})(Dashboard);
