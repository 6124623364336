import React, { Component } from 'react';
import { INVOICE_TEMPLATE_TYPE } from '../../global/constants';
import ClassicInvoiceTemplate from './ClassicInvoiceTemplate';
import DefaultInvoiceTemplate from './DefaultInvoiceTemplate';
import ThermalInvoiceTemplate from './ThermalInvoiceTemplate';

class InvoiceTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { type } = this.props;
    return (
      <>
        {type === INVOICE_TEMPLATE_TYPE.DEFAULT && <DefaultInvoiceTemplate {...this.props} />}
        {type === INVOICE_TEMPLATE_TYPE.CLASSIC && <ClassicInvoiceTemplate {...this.props} />}
        {type === INVOICE_TEMPLATE_TYPE.THERMAL && <ThermalInvoiceTemplate {...this.props} />}
      </>
    );
  }
}

export default InvoiceTemplates;
