import React, { Component } from 'react';
import styled from 'styled-components';
import myImage from '../../assets/images/svg/pageloader.svg';
const PageLoading = styled.span`
  background-image: url(${myImage});
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

class Loading extends Component {
  render() {
    return <PageLoading></PageLoading>;
  }
}

export default Loading;
