import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  ledgerReport: undefined,
  invoiceList: undefined,
  filteredInvoiceList: undefined,
  invoicePreview: undefined
};

export const ReportsReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_REPORTS_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case Actions.SET_LEDGER_REPORT:
      return {
        ...state,
        ledgerReport: action.payload
      };
    case Actions.SET_INVOICE_LIST:
      return {
        ...state,
        invoiceList: action.payload
      };
    case Actions.SET_FILTERED_INVOICE_LIST:
      return {
        ...state,
        filteredInvoiceList: action.payload
      };
    case Actions.SET_INVOICE_PREVIEW:
      return {
        ...state,
        invoicePreview: action.payload
      };
    case Actions.CLEAR_REPORTS_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
