import React, { Component } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import classNames from 'classnames';
import Button from '../Button/Button';
import { getCustomerSettings, updateCustomerSettings } from '../../services/customerService';
import { setCustomerSettings } from '../../actions/customerActions';
import Message from '../Message';
import { Mediumtext, Smalltext, flex, flexspacebetween } from '../../font-variable';

const SaleWrapper = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  @media (max-width: 470px) {
    max-width: 360px;
  }
`;

const ItemLeft = styled.div``;

const ItemWrap = styled.div`
  ${flex};
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const HeaderHeading = styled.h2`
  ${Mediumtext};
  color: var(--header-title-color);
  font-weight: 600;
  margin: 0;
`;

const SaleModalWrap = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  flex-direction: column;
  background: var(--secondary-color);
  position: sticky;
  top: 0px;
  z-index: 99;
  padding: 12px 18px;
  margin-bottom: 18px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid var(--secondary-border-color);
`;

const SettingsForm = styled.form``;

const ContentRight = styled.div`
  width: 100%;
  padding: 0 18px 18px;
`;

const FormBoxWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 470px) {
    flex-direction: column;
    margin-bottom: 15px;
    gap: 15px;
  }
`;

const FormBoxlist = styled.div`
  width: 100%;
  margin: 24px 0px;
`;

const CheckBox = styled.div`
  ${flex};
  input[type='checkbox'] {
    position: relative;
    border: 1px solid var(--grey-color);
    border-radius: 4px;
    background: none;
    cursor: pointer;
    margin: 0 8px 0 0;
    outline: 0;
    padding: 0;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    appearance: none;
    opacity: 1;
  }

  input[type='checkbox']:hover {
    opacity: 1;
  }

  input[type='checkbox']:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: 1;
  }

  input[type='checkbox']:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 9px;
    border: solid var(--secondary-color);
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
`;

const InputCheck = styled.input``;

const FormBox = styled.div`
  width: 100%;
  max-width: 50%;
  margin-right: 0;
  margin-left: 10px;
  @media (max-width: 470px) {
    margin-left: 0;
  }
  ${(props) =>
    props.firstbox &&
    css`
      margin-left: 0px;
      margin-right: 10px;
    `}
`;

const LabelForm = styled.label`
  ${Smalltext};
  color: var(--grey-color);
  margin-bottom: 6px;
  display: block;
  white-space: nowrap;
  span {
    color: var(--red-color);
  }
`;

const LabelFormCheck = styled.label`
  ${Smalltext};
  color: var(--grey-color);
  display: block;
  text-transform: capitalize;
  span {
    color: var(--red-color);
  }
`;

const SaveBtn = styled(Button)``;
const CancelBtn = styled(Button)``;

const BtnBottom = styled.div`
  button {
    margin: 0px 8px 0px 0px;
  }
`;

const SubmitButton = styled.div`
  display: inline-block;
`;

const ValueText = styled.p`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0;
`;

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide_price: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const company_id = UserPreferenceSingleton.getInstance().getCompanyId();
    const result = await this.props.getCustomerSettings(company_id, true, true);
    if (result) {
      const { customerSettings = {} } = this.props.customer;
      const { hide_price } = customerSettings.profile_settings || {};
      this.setState({ hide_price });
    }
  };

  onClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  onChangeHidePrice = (value) => {
    this.setState({ hide_price: value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      const { hide_price } = this.state;
      const { customerSettings = {} } = this.props.customer;
      customerSettings.profile_settings.hide_price = hide_price;
      const result = await this.props.updateCustomerSettings(companyId, customerSettings, true);
      if (result) this.onClose();
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Message text={resError} type={'error'} />;
    }
  }

  render() {
    const { customerItem = {} } = this.props.customer;
    const { hide_price } = this.state;
    const { first_name, last_name, mobile_numbers = [], email } = customerItem;
    const mobileNumbers = mobile_numbers?.map((x) => `+${x?.code} ${x?.number}`)?.join(', ');
    return (
      <>
        <SaleWrapper>
          <SaleModalWrap>
            <HeaderWrapper>
              <HeaderBlock>
                <ItemLeft>
                  <HeaderHeading>Profile Settings</HeaderHeading>
                </ItemLeft>
                <ItemWrap>
                  <CloseIcon onClick={this.onClose} />
                </ItemWrap>
              </HeaderBlock>
            </HeaderWrapper>
            {this.renderErrors()}
            <SettingsForm onSubmit={this.onSubmit}>
              <ContentRight>
                <FormBoxWrapper>
                  <FormBox firstbox>
                    <LabelForm>First Name</LabelForm>
                    <ValueText>{first_name}</ValueText>
                  </FormBox>
                  <FormBox>
                    <LabelForm>Last Name</LabelForm>
                    <ValueText>{last_name}</ValueText>
                  </FormBox>
                </FormBoxWrapper>
                <FormBoxWrapper>
                  <FormBox firstbox>
                    <LabelForm>Email</LabelForm>
                    <ValueText>{email}</ValueText>
                  </FormBox>
                  <FormBox>
                    <LabelForm>Mobile Number</LabelForm>
                    <ValueText>{mobileNumbers}</ValueText>
                  </FormBox>
                </FormBoxWrapper>
                <FormBoxlist>
                  <CheckBox>
                    <InputCheck
                      type='checkbox'
                      id='hide_prices'
                      name='check'
                      checked={hide_price}
                      value={hide_price}
                      onChange={() => {
                        this.onChangeHidePrice(!hide_price);
                      }}
                    />
                    <LabelFormCheck htmlFor='hide_prices'>Hide Prices From All Products</LabelFormCheck>
                  </CheckBox>
                </FormBoxlist>
                <BtnBottom>
                  <SubmitButton className={classNames('btn-save-loading', { loading: false })}>
                    <SaveBtn small isPrimary={true} type='submit' title='Save' disabled={false} />
                  </SubmitButton>
                  <CancelBtn type='button' createbtn isPrimary={true} onClick={this.onClose} title='Cancel' />
                </BtnBottom>
              </ContentRight>
            </SettingsForm>
          </SaleModalWrap>
        </SaleWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer,
  message: state.message
});

export default connect(mapStateToProps, {
  setCustomerSettings,
  updateCustomerSettings,
  getCustomerSettings
})(SettingsModal);
