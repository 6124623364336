import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  orderOverview: undefined,
  topSoldProducts: undefined,
  newCatalogs: undefined,
  totalSaleAndBill: undefined,
  todaysActivity: undefined,
  totalOrderChartData: undefined,
  announcementList: undefined
};

export const dashboardReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_DASHBOARD_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case Actions.SET_ORDER_OVERVIEW:
      return {
        ...state,
        orderOverview: action.payload
      };
    case Actions.SET_TOP_SOLD_PRODUCTS:
      return {
        ...state,
        topSoldProducts: action.payload
      };
    case Actions.SET_NEW_CATALOGS:
      return {
        ...state,
        newCatalogs: action.payload
      };
    case Actions.SET_TOTAL_SALES_AND_BILL:
      return {
        ...state,
        totalSaleAndBill: action.payload
      };
    case Actions.SET_TODAYS_ACTIVITY:
      return {
        ...state,
        todaysActivity: action.payload
      };
    case Actions.SET_TOTAL_ORDER_CHART_DATA:
      return {
        ...state,
        totalOrderChartData: action.payload
      };
    case Actions.SET_ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcementList: action.payload
      };
    default:
      return state;
  }
};
