import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Mediumtext } from '../../font-variable';

const TextInput = styled.input`
  ${Mediumtext}
  padding: 8px 14px;
  margin: 0;
  outline: 0;
  width: 100%;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid var(--secondary-border-color);
  background: var(--secondary-color);
  color: var(--black-light-color);
  transition: 0.1s all ease;
  &::placeholder {
    color: var(--placeholder-color);
  }

  ${(props) =>
    props.filtermodal &&
    css`
      margin: 10px 0px 0px 0px;
      padding: 9px 12px;
      ${Mediumtext};
      color: var(--black-light-color);
    `}
  ${(props) =>
    props.isError &&
    css`
      border: 1px solid #ff9d83;
      :hover {
        border: 1px solid #ff9d83 !important;
      }
      :focus {
        border: 1px solid #ff9d83 !important;
        outline: 0 !important;
      }
    `}

  :hover {
    border: 1px solid var(--input-border-hover-color);
  }
  :focus {
    border: 1px solid var(--input-border-hover-color);
    outline: 0;
  }
  :active {
    border: 1px solid var(--input-border-hover-color);
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  [type='number'] {
    appearance: textfield;
  }
`;

class Input extends Component {
  render() {
    const { type, disabled, placeholder, autoFocus, value, onChange, onKeyDown, min, max, isError } = this.props;
    return (
      <>
        <TextInput
          min={min}
          max={max}
          {...this.props}
          type={type}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={value}
          onKeyDown={onKeyDown}
          isError={isError}
        />
      </>
    );
  }
}

export default Input;
