import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { ReactComponent as LogoIcon } from '../../../assets/images/svg/logobg.svg';
import { getUrlParam } from '../../../helper/common';
import { resetPassword } from '../../../services/authService';
import Message from '../../../components/Message';
import { clearResponseMessage } from '../../../actions/messageActions';
import { Maintitle, Mediumtext, Smalltext, flexcenter } from '../../../font-variable';

const MembershipBody = styled.div`
  ${flexcenter};
  background-color: var(--background-color);
  height: 100%;
`;

const MembershipWrap = styled.div`
  max-width: 420px;
  width: 100%;
  margin: auto;
  padding: 50px 10px;
  text-align: center;
`;

const MembershipBox = styled.div``;
const LoginForm = styled.form``;

const MemberLogin = styled.div`
  margin-bottom: 24px;
`;

const LoginText = styled.div`
  text-align: center;
  margin: 16px 0 32px;
`;

const LoginH2 = styled.h2`
  ${Maintitle};
  color: var(--header-title-color);
  margin: 0 0 10px;
`;

const LoginCaption = styled.p`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0;
`;

const ButtonWrap = styled.div`
  margin: 24px 0;
  button {
    margin: 0;
  }
`;

const InputBox = styled.div`
  margin-bottom: 18px;
  input {
    :focus {
      border: 1px solid var(--primary-color);
      offset-distance: 100%;
    }
    :focus-visible {
      border: 1px solid var(--primary-color);
      outline: 3px solid var(--outline-blue-color);
      margin: auto;
      border-radius: 4px;
    }
  }
`;

const LabelView = styled.label`
  ${Smalltext};
  color: var(--grey-color);
  margin-bottom: 6px;
  text-align: left;
  display: block;
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirm_password: ''
    };
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onChangeInputValue = (propsNames, e) => {
    this.setState({ [propsNames]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email, confirm_password, password } = this.state;
    const token = getUrlParam(this.props.location.search, 'token');

    const obj = {
      email,
      token,
      password,
      confirm_password
    };
    const result = await this.props.resetPassword(obj);
    this.setState({ result });
  };

  onClickLogin = () => {
    this.props.history.push('/login');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Message text={resError} type={'error'} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Message text={resSuccess} type={'success'} />;
    }
  }

  render() {
    const { email } = this.state;
    const { loading } = this.props.auth;
    const { resSuccess } = this.props.message;

    return (
      <>
        <MembershipBody>
          <MembershipWrap>
            <MembershipBox>
              <LogoIcon />
            </MembershipBox>
            <LoginText>
              <LoginH2>Forget a Password</LoginH2>
              <LoginCaption>Password reset link will be mailed to you. </LoginCaption>
            </LoginText>
            <LoginForm onSubmit={this.onSubmit}>
              <MemberLogin>
                {!resSuccess ? (
                  <>
                    <InputBox>
                      <LabelView>Phone number or email</LabelView>
                      <Input
                        placeholder='Enter your number or email'
                        type='text'
                        value={email}
                        autoFocus={true}
                        onChange={(e) => {
                          this.onChangeInputValue('email', e);
                        }}
                      />
                    </InputBox>
                    <ButtonWrap className={classNames('btn-save-loading', { loading: loading })}>
                      <Button fullwidth isPrimary={true} disabled={loading} title='Reset Password' type='submit' />
                    </ButtonWrap>
                  </>
                ) : (
                  <>{this.renderSuccess()}</>
                )}

                {this.renderErrors()}
              </MemberLogin>
            </LoginForm>
          </MembershipWrap>
        </MembershipBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});
export default connect(mapStateToProps, { resetPassword, clearResponseMessage })(ResetPassword);
