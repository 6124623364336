import { connect } from 'react-redux';
import React, { Component } from 'react';
import classNames from 'classnames';
import { ReactComponent as LogoIcon } from '../../../assets/images/svg/logobg.svg';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import {
  MembershipBody,
  MembershipWrap,
  MembershipBox,
  LoginForm,
  MemberLogin,
  LoginH2,
  LoginCaption,
  LabelView,
  LoginBtn,
  ButtonWrap,
  LoginText,
  ButtonText,
  InputBox
} from '../Forgotpassword/style';
import { forgotPassword } from '../../../services/authService';
import Message from '../../../components/Message';
import { clearResponseMessage } from '../../../actions/messageActions';
import { isEmpty } from '../../../helper/common';
import Error from '../../../components/ErrorMessage/ErrorMessage';

class Forgotpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onClickLogin = () => {
    this.props.history.push('/login');
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;

    const obj = {
      email
    };
    await this.props.forgotPassword(obj);
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Message text={resError} type={'error'} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Message text={resSuccess} type={'success'} />;
    }
  }

  render() {
    const { email } = this.state;
    const { loading } = this.props.auth;
    const { resSuccess } = this.props.message;
    const errorMessages = this.props.membership;
    return (
      <>
        <MembershipBody>
          <MembershipWrap>
            <MembershipBox>
              <LogoIcon />
            </MembershipBox>
            <LoginText>
              <LoginH2>Forget a Password</LoginH2>
              <LoginCaption>Password reset link will be mailed to you.</LoginCaption>
            </LoginText>
            <LoginForm onSubmit={this.onSubmit}>
              <MemberLogin>
                {!resSuccess ? (
                  <>
                    <InputBox>
                      <LabelView>Email Address</LabelView>
                      <Input
                        placeholder='Enter your email'
                        type='email'
                        value={email}
                        autoFocus={true}
                        onChange={this.onChangeEmail}
                        isError={!isEmpty(errorMessages?.emailForget)}
                      />
                      {!isEmpty(errorMessages?.emailForget) && <Error message={errorMessages?.emailForget} />}
                    </InputBox>
                    {this.renderErrors()}
                    <ButtonWrap className={classNames('btn-save-loading', { loading: loading })}>
                      <Button fullwidth isPrimary={true} disabled={loading} title='Reset Password' type='submit' />
                    </ButtonWrap>
                  </>
                ) : (
                  <>
                    <LoginH2>Forgot Password Confirmation</LoginH2>
                    {this.renderSuccess()}
                  </>
                )}
              </MemberLogin>

              <LoginBtn>
                Go back to
                <ButtonText
                  fullwidth
                  createbtn
                  isPrimary={false}
                  title='Sign in'
                  type='button'
                  onClick={this.onClickLogin}>
                  Sign in
                </ButtonText>
              </LoginBtn>
            </LoginForm>
          </MembershipWrap>
        </MembershipBody>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  membership: state.membership
});
export default connect(mapStateToProps, { forgotPassword, clearResponseMessage })(Forgotpassword);
