import * as Actions from '../actions/types';

const initialState = {
  loading: false,
  pendingOrderList: undefined,
  cancelOrderList: undefined,
  invoicedOrderList: undefined,
  filteredDataList: []
};

export const orderListReducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case Actions.SET_ORDER_LIST_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case Actions.SET_PENDING_ORDER_LIST:
      return {
        ...state,
        pendingOrderList: action.payload
      };
    case Actions.SET_CANCEL_ORDER_LIST:
      return {
        ...state,
        cancelOrderList: action.payload
      };
    case Actions.SET_INVOICED_ORDER_LIST:
      return {
        ...state,
        invoicedOrderList: action.payload
      };
    case Actions.UPDATE_ORDER_LIST_FILTERED_LIST:
      return {
        ...state,
        filteredDataList: action.payload
      };
    case Actions.CLEAR_ORDER_LIST_DATA:
      const initialTempState = JSON.parse(JSON.stringify(initialState));
      return initialTempState;
    default:
      return state;
  }
};
