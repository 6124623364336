import * as Actions from './types';

/**
 * @desc Set Pagination List
 */
export const toggleSideMenu = (payload) => {
  return {
    type: Actions.TOGGLE_SIDEMENU,
    payload
  };
};
