import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { clearResponseMessage } from '../../../actions/messageActions';
import { login } from '../../../services/authService';
import { ReactComponent as LogoIcon } from '../../../assets/images/svg/logobg.svg';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Message from '../../../components/Message';
import { loadRequiredData } from '../../../services/baseService';
import {
  MembershipBody,
  MembershipWrap,
  MembershipBox,
  LoginForm,
  LoginH2,
  MemberLogin,
  PasswordDiv,
  ForgotPassWord,
  ButtonWrap,
  LabelView,
  LoginCaption,
  InputBox,
  LoginText,
  ButtonText,
  CreateAccount
} from './styles';
import { isEmpty } from '../../../helper/common';
import Error from '../../../components/ErrorMessage/ErrorMessage';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleForgotPassword = () => {
    this.props.history.push('/forgot-password');
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleCreateNewAccount = () => {
    this.props.history.push('/signup');
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    const loginObj = {
      email: email,
      password: password
    };

    const result = await this.props.login(loginObj);
    if (result === true) {
      await this.props.loadRequiredData(this.props.history);
      this.props.history.push('/');
    }
  };

  renderErrors() {
    const { resError } = this.props.message;

    if (resError) {
      return <Message text={resError} type={'error'} />;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <Message text={resSuccess} type={'success'} />;
    }
  }

  render() {
    const { email, password } = this.state;
    const { loading } = this.props.auth;
    const errorMessages = this.props.membership;
    return (
      <MembershipBody>
        <MembershipWrap>
          <MembershipBox>
            <LogoIcon />
          </MembershipBox>
          <LoginText>
            <LoginH2>Sign In </LoginH2>
            <LoginCaption>Welcome back. You’ve been missed! </LoginCaption>
          </LoginText>
          <LoginForm onSubmit={this.onSubmit}>
            <MemberLogin>
              <InputBox>
                <LabelView>Phone number or email</LabelView>
                <Input
                  placeholder='Enter your phone number or email'
                  onChange={this.onChangeEmail}
                  value={email}
                  type='text'
                  autoFocus={true}
                  isError={!isEmpty(errorMessages?.email)}
                />
                {!isEmpty(errorMessages?.email) && <Error message={errorMessages?.email} />}
              </InputBox>
              <InputBox>
                <LabelView>Password</LabelView>
                <Input
                  placeholder='Enter your password'
                  onChange={this.onChangePassword}
                  value={password}
                  type='password'
                  isError={!isEmpty(errorMessages?.password)}
                />
                {!isEmpty(errorMessages?.password) && <Error message={errorMessages?.password} />}
              </InputBox>
              <PasswordDiv>
                <ForgotPassWord onClick={this.handleForgotPassword} disabled={loading}>
                  Forgot Password?
                </ForgotPassWord>
              </PasswordDiv>
            </MemberLogin>
            <ButtonWrap className={classNames('btn-save-loading', { loading: loading })}>
              <Button fullwidth isPrimary={true} title='Sign In' disabled={loading} type='submit' />
            </ButtonWrap>
            {this.renderErrors()}
            {this.renderSuccess()}
          </LoginForm>
          <CreateAccount>
            Don't have an account?
            <ButtonText
              createbtn
              fullwidth
              isPrimary={true}
              title='Create New Account'
              type='button'
              onClick={this.handleCreateNewAccount}>
              Create New Account
            </ButtonText>
          </CreateAccount>
        </MembershipWrap>
      </MembershipBody>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  company: state.company,
  membership: state.membership
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  login,
  loadRequiredData
})(Login);
