import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { getTotalSalesAndBill } from '../../services/dashboardService';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import ReportDateFilter from '../../components/ReportDateTimeFilter/ReportDateFilter';
import Loading from '../../components/Loading/Loading';
import { ReactComponent as BillIcon } from '../../assets/images/svg/billicon.svg';
import { ReactComponent as SaleIcon } from '../../assets/images/svg/saleicon.svg';
import { currencyWithDecimal } from '../../helper/common';
import { Headertitle, Mediumtext, Smalltext, flex, flexcenter, flexspacebetween } from '../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MinContact = styled.div`
  position: relative;
  width: 100%;
`;

const ShotcutDiv = styled.div`
  width: 100%;
  height: 320px;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const Sale = styled.div`
  ${flex};
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 100%;
`;

const Bill = styled.div`
  ${flex};
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  width: 100%;
`;

const Head = styled.h3`
  ${Mediumtext};
  color: var(--black-light-color);
  font-weight: 600;
  display: block;
  white-space: nowrap;
  margin: 0;
`;

const TabelHead = styled.span`
  ${Smalltext};
  color: var(--grey-color);
`;

const TotalAmount = styled.h4`
  ${Headertitle}
  color: var(--black-light-color);
  margin: 12px 0 0;
`;

const HeaderWrapper = styled.div`
  ${flex};
  justify-content: space-between;
  gap: 16px;
  position: relative;
  z-index: 0;
  padding: 16px 16px;
  margin-bottom: -4px;
  @media (max-width: 767px) {
    padding: 16px 12px;
  }
`;

const MainTab = styled.div`
  ${flexcenter}
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
`;

const TotalWrapper = styled.div`
  ${flexspacebetween}
  position: relative;
  width: 100%;
`;

const IconView = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background: var(--background-dropdown);
  ${flexcenter};
  @media (max-width: 767px) {
    width: 45px;
    height: 45px;
  }
`;

const TotalWrap = styled.div`
  text-align: center;
`;
const VerticallIne = styled.div`
  text-align: center;
  border-right: 1px solid #e2e2e2;
  height: 100%;
  margin: 0 8px;
`;

class TotalSalesAndBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      fromdate: undefined,
      todate: undefined
    };
  }

  onGetTotalSalesAndBill = async () => {
    const { fromdate, todate } = this.state;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (!companyId) return;
    this.setState({ isLoading: true });
    await this.props.getTotalSalesAndBill(companyId, fromdate, todate);
    this.setState({ isLoading: false });
  };

  onChange = async (option, fromDate, toDate) => {
    const fromDateString = moment(fromDate).format('YYYY-MM-DD');
    const toDateString = moment(toDate).format('YYYY-MM-DD');
    this.setState({ fromdate: fromDateString, todate: toDateString }, () => {
      this.onGetTotalSalesAndBill();
    });
  };

  render() {
    const { isLoading } = this.state;
    const { totalSaleAndBill } = this.props.dashboard;
    return (
      <>
        <MinContact>
          <ShotcutDiv>
            <HeaderWrapper>
              <Head>Bill and Sale</Head>
              <ReportDateFilter onChange={this.onChange} loading={isLoading} backgroundColor={'#f9f9f9'} />
            </HeaderWrapper>
            {isLoading && (!totalSaleAndBill || totalSaleAndBill?.length === 0) ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <MainTab>
                <TotalWrapper>
                  <Bill>
                    <IconView>
                      <BillIcon />
                    </IconView>
                    <TotalWrap>
                      <TabelHead>Total Bill</TabelHead>
                      <TotalAmount>
                        ₹ {currencyWithDecimal(totalSaleAndBill?.[0]?.total_invoice_amount) || 0}
                      </TotalAmount>
                    </TotalWrap>
                  </Bill>
                  <VerticallIne></VerticallIne>
                  <Sale>
                    <IconView>
                      <SaleIcon />
                    </IconView>
                    <TotalWrap>
                      <TabelHead>Total Sale</TabelHead>
                      <TotalAmount>{totalSaleAndBill?.[0]?.total_invoice_count || 0}</TotalAmount>
                    </TotalWrap>
                  </Sale>
                </TotalWrapper>
              </MainTab>
            )}
          </ShotcutDiv>
        </MinContact>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
});

export default connect(mapStateToProps, {
  getTotalSalesAndBill
})(TotalSalesAndBill);
