import { setCurrentPage, setPaginationList, setTotalPage, setTotalRecords } from '../actions/paginationActions';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';

/**
 * @desc  load pagging list
 */
export const loadListWithPagging =
  (list, index, isSetTotalRecords = false) =>
  (dispatch) => {
    if (!list) return;
    const paginatedList = dispatch(getLocalPaging(list, index));
    dispatch(setPaginationList(paginatedList));
    dispatch(setCurrentPage(index));
    if (isSetTotalRecords) {
      dispatch(setTotalRecords(list.length));
    }
  };

/**
 * @desc  get Local Paging
 */
export const getLocalPaging = (allrecordList, pageIndex) => (dispatch) => {
  if (allrecordList) {
    let ItemPerPage = UserPreferenceSingleton.getInstance().getPageCount();
    dispatch(getTotalPageCount(allrecordList.length, pageIndex, ItemPerPage));
    let skipCount = ItemPerPage * (pageIndex - 1);
    return allrecordList.slice(skipCount, skipCount + ItemPerPage);
  }
  return undefined;
};

/**
 * @desc  get Total Page Count
 */
export const getTotalPageCount = (totalRecords, currentPage, ItemPerPage) => (dispatch) => {
  let totalPage = 0;
  if (totalRecords > 0) {
    totalPage = Math.ceil(totalRecords / ItemPerPage);
  }
  dispatch(setCurrentPage(currentPage));
  dispatch(setTotalPage(totalPage));
  return totalPage;
};
