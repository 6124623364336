import React, { Component } from 'react';
import _ from 'lodash';
import Layout from '../../../components/Layout/Layout';
import { OUT_OF_STOCK_PRODUCTS, PRODUCT_STATUS, SIDEBAR_MENU_ALL_CATALOGS } from '../../../global/constants';
// import { ReactComponent as Excel } from '../../assets/images/svg/exel.svg';
// import { ReactComponent as Pdf } from '../../assets/images/svg/pdf.svg';
import { ReactComponent as MenuIcon } from '../../../assets/images/svg/menuicon.svg';
import { ReactComponent as CartIcon } from '../../../assets/images/svg/cartmobileicon.svg';
import { ReactComponent as AddCartIcon } from '../../../assets/images/svg/addcarticon.svg';
import { ReactComponent as VerticalMoreIcon } from '../../../assets/images/svg/Verticalmore.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/svg/searchmobile.svg';
import { ReactComponent as ExcelIcon } from '../../../assets/images/svg/exel.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/svg/plusicon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/svg/minusicon.svg';
import Button from '../../../components/Button/Button';
import EmptyLogo from '../../../assets/images/empty-catalog.svg';
import { getCatalogList } from '../../../services/catalogService';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
  MainWrapper,
  MainContent,
  ProductNav,
  CatName,
  SearchInput,
  RightWrap,
  LeftWrap,
  CatalogBlock,
  ContentWrapper,
  ProductInfoCatalog,
  Product,
  ProductImg,
  CatalougeName,
  ContentBottomCatalog,
  ContentBoxCatalog,
  ContentCatalog,
  ContentTopCatalog,
  ButtonWrapper,
  ContentBLockWrapper,
  EmptyContain,
  EmptyDetail,
  EmptyImage,
  TextEmpty,
  TextDetail,
  MenuView,
  ButtonWrap,
  LeftWrapper,
  Outofstock,
  OutList,
  MobileIcon,
  AddCartView,
  AddView,
  SearchView,
  SearchIconView,
  SearchViewMobile,
  SerchWrap,
  SvgIconWrap,
  ContentBoxProducts,
  ContentBoxProductsList,
  ProductReady,
  LButton,
  ErrorWrap,
  AccountChartIconBlock,
  LoadView
} from './style';
import UserPreferenceSingleton from '../../../helper/UserPreferenceSingleton';
import { connect } from 'react-redux';
import { currencyWithDecimal, groupBy, isEmpty } from '../../../helper/common';
import Loading from '../../../components/Loading/Loading';
import { toggleSideMenu } from '../../../actions/SideMenuAction';
import { addToCart } from '../../../actions/CartAction';
import Message from '../../../components/Message';
import { setSuccessMessage } from '../../../actions/messageActions';
import Input from '../../../components/Input/Input';
import { outOfStockProductCSVHeaders } from '../../../data/raw';
import { ReactComponent as FilterIconSvg } from '../../../assets/images/svg/Filter.svg';
import Timer from '../../../components/Timer/Timer';
import { Menu, withStyles } from '@material-ui/core';
import FilterModalItem from '../FilterModalItem/FilterModalItem';
import SearchBox from '../../../components/SearchBox/SearchBox';
import { ReactComponent as CloseIcon } from '../../../assets/images/svg/closeicon-small.svg';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid var(--secondary-border-color)',
    padding: '12px',
    background: 'var(--secondary-color)',
    boxShadow: 'var(--box-shadow) !important',
    borderRadius: '6px',
    marginTop: '5px',
    width: (props) => (props?.isSummaryMenu ? '465px' : '220px'),
    maxHeight: (props) => (props?.isSummaryMenu ? '420px' : ''),
    overflowY: (props) => (props?.isSummaryMenu ? 'scroll' : '')
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));
class CatalogProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogProducts: [],
      searchText: '',
      isShowMobileSearch: false,
      isOpenFilterModal: false,
      anchorEl: null,
      filterData: undefined
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    await this.props.getCatalogList(companyId);
    const isOutOfStockProductPage = OUT_OF_STOCK_PRODUCTS === this.props.match.url;
    const { catalogList = [] } = this.props.catalog;
    const { filterData } = this.state;
    const { fromdate, todate } = filterData || {};
    let catalogProducts = [];
    if (isOutOfStockProductPage) {
      catalogProducts = catalogList?.filter((x) => x?.status === PRODUCT_STATUS.INACTIVE && x?.qty <= 0);
      if (!isEmpty(fromdate) && !isEmpty(todate)) {
        catalogProducts = catalogProducts?.filter((x) => x?.deactivatedAt >= fromdate && x?.deactivatedAt <= todate);
      }
    } else {
      const catalogName = window.atob(this.props.match.params.product);
      catalogProducts = _.filter(catalogList, { catalogue: catalogName });
      catalogProducts = catalogProducts?.map((x) => {
        return {
          ...x,
          isShowOrderButton: true,
          isShowPlaceOrderButton: false,
          orderQty: 1
        };
      });
    }
    this.setState({ catalogProducts, allCatalogProducts: catalogProducts });
  };

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  onClickAddToCart = (e, catalog, index) => {
    e.stopPropagation();
    const { cart = [] } = this.props.cart;
    let { catalogProducts } = this.state;
    let newCart = cart;
    let existedProduct = newCart?.find((x) => catalog?.id === x?.id);
    if (existedProduct) {
      existedProduct.orderedQty += Number(catalog?.orderQty);
      existedProduct.totalAmt = Number(catalog?.price_sales) * existedProduct?.orderedQty;
    } else {
      catalog.orderedQty = Number(catalog?.orderQty);
      catalog.totalAmt = Number(catalog?.price_sales) * catalog?.orderedQty;
      catalog.isFromCatalog = false;
      catalog.isPreBook = catalog?.qty === 0 && catalog?.status === PRODUCT_STATUS.ACTIVE;
      newCart = [...newCart, catalog];
    }
    this.props.addToCart(newCart);
    UserPreferenceSingleton.getInstance().setCartItems(newCart);
    this.props.setSuccessMessage('Added to Cart');
    setTimeout(() => {
      this.props.setSuccessMessage('');
    }, 5000);
    catalogProducts[index].orderQty = 1;
    catalogProducts[index].isShowOrderButton = !catalogProducts[index]?.isShowOrderButton;
    catalogProducts[index].isShowPlaceOrderButton = !catalogProducts[index].isShowPlaceOrderButton;
    this.setState({ catalogProducts });
  };

  onClickOrderButton = (index) => {
    let { catalogProducts } = this.state;
    catalogProducts[index].isShowOrderButton = !catalogProducts[index]?.isShowOrderButton;
    catalogProducts[index].isShowPlaceOrderButton = !catalogProducts[index]?.isShowPlaceOrderButton;
    this.setState({ catalogProducts });
  };

  onClickPlusQty = (index) => {
    const { catalogProducts } = this.state;
    catalogProducts[index].orderQty += 1;
    this.setState({ catalogProducts });
  };

  onClickMinusQty = (index) => {
    const { catalogProducts } = this.state;
    if (catalogProducts[index].orderQty > 1) {
      catalogProducts[index].orderQty -= 1;
      this.setState({ catalogProducts });
    }
  };

  onChangeQty = (e, index) => {
    let { catalogProducts } = this.state;
    catalogProducts[index].orderQty = Number(e.target.value);
    this.setState({ catalogProducts });
  };

  clearSerachText = () => {
    const { isShowMobileSearch } = this.state;
    if (isShowMobileSearch) this.setState({ isShowMobileSearch: false });
    this.setState({ searchText: '' });
    this.searchCatalogProducts('');
  };

  onSearchChange = (e) => {
    const searchText = e.target.value;
    this.setState({ searchText });
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      this.searchCatalogProducts(searchText);
    }, 1000);
  };

  searchCatalogProducts = async (searchText) => {
    let newList = [];
    searchText = searchText.trim().toLowerCase();
    const { allCatalogProducts } = this.state;
    if (!isEmpty(searchText)) {
      newList = allCatalogProducts.filter((x) => x?.name && x.name.trim().toLowerCase().includes(searchText));
    } else {
      newList = allCatalogProducts;
    }
    this.setState({ catalogProducts: newList });
  };

  onClickSearchedItem = (e, catalogName) => {
    e.stopPropagation();
    if (catalogName) {
      const url = `/catalogue/details/${window.btoa(catalogName)}`;
      this.props.history.push(url);
      this.setState({ searchText: '', searchedList: [] });
      this.loadData();
    }
  };

  openFilterModal = (e) => {
    this.setState({ isOpenFilterModal: true, anchorEl: e.currentTarget });
  };

  closeFilterModal = () => {
    this.setState({ isOpenFilterModal: false, anchorEl: null });
  };

  onApplyFilter = (filterData) => {
    if (filterData) {
      this.setState({ filterData }, () => {
        this.loadData();
        this.closeFilterModal();
      });
    }
  };

  onClearFilter = async () => {
    this.setState({ filterData: undefined }, () => {
      this.loadData();
      this.closeFilterModal();
    });
  };

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return (
        <ErrorWrap>
          <Message text={resSuccess} type={'success'} />
        </ErrorWrap>
      );
    }
  }

  onClickMobileSearch = () => {
    const { isShowMobileSearch } = this.state;
    this.setState({ isShowMobileSearch: !isShowMobileSearch });
  };

  renderOutOfStockProducts = () => {
    let { catalogProducts } = this.state;
    if (!catalogProducts || catalogProducts?.length === 0) return null;

    catalogProducts = catalogProducts?.map((x) => {
      if (!x?.deactivatedAt) x.deactivatedAt = new Date('1970');
      return x;
    });

    let products = groupBy(catalogProducts, 'deactivatedAt');
    products = products?.sort((a, b) => new Date(b?.id) - new Date(a?.id));

    return products?.map((group, index) => {
      const { customerSettings = {} } = this.props.customer;
      const { hide_price } = customerSettings.profile_settings || {};
      const isOlder = group?.id === new Date('1970').toString();
      const relative_time_label = isOlder ? 'Older' : moment(new Date(group?.id)).fromNow();
      return (
        <ContentBoxProducts key={`${group?.id}_${relative_time_label}`}>
          <div className='dateview' title={isOlder ? 'Older' : `${group?.id}`}>
            {relative_time_label}
          </div>
          <ContentBoxProductsList>
            {group?.products?.map((item) => {
              return (
                <ContentBoxCatalog className='outofstock' key={`index${index}_${item?.id}`}>
                  <ContentCatalog disabled={item?.status === PRODUCT_STATUS.INACTIVE && item?.qty <= 0}>
                    {item?.status === PRODUCT_STATUS.INACTIVE && item?.qty <= 0 && (
                      <OutList>
                        <Outofstock>Deactivated</Outofstock>
                      </OutList>
                    )}
                    <ContentTopCatalog>
                      <ProductImg src={item?.gallery.length > 0 && item.gallery[0].url} />
                    </ContentTopCatalog>
                    <ContentBottomCatalog>
                      <CatalougeName>{item?.name}</CatalougeName>
                      <ProductInfoCatalog>
                        {!hide_price && <Product>₹ {currencyWithDecimal(item?.price_sales)}</Product>}
                      </ProductInfoCatalog>
                    </ContentBottomCatalog>
                  </ContentCatalog>
                </ContentBoxCatalog>
              );
            })}
          </ContentBoxProductsList>
        </ContentBoxProducts>
      );
    });
  };

  render() {
    const { customerSettings = {} } = this.props.customer;
    const { hide_price } = customerSettings.profile_settings || {};
    const { catalogProducts, searchText, isShowMobileSearch, anchorEl, isOpenFilterModal, filterData } = this.state;
    const isOutOfStockProductPage = OUT_OF_STOCK_PRODUCTS === this.props.match.url;
    const catalogName = isOutOfStockProductPage ? 'Deactivated' : window.atob(this.props.match.params.product);
    const { loading } = this.props.catalog;
    return (
      <Layout expanded={SIDEBAR_MENU_ALL_CATALOGS}>
        <MainWrapper>
          <MainContent>
            <ProductNav>
              <LeftWrap>
                <MenuView onClick={this.toggleSideMenu}>
                  <MenuIcon />
                </MenuView>
                <CatName>{catalogName}</CatName>
              </LeftWrap>
              <RightWrap>
                {/* <Excel />
                <Pdf className='pdf' /> */}
                {isOutOfStockProductPage && (
                  <SvgIconWrap>
                    <FilterIconSvg onClick={this.openFilterModal}></FilterIconSvg>
                  </SvgIconWrap>
                )}
                <SearchView>
                  <SearchBox
                    value={searchText}
                    onSearchChange={this.onSearchChange}
                    clearSearch={this.clearSerachText}
                    placeholder={'Search Product'}
                    name={'search product'}
                  />
                </SearchView>
                <SearchIconView onClick={this.onClickMobileSearch}>
                  <SearchIcon />
                </SearchIconView>
                {isOutOfStockProductPage ? (
                  <AccountChartIconBlock>
                    <CSVLink
                      className='excelicon'
                      data={catalogProducts}
                      headers={outOfStockProductCSVHeaders}
                      filename={'Out_Of_Stock_Products.csv'}>
                      <ExcelIcon />
                    </CSVLink>
                  </AccountChartIconBlock>
                ) : (
                  <VerticalMoreIcon />
                )}
              </RightWrap>
            </ProductNav>
            {(!catalogProducts || catalogProducts?.length === 0) && loading ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <CatalogBlock>
                {isShowMobileSearch && (
                  <SerchWrap>
                    <SearchViewMobile>
                      <SearchInput
                        type='text'
                        name='search product'
                        value={searchText}
                        onChange={this.onSearchChange}
                        autoComplete='on'
                        placeholder={'Search Product'}></SearchInput>
                      <CloseIcon className='close' onClick={this.clearSerachText} />
                    </SearchViewMobile>
                  </SerchWrap>
                )}
                {this.renderSuccess()}
                <ContentWrapper>
                  {isOutOfStockProductPage ? (
                    this.renderOutOfStockProducts()
                  ) : (
                    <ContentBLockWrapper>
                      {catalogProducts?.length > 0 &&
                        catalogProducts.map((item, index) => {
                          const isPrebook =
                            (item?.qty === 0 && item?.status === PRODUCT_STATUS.ACTIVE) || item?.orderQty > item?.qty;
                          const now = moment(new Date());
                          const expiration = moment(item.manufacturing_estimate_date);
                          const diff = expiration.diff(now);
                          const diffDuration = moment.duration(diff);
                          return (
                            <ContentBoxCatalog key={`index${index}`}>
                              {item?.qty > 0 && <ProductReady>Ready to ship</ProductReady>}
                              <ContentCatalog disabled={item?.status === PRODUCT_STATUS.INACTIVE && item?.qty <= 0}>
                                {item?.status === PRODUCT_STATUS.INACTIVE && item?.qty <= 0 && (
                                  <OutList>
                                    <Outofstock>Deactivated</Outofstock>
                                  </OutList>
                                )}
                                <ContentTopCatalog>
                                  <ProductImg src={item?.gallery.length > 0 && item.gallery[0].url} />
                                  {item.isShowOrderButton && (
                                    <>
                                      <ButtonWrapper className={'hideshow'}>
                                        <Button
                                          small
                                          isPrimary={true}
                                          title={'Order Now'}
                                          disabled={item?.status === PRODUCT_STATUS.INACTIVE && item?.qty <= 0}
                                          onClick={(e) => {
                                            this.onClickOrderButton(index);
                                          }}></Button>
                                        {(item?.status === PRODUCT_STATUS.ACTIVE || item?.qty > 0) && (
                                          <MobileIcon
                                            className={isPrebook && 'prebook'}
                                            onClick={(e) => {
                                              this.onClickOrderButton(index);
                                            }}>
                                            <CartIcon />
                                          </MobileIcon>
                                        )}
                                      </ButtonWrapper>
                                    </>
                                  )}
                                  {item?.is_in_manufacturing &&
                                    item?.qty <= 0 &&
                                    item?.status === PRODUCT_STATUS.ACTIVE && (
                                      <>
                                        {!isEmpty(diffDuration) && <Timer expirationDate={expiration} product={item} />}
                                      </>
                                    )}
                                  {item?.isShowPlaceOrderButton && (
                                    <ButtonWrap className='product-catalog'>
                                      <LeftWrapper>
                                        {/* <Button
                                          isPrimary={true}
                                          title={'-'}
                                          createbtn
                                          onClick={() => {
                                            this.onClickMinusQty(index);
                                          }}
                                        /> */}
                                        <LButton
                                          isPrimary={true}
                                          onClick={() => {
                                            this.onClickMinusQty(index);
                                          }}>
                                          <MinusIcon />
                                        </LButton>
                                        <Input
                                          min={0}
                                          type={'number'}
                                          value={item?.orderQty}
                                          onChange={(e) => {
                                            this.onChangeQty(e, index);
                                          }}
                                        />
                                        <LButton
                                          isPrimary={true}
                                          onClick={() => {
                                            this.onClickPlusQty(index);
                                          }}>
                                          <PlusIcon />
                                        </LButton>
                                        {/* <Button
                                          isPrimary={true}
                                          title={'+'}
                                          createbtn
                                          onClick={() => {
                                            this.onClickPlusQty(index);
                                          }}
                                        /> */}
                                      </LeftWrapper>
                                      <AddView>
                                        <Button
                                          small
                                          disabled={item?.status === PRODUCT_STATUS.INACTIVE && item?.qty <= 0}
                                          isPrimary={true}
                                          title={isPrebook ? 'Pre Book' : item?.qty > 0 ? 'Add to cart' : ''}
                                          onClick={(e) => {
                                            this.onClickAddToCart(e, item, index);
                                          }}
                                        />
                                      </AddView>
                                      <AddCartView
                                        className={isPrebook && 'prebook'}
                                        onClick={(e) => {
                                          this.onClickAddToCart(e, item, index);
                                        }}>
                                        <AddCartIcon />
                                      </AddCartView>
                                    </ButtonWrap>
                                  )}
                                </ContentTopCatalog>
                                <ContentBottomCatalog>
                                  <CatalougeName>{item.name}</CatalougeName>
                                  <ProductInfoCatalog>
                                    {!hide_price && <Product>₹ {currencyWithDecimal(item.price_sales)}</Product>}
                                    {/* <Product>
                                        C.Stock: <RightSpan>{mathRound(item.qty)}</RightSpan>
                                      </Product> */}
                                  </ProductInfoCatalog>
                                </ContentBottomCatalog>
                              </ContentCatalog>
                            </ContentBoxCatalog>
                          );
                        })}
                    </ContentBLockWrapper>
                  )}
                </ContentWrapper>
                {loading && (!catalogProducts || catalogProducts.length === 0) && (
                  <EmptyContain>
                    <EmptyDetail>
                      <EmptyImage src={EmptyLogo} />
                      <TextEmpty>No Catalog Product Found</TextEmpty>
                      <TextDetail>
                        We don't want to boast too much, but sending amazing catalog product and getting paid is easier
                        than ever. Go ahead! Try it yourself.
                      </TextDetail>
                    </EmptyDetail>
                  </EmptyContain>
                )}
              </CatalogBlock>
            )}
          </MainContent>
          <StyledMenu id='customized-menu' anchorEl={anchorEl} open={isOpenFilterModal} onClose={this.closeFilterModal}>
            <FilterModalItem onApply={this.onApplyFilter} filterData={filterData} onClear={this.onClearFilter} />
          </StyledMenu>
        </MainWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  catalog: state.catalog,
  cart: state.cart,
  customer: state.customer
});

export default connect(mapStateToProps, {
  getCatalogList,
  toggleSideMenu,
  addToCart,
  setSuccessMessage
})(CatalogProducts);
