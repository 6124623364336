import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { randomColorsArray } from '../../data/raw';
import { isEmpty } from '../../helper/common';
import { Normaltext, flexcenter } from '../../font-variable';

const UserLogoWrapper = styled.div`
  ${flexcenter};
  margin-right: 10px;
`;
const UserLogo = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 28px;
  ${flexcenter};
  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
      border-radius: ${props.size / 2}px;
      border: 1px solid var(--secondary-border-color);
      object-fit: contain;
    `}
`;
const TextWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 28px;
  ${flexcenter};
  color: white;
  background-color: ${(props) => props.randColor};
  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
      border-radius: ${props.size / 2}px;
    `}
  span {
    ${Normaltext};
  }
`;
class Avtar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getAvtarName = (name) => {
    if (isEmpty(name)) {
      return '#';
    }
    let splitedName = name.trim().split(' ');
    if (splitedName.length === 1) {
      return this.getChar(name);
    } else {
      if (splitedName[1].trim() === '') {
        return this.getChar(name);
      } else {
        return this.getChar(splitedName[0]) + this.getChar(splitedName[1]);
      }
    }
  };

  getChar = (value) => {
    return value.charAt(0).toUpperCase();
  };

  getAsciiChar = (name) => {
    return this.getChar(name).charCodeAt();
  };

  getColorIndex = (name) => {
    const asciiValue = this.getAsciiChar(name);
    // difference of ascii val from base value 64,
    // & it's divided by array's length. So, that we get number between the array's index
    // To stop blinking of bgColor on any changes
    const colorIndex = (asciiValue - 64) % (randomColorsArray.length - 1);
    return colorIndex;
  };

  render() {
    const { imgSrc, size, name } = this.props;
    const avtarName = this.getAvtarName(name);
    let colorIndex = this.getColorIndex(avtarName);
    var randColor = randomColorsArray[colorIndex];
    return (
      <UserLogoWrapper>
        {imgSrc ? (
          <UserLogo size={size} src={imgSrc} />
        ) : (
          <TextWrapper size={size} randColor={randColor}>
            <span>{avtarName}</span>
          </TextWrapper>
        )}
      </UserLogoWrapper>
    );
  }
}

export default Avtar;
