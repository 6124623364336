import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout/Layout';
import { connect } from 'react-redux';
import { ReactComponent as MenuIcon } from '../../assets/images/svg/menuicon.svg';
// import { ReactComponent as VerticalMoreIcon } from '../../assets/images/svg/Verticalmore.svg';
import { ReactComponent as AnnounceIcon } from '../../assets/images/svg/announcement.svg';
import { toggleSideMenu } from '../../actions/SideMenuAction';
import Overview from './Overview';
import TopProductsSold from './TopProductsSold';
import NewCatalogs from './NewCatalogs';
import TotalSalesAndBill from './TotalSalesAndBill';
import Message from '../../components/Message';
import TodaysActivity from './TodaysActivity';
import TotalOrders from './TotalOrders';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getAnnouncementList } from '../../services/dashboardService';
import { Headertitle, Smalltext, Textdotdot, flex, flexspacebetween } from '../../font-variable';

const MainWrapper = styled.div`
  height: 100%;
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  z-index: 2;
  background: var(--secondary-color);
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const AnnouncementWrap = styled.div`
  padding: 0 24px 24px;
  @media (max-width: 767px) {
    padding: 0 16px 16px;
  }
`;

const AnnouncementBox = styled.div`
  width: 100%;
  padding: 8px 40px;
  ${flexspacebetween};
  background: var(--annocement-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 6px;
  gap: 20px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px;
    gap: 12px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const AnnouncementIcon = styled.div`
  position: relative;
  top: 12px;
`;

const AnnouncementText = styled.div`
  ${Headertitle};
  color: var(--black-light-color);
  margin: 0 20px 0 30px;
  @media (max-width: 767px) {
    margin: 16px 0 0;
    font-size: 18px;
    text-align: center;
  }
`;

const AnnouncementLink = styled.div`
  ${Smalltext};
  color: var(--primary-color);
  cursor: pointer;
  :hover {
    text-decoration-line: underline;
  }
`;

const CatName = styled.div`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
`;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
  }
`;

// const RightWrap = styled.div`
//   display: flex;
//   align-items: center;
//   .pdf {
//     margin: 0 5px;
//   }
// `;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const MainContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BoxWrap = styled.div`
  display: grid;
  gap: 22px;
  padding: 0 24px 24px;
  grid-template-columns: repeat(auto-fit, minmax(535px, 1fr));
  width: 100%;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 16px;
    gap: 16px;
  }
`;

const DashDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
`;

const LeftView = styled.div`
  ${flex};
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const ErrorWrap = styled.div``;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      await this.props.getAnnouncementList(companyId, true);
    }
  };

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return (
        <ErrorWrap>
          <Message text={resError} type={'error'} />
        </ErrorWrap>
      );
    }
  }

  onViewAllAnnouncements = () => {
    this.props.history.push('/view/announcement');
  };

  render() {
    const { announcementList = [] } = this.props.dashboard;
    return (
      <Layout>
        <MainWrapper>
          <MainContent>
            <ProductNav>
              <LeftWrap>
                <MenuView onClick={this.toggleSideMenu}>
                  <MenuIcon />
                </MenuView>
                <CatName>Dashboard</CatName>
              </LeftWrap>
              {/* <RightWrap>
                <VerticalMoreIcon />
              </RightWrap> */}
            </ProductNav>
            {this.renderErrors()}
            <DashDetail>
              {announcementList?.length > 0 && (
                <AnnouncementWrap>
                  <AnnouncementBox>
                    <LeftView>
                      <AnnouncementIcon>
                        <AnnounceIcon />
                      </AnnouncementIcon>
                      <AnnouncementText>Here some important announcement for all of you</AnnouncementText>
                    </LeftView>
                    <AnnouncementLink onClick={this.onViewAllAnnouncements}>
                      View all Announcement ({announcementList?.length})
                    </AnnouncementLink>
                  </AnnouncementBox>
                </AnnouncementWrap>
              )}
              <Overview />
              <BoxWrap>
                <TodaysActivity history={this.props.history} />
                <TotalSalesAndBill />
                <TopProductsSold history={this.props.history} />
                <NewCatalogs history={this.props.history} />
              </BoxWrap>
              <TotalOrders />
            </DashDetail>
          </MainContent>
        </MainWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  dashboard: state.dashboard
});

export default connect(mapStateToProps, { toggleSideMenu, getAnnouncementList })(Dashboard);
