import * as Actions from './types';

/**
 * @desc Set Order List Loader
 */
export const setOrderListLoader = (payload) => {
  return {
    type: Actions.SET_ORDER_LIST_LOADER,
    payload
  };
};

/**
 * @desc Set pending order List
 */
export const setPendingOrderList = (payload) => {
  return {
    type: Actions.SET_PENDING_ORDER_LIST,
    payload
  };
};

/**
 * @desc Set cancel order List
 */
export const setCancelOrderList = (payload) => {
  return {
    type: Actions.SET_CANCEL_ORDER_LIST,
    payload
  };
};

/**
 * @desc Set Invoiced order List
 */
export const setInvoicedOrderList = (payload) => {
  return {
    type: Actions.SET_INVOICED_ORDER_LIST,
    payload
  };
};

/**
 * @desc update Order Filtered List
 */
export const updateOrderFilteredList = (payload) => {
  return {
    type: Actions.UPDATE_ORDER_LIST_FILTERED_LIST,
    payload
  };
};
/**
 * @desc Clear order List data
 */
export const clearOrderListData = () => {
  return {
    type: Actions.CLEAR_ORDER_LIST_DATA
  };
};
