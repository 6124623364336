import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';
import { Menu } from '@material-ui/core';
import { Mediumtext, Smalltext, Textdotdot, flex, flexcenter, flexspacebetween } from '../../font-variable';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const MainNav = styled.div`
  height: 100vh;
  width: 210px;
  float: left;
  background-color: var(--background-color);
  padding: 10px 0 10px 8px;
  border: 1px solid var(--secondary-border-color);
  position: relative;
  @media (max-width: 767px) {
    position: absolute;
    z-index: 111;
    width: 300px;
    ${(props) =>
      props.isShowLayout
        ? css`
            right: 100%;
          `
        : css`
            // right: 100%;
          `}
  }
`;

const Logodropdown = styled.div`
  ${flexspacebetween};
  gap: 4px;
  pointer-events: none;
  margin: 0 8px 10px 0px;
`;

const Accordion = withStyles({
  root: {
    border: '0px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },

  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    padding: '6px 15px',
    margin: '0',
    borderRadius: '6px',
    minHeight: '32px',

    '&$expanded': {
      minHeight: 'auto'
    }
  },
  content: {
    margin: 0,
    alignItems: 'center',

    '&$expanded': {
      margin: 0
    }
  },

  expandIcon: {
    order: 0,
    margin: 0,
    padding: 0
  },

  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    margin: '0 16px 0 27px',
    padding: 0
  }
})(MuiAccordionDetails);

const TypographyText = withStyles({
  root: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '16px',
    color: 'var(--black-light-color)',
    marginLeft: '10px'
  }
})(Typography);

const TypographySubText = withStyles({
  root: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: '500',
    color: 'var(--black-light-color) !important'
  }
})(Typography);

const LogoWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    position: relative;
  }
`;

const CloseView = styled.div`
  display: none;
  @media (max-width: 767px) {
    position: absolute;
    top: 9px;
    right: 9px;
    left: auto;
    display: block;
  }
`;

const Navbar = styled.div`
  padding: 0px;
`;

const AccordionWrap = styled(Accordion)`
  background-color: transparent;
  margin: 0px;
  box-shadow: none;
  padding: 0px;
  &.Mui-expanded {
    margin: 0;
    svg {
      path {
        fill: var(--primary-color);
      }
    }
    p {
      color: var(--primary-color);
    }
  }
  &.MuiAccordion-root::before {
    background-color: transparent;
  }
`;

const MenuItemLink = styled(NavLink)`
  &.active_menu {
    ${Smalltext};
    ${flex};
    border-radius: 6px;
    p {
      color: var(--primary-color) !important;
      :hover {
        color: var(--primary-color) !important;
      }
    }
    ::before {
      content: '';
      border: 2.5px solid var(--primary-color);
      border-radius: 50px;
      display: inline-block;
      position: absolute;
      left: 22px;
    }
  }
`;

const AccordionSummaryWrap = styled(AccordionSummary)`
  padding: 0px;
  .MuiAccordionSummary-content {
    :hover {
      svg path {
        fill: var(--primary-color);
      }
      p {
        color: var(--primary-color);
      }
    }
  }
`;

const AccordionDetailsList = styled(AccordionDetails)`
  margin: 0 16px 0 27px !important;
  padding: 0;
  a {
    padding: 6px 16px;
    width: 100%;
    ${Smalltext};
    color: var(--grey-color);
    margin: 2px 0;
    :hover {
      border-radius: 6px;
      text-decoration: none;
      color: var(--primary-color) !important;
    }
    p {
      text-decoration: none;
      :hover {
        text-decoration: none;
        color: var(--primary-color) !important;
      }
    }
  }
`;

const ContactView = styled.div`
  ${flex};
  cursor: pointer;
`;

const BottomView = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileView = styled.div`
  ${flex};
  cursor: pointer;
  pointer-events: auto;
  padding: 6px 10px;
  border-radius: 6px;
  :hover {
    background: var(--background-hover-color);
  }
`;

const RightSvg = styled.div`
  margin-left: auto;
  display: inline-flex;
`;

const ProfileNav = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 18px;
  ${Mediumtext};
  color: var(--black-light-color);
  background-color: var(--background-color);
`;

const DisplayName = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  text-align: left;
  ${Textdotdot};
`;

const ProfileNameBlock = styled.div`
  border-bottom: 1px solid var(--secondary-border-color);
  padding-bottom: 12px;
  margin: 0 10px 16px;
`;

const ProfileNameBlock2 = styled.div`
  border-bottom: 1px solid var(--secondary-border-color);
  padding-bottom: 12px;
  margin: 0 10px 6px;
  ${(props) =>
    props.last &&
    css`
      padding-bottom: 10px;
      margin-bottom: 0;
      border: 0;
    `}
`;

const ProfileName = styled.h2`
  ${Mediumtext};
  color: var(--header-title-color);
  margin: 0;
  padding-bottom: 4px;
`;

const ProfileEmail = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  overflow-wrap: break-word;
`;

const Menulist = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
  display: block;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 4px;
  text-transform: capitalize;
  :hover {
    background: var(--background-dropdown);
  }
`;

const Overlay = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`;

const AccordionCart = styled.div``;

const CardtNum = styled.span`
  ${flexcenter};
  color: var(--text-color);
  background-color: var(--cart-text-color);
  width: 16px;
  height: 16px;
  border-radius: 50px;
  position: absolute;
  left: 26px;
  top: 1px;
  span {
    font-size: 10px;
  }
`;

const LabelName = styled.h6`
  ${Mediumtext};
  color: var(--header-title-color);
  margin: 0;
`;

const LabelValue = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  word-break: break-word;
  display: block;
  margin-top: 4px;
`;

const StyledMenu = withStyles({
  paper: {
    border: '1px solid var(--secondary-border-color)',
    padding: '12px 6px 6px 6px',
    background: 'var(--secondary-color)',
    boxShadow: 'var(--box-shadow) !important',
    borderRadius: '6px',
    width: '190px',
    marginBottom: '4px',
    marginTop: '38px',
    marginLeft: '-7px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    {...props}
  />
));

const StyledMenu2 = withStyles({
  paper: {
    border: '1px solid var(--secondary-border-color)',
    padding: '12px 6px 6px 6px',
    background: 'var(--secondary-color)',
    boxShadow: 'var(--box-shadow) !important',
    borderRadius: '6px',
    width: '190px',
    marginBottom: '4px',
    marginTop: '-4px',
    marginLeft: '-7px',
    zIndex: '9999'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    {...props}
  />
));

export {
  Wrapper,
  AccordionWrap,
  LogoWrap,
  Navbar,
  MenuItemLink,
  AccordionSummaryWrap,
  TypographyText,
  MainNav,
  TypographySubText,
  AccordionDetailsList,
  AccordionCart,
  ProfileNav,
  DisplayName,
  StyledMenu,
  StyledMenu2,
  Menulist,
  ProfileEmail,
  ProfileName,
  ProfileNameBlock,
  CloseView,
  Overlay,
  CardtNum,
  ContactView,
  BottomView,
  ProfileView,
  RightSvg,
  LabelName,
  LabelValue,
  ProfileNameBlock2,
  Logodropdown
};
