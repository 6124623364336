import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { getNewCatalogs } from '../../services/dashboardService';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import ReportDateFilter from '../../components/ReportDateTimeFilter/ReportDateFilter';
import Loading from '../../components/Loading/Loading';
import { addDaysToDate, currencyWithDecimal, isDev } from '../../helper/common';
import { ReactComponent as AvtarIcon } from '../../assets/images/svg/avtardemo.svg';
import { getCatalogList } from '../../services/catalogService';
import { setNewCatalogs } from '../../actions/dashboardActions';
import { Mediumtext, Normaltext, Smalltext, flexcenter, flexspacebetween } from '../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MinContact = styled.div`
  position: relative;
  width: 100%;
  /* ${(props) =>
    !props.isDev &&
    css`
      padding: 0 15px 30px 30px;
    `} */
`;

const ShotcutDiv = styled.div`
  width: 100%;
  height: 474px;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const HeadTitle = styled.div`
  ${Smalltext};
  color: var(--black-light-color);
  margin: 0;
`;

const Pcs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SpanPcs = styled.span`
  ${Normaltext};
  color: var(--grey-color);
  background: var(--background-dropdown);
  border-radius: 30px;
  margin: 0 0 0 4px;
  padding: 2px 8px;
`;

const TabelHead = styled.span`
  ${Mediumtext};
  color: var(--black-light-color);
  font-weight: 600;
  display: block;
  white-space: nowrap;
  margin: 0;
`;

const Nodata = styled.div`
  ${flexcenter};
  ${Mediumtext};
  font-weight: 600;
  color: var(--black-light-color);
  height: 100%;
`;

const TableIt = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Normaltext};
  color: var(--grey-color);
  background: var(--secondary-color);
  text-align: left;
  text-transform: capitalize;
  padding: 8px 8px 12px;
  position: sticky;
  top: 0;
  z-index: 99;
  &:first-child {
    padding-left: 0;
    width: 0%;
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
    text-align: right;
  }
  &:nth-child(4) {
    text-align: right;
  }
  &:last-child {
    text-align: right;
    padding-right: 0;
    cursor: pointer;
    > div {
      justify-content: flex-end;
    }
  }
`;

const Td = styled.td`
  ${Smalltext};
  color: var(--black-light-color);
  padding: 12px 8px;
  white-space: nowrap;
  text-transform: capitalize;
  border-top: 1px solid var(--border-color);
  &:first-child {
    padding-left: 0;
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
    text-align: right;
  }
  &:nth-child(4) {
    text-align: right;
  }
  &:last-child {
    text-align: right;
    padding-right: 0;
    cursor: pointer;
  }
`;

const MainTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
  padding: 0px 16px;
  @media (max-width: 767px) {
    padding: 0 12px;
  }
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  gap: 16px;
  position: relative;
  z-index: 0;
  padding: 16px 16px;
  margin-bottom: -4px;
  @media (max-width: 767px) {
    padding: 16px 12px;
  }
`;

const MainTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const PlaceHoldImage = styled.div`
  width: 44px;
  height: 48px;
  border-radius: 4px;
  background: var(--background-dropdown);
  border: 1px solid var(--border-color);
  ${flexcenter};
`;

const PlaceImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-width: 100%;
`;

class NewCatalogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      option: 4,
      //Last 30 Days,Today included so -29
      fromDate: addDaysToDate(new Date(), -29),
      toDate: new Date()
    };
  }

  onGetNewCatalogs = async () => {
    const { fromDate, toDate } = this.state;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (!companyId) return;
    this.setState({ isLoading: true });
    await this.props.getCatalogList(companyId);
    await this.props.getNewCatalogs(companyId, fromDate, toDate);
    const { newCatalogs } = this.props.dashboard;

    if (newCatalogs?.length > 0) {
      let { catalogList = [] } = this.props.catalog;
      const newCatalogsList = [];

      newCatalogs?.forEach((catalog) => {
        const products = catalogList?.filter((x) => x?.catalogue === catalog);
        let newCatalog = {};
        const productsWithImage = products?.filter((x) => x?.gallery?.length > 0);
        if (productsWithImage?.length > 0) newCatalog.image_url = productsWithImage?.[0]?.gallery?.[0]?.url;
        newCatalog.name = catalog;
        newCatalog.total_cost = products?.reduce((sum, val) => sum + Number(val?.price_sales || 0), 0);
        newCatalog.total_products = products?.length;
        newCatalog.avg_cost = newCatalog.total_cost / newCatalog.total_products;
        newCatalogsList.push(newCatalog);
      });
      this.props.setNewCatalogs(newCatalogsList);
    }
    this.setState({ isLoading: false });
  };

  onChange = async (option, fromDate, toDate) => {
    const fromDateString = moment(fromDate).format('YYYY-MM-DD');
    const toDateString = moment(toDate).format('YYYY-MM-DD');
    this.setState({ fromDate: fromDateString, toDate: toDateString }, () => {
      this.onGetNewCatalogs();
    });
  };

  onClickCatalog = (catalogName) => {
    const url = `/catalogue/details/${window.btoa(catalogName)}`;
    if (catalogName) this.props.history.push(url);
  };

  render() {
    const { isLoading, option, fromDate, toDate } = this.state;
    const { newCatalogs } = this.props.dashboard;
    return (
      <>
        <MinContact isDev={isDev}>
          <ShotcutDiv>
            <HeaderWrapper>
              <TabelHead>New Catalogs</TabelHead>
              <ReportDateFilter
                option={option}
                fromDate={fromDate}
                toDate={toDate}
                onChange={this.onChange}
                loading={isLoading}
                backgroundColor={'#f9f9f9'}
              />
            </HeaderWrapper>
            {!isLoading && (!newCatalogs || newCatalogs?.length === 0) && <Nodata> No Data Found</Nodata>}
            {isLoading && (!newCatalogs || newCatalogs?.length === 0) ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <>
                {newCatalogs?.length > 0 && (
                  <MainTab>
                    <MainTable>
                      <TableIt>
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Product Name</Th>
                            <Th>Avg. Cost</Th>
                            <Th>Set Cost</Th>
                            <Th>Pieces</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {newCatalogs &&
                            newCatalogs?.length > 0 &&
                            newCatalogs?.map((item, i) => {
                              return (
                                <Tr key={i}>
                                  <Td>
                                    <PlaceHoldImage>
                                      {item?.image_url ? (
                                        <PlaceImage src={item?.image_url} alt='gallery' />
                                      ) : (
                                        <AvtarIcon />
                                      )}
                                    </PlaceHoldImage>
                                  </Td>
                                  <Td>
                                    <HeadTitle
                                      onClick={() => {
                                        this.onClickCatalog(item?.name);
                                      }}>
                                      {item?.name}
                                    </HeadTitle>
                                  </Td>
                                  <Td>₹ {currencyWithDecimal(item?.avg_cost)}</Td>
                                  <Td>₹ {currencyWithDecimal(item?.total_cost)}</Td>
                                  <Td>
                                    <Pcs>
                                      {item?.total_products}
                                      <SpanPcs>Pcs</SpanPcs>
                                    </Pcs>
                                  </Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </TableIt>
                    </MainTable>
                  </MainTab>
                )}
              </>
            )}
          </ShotcutDiv>
        </MinContact>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  catalog: state.catalog
});

export default connect(mapStateToProps, {
  getNewCatalogs,
  getCatalogList,
  setNewCatalogs
})(NewCatalogs);
