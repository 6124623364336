const randomColorsArray = ['#4fcb71', '#f5a623', '#3d5af1', '#50e3c2', '#9B9B9B', '#4a90e2', '#ff7c73', '#FF2C54'];

const itemPerPage = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
];

const outOfStockProductCSVHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Catalog', key: 'catalogue' },
  { label: 'SKU', key: 'sku' },
  { label: 'Price', key: 'price_sales' },
  { label: 'Deactivated On', key: 'deactivatedAt' },
  { label: 'Image', key: 'gallery[0].url' }
];

const ledgerReportCSVHeaders = [
  { label: 'Date', key: 'date' },
  { label: 'Number', key: 'number' },
  { label: 'Description', key: 'description' },
  { label: 'Debit', key: 'debit' },
  { label: 'Credit', key: 'credit' }
];

export { randomColorsArray, itemPerPage, outOfStockProductCSVHeaders, ledgerReportCSVHeaders };
