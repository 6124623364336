import React, { Component } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/closeicon.svg';
import { HeaderBlock, ItemLeft, HeaderHeading, ItemWrap } from '../Announcement/style';
import styled, { css } from 'styled-components';
import { ReactComponent as AvtarIcon } from '../../assets/images/svg/avtardemo.svg';
import { connect } from 'react-redux';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getCatalogList } from '../../services/catalogService';
import { Mediumtext, Smalltext, flexcenter, flexspacebetween } from '../../font-variable';

const TableIt = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Smalltext};
  color: var(--grey-color);
  text-align: left;
  padding: 1px 8px 8px;
  text-transform: capitalize;
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 0;
  background: var(--secondary-color);
  &:first-child {
    width: 48px;
  }
  &:nth-child(2) {
  }
  &:last-child {
    text-align: center;
  }
  ${(props) => {
    return (
      props?.isShowEstimateDate &&
      css`
        &:nth-child(2) {
        }
        &:nth-child(3) {
        }
        &:last-child {
          text-align: center;
        }
      `
    );
  }}
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border-bottom: 1px solid var(--border-color);
  padding: 12px 8px;
  white-space: nowrap;
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:last-child {
    text-align: center;
  }
  ${(props) => {
    return (
      props?.isShowEstimateDate &&
      css`
        &:nth-child(2) {
        }
        &:nth-child(3) {
        }
        &:last-child {
          text-align: center;
        }
      `
    );
  }}
`;

const SaleWrapper = styled.div`
  background: var(--secondary-color);
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 500px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  ${flexspacebetween};
  @media (max-width: 767px) {
    max-width: 450px;
  }
  @media (max-width: 470px) {
    max-width: 360px;
  }
`;

const SaleModalWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  position: relative;
  z-index: 99;
  padding: 12px 18px;
  margin-bottom: 18px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid var(--secondary-border-color);
  ${(props) =>
    props.mobile
      ? css`
          padding: 20px;
        `
      : css`
          /* padding: 20px; */
        `}
`;

const OverViewTabItemWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: calc(100% - 64px);
  overflow: auto;
  margin-left: 18px;
`;

const HeadTitle = styled.div`
  display: grid;
  grid-gap: 2px;
`;

const SpanName = styled.h4`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0;
  cursor: pointer;
`;

const PlaceHoldImage = styled.div`
  width: 44px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--background-dropdown);
  ${flexcenter};
`;

const PlaceImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-width: 100%;
`;

class OrderListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    await this.props.getCatalogList(companyId);
  };

  onClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  onClickProductName = (productId) => {
    let { catalogList = [] } = this.props.catalog;
    const product = catalogList?.find((x) => x?.id === productId);
    let catalogue;
    if (product) catalogue = product?.catalogue;
    const url = `/catalogue/details/${window.btoa(catalogue)}`;
    if (catalogue) this.props.history.push(url);
  };

  render() {
    const { orderList, title } = this.props;
    const isShowEstimateDate = title === 'Delayed Orders';
    return (
      <>
        <SaleWrapper>
          <SaleModalWrap>
            <HeaderWrapper>
              <HeaderBlock>
                <ItemLeft>
                  <HeaderHeading>{title}</HeaderHeading>
                </ItemLeft>
                <ItemWrap>
                  <CloseIcon onClick={this.onClose} />
                </ItemWrap>
              </HeaderBlock>
            </HeaderWrapper>
            <OverViewTabItemWrapper>
              <TableIt>
                <Thead>
                  <Tr isShowEstimateDate={isShowEstimateDate}>
                    <Th></Th>
                    <Th>Product Name</Th>
                    {isShowEstimateDate && <Th>Estimate Date</Th>}
                    <Th>Qty</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orderList?.length > 0 &&
                    orderList?.map((item, index) => {
                      const { product_id, product_image, product_name, qty, estimate_date_formatted } = item || {};
                      return (
                        <Tr key={index} isShowEstimateDate={isShowEstimateDate}>
                          <Td>
                            <PlaceHoldImage>
                              {product_image?.[0]?.url ? (
                                <PlaceImage src={product_image?.[0]?.url} alt='gallery' />
                              ) : (
                                <AvtarIcon />
                              )}
                            </PlaceHoldImage>
                          </Td>
                          <Td>
                            <HeadTitle>
                              <SpanName
                                onClick={() => {
                                  this.onClickProductName(product_id);
                                }}>
                                {product_name}
                              </SpanName>
                            </HeadTitle>
                          </Td>
                          {isShowEstimateDate && <Td>{estimate_date_formatted}</Td>}
                          <Td>{qty}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableIt>
            </OverViewTabItemWrapper>
          </SaleModalWrap>
        </SaleWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  catalog: state.catalog
});

export default connect(mapStateToProps, { getCatalogList })(OrderListModal);
