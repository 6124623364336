import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import {
  Headertitle,
  Mediumtext,
  Normaltext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween}
`;

const EmptyContain = styled.div`
  ${flexcenter};
  height: 100%;
`;

const EmptyDetail = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
`;

const EmptyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextEmpty = styled.h3`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 10px 0 0;
  text-transform: capitalize;
`;

const TextDetail = styled.p`
  ${Smalltext};
  color: var(--grey-color);
  text-align: center;
  margin: 10px 0;
`;

const MainTable = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
  margin-left: 24px;
  @media (max-width: 767px) {
    margin-top: 8px;
  }
`;

const MainTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  height: 100%;
  &.table-space {
    padding-bottom: 24px;
  }
`;

const TableIt = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Smalltext};
  color: var(--grey-color);
  text-align: left;
  padding: 1px 8px 8px;
  text-transform: capitalize;
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 0;
  background: var(--secondary-color);
  &:first-child {
  }
  &:nth-child(5) {
    text-align: right;
  }
  &:last-child {
    text-align: right;
    padding-right: 24px;
    cursor: pointer;
  }
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border-bottom: 1px solid var(--border-color);
  padding: 16px 8px;
  white-space: nowrap;
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:nth-child(3) {
  }
  &:nth-child(5) {
    text-align: right;
    div {
      justify-content: flex-end;
    }
  }
  &:last-child {
    cursor: pointer;
    text-align: right;
    padding-right: 24px;
    div {
      justify-content: flex-end;
    }
  }
`;

const AvtarSupplier = styled.div`
  ${flex};
`;

const HeadTitle = styled.div`
  display: grid;
  grid-gap: 2px;
  ${Mediumtext};
  color: var(--black-light-color);
  text-transform: capitalize;
`;

const InvoiceNub = styled.p`
  ${Normaltext};
  color: var(--grey-color);
  margin: 0;
  cursor: pointer;
  span {
    margin-left: 2px;
  }
`;

const AmountDiv = styled.div`
  ${Mediumtext};
  color: var(--black-light-color);
  ${flex};
  svg {
    margin-left: 8px;
  }
`;

const AmountTag = styled.span`
  ${Mediumtext};
  color: var(--black-light-color);
  white-space: nowrap;
`;

const WrapperPlace = styled.div`
  ${flex};
`;

const SpanTag = styled.div`
  ${Mediumtext};
  color: var(--black-light-color);
  ${flex};
  margin: 1px 0;
  p {
    margin: 0;
  }
  ${(props) =>
    !props.isInvoiceLabel &&
    css`
      cursor: pointer;
    `}
`;

const AmountNub = styled.span`
  margin-left: 10px;
  svg {
    vertical-align: middle;
  }
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const CatName = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const SearchInput = styled.input``;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    ${flex};
  }
`;

const RightWrap = styled.div`
  ${flex};
  gap: 15px;
  position: relative;
  .pdf {
    margin: 0 5px;
  }
  a {
    ${flexcenter};
    border: 1px solid var(--secondary-border-color);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    :hover {
      border: 1px solid var(--input-border-hover-color);
    }
  }
`;

const MainWrapper = styled.div`
  height: 100%;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 0;
  overflow: auto;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const PlaceHoldImage = styled.div`
  width: 44px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--background-dropdown);
  ${flexcenter};
`;

const PlaceImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-width: 100%;
`;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
  border-radius: 6px 6px 0 0;
  position: sticky;
  top: 0px;
  z-index: 99;
  padding: 9px 14px;
  margin-bottom: 18px;
`;

const ItemLeft = styled.div``;

const HeaderHeading = styled.h2`
  ${Mediumtext};
  color: var(--header-title-color);
  margin: 0;
`;

const CloseView = styled.div`
  cursor: pointer;
  svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
`;

const SkuModal = styled.div`
  background: var(--secondary-color);
  width: 100%;
  max-width: 560px;
  border-radius: 4px;
  position: absolute;
  outline: none;
  left: 50%;
  top: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`;

const SkuRow = styled.div`
  ${flexspacebetween};
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 14px 14px;
`;

const SkuDetails = styled.div`
  width: 100%;
  max-width: 280px;
`;

const SkuText = styled.div`
  margin-bottom: 15px;
  width: 100%;
  ${flex};
`;

const LText = styled.span`
  ${Smalltext};
  color: var(--grey-color);
  width: 100%;
  max-width: 124px;
`;

const RText = styled.span`
  ${Smalltext};
  color: var(--black-light-color);
`;

const ImgSku = styled.div`
  margin-left: 24px;
  width: 220px;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: var(--placeholder-color);
    border-radius: 4px;
  }
  @media (max-width: 767px) {
    height: 260px;
  }
`;

const SearchView = styled.div`
  display: block;
  input {
    ${Smalltext};
    background: var(--secondary-color);
    border: 1px solid var(--secondary-border-color);
    color: var(--black-light-color);
    margin-right: 0px;
    padding: 7px 10px;
    border-radius: 4px;
    outline: 0;
    ::placeholder {
      color: var(--placeholder-color);
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const SearchIconView = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: inline-flex;
    margin-right: 5px;
  }
`;

const SerchWrap = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    .close {
      position: absolute;
      right: 26px;
      z-index: 11;
      top: 8px;
      vertical-align: middle;
    }
  }
`;

const SearchViewMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    input {
      ${Smalltext};
      margin-right: 0px;
      color: var(--black-light-color);
      background: var(--secondary-color);
      border: 1px solid var(--secondary-border-color);
      border-radius: 4px;
      outline: 0;
      margin: 4px 24px;
      padding: 8px 10px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 11;
      ::placeholder {
        color: var(--placeholder-color);
      }
    }
  }
  @media (max-width: 767px) {
    input {
      margin: 0 16px;
    }
  }
`;

const ErrorWrap = styled.div``;

const MinContact = styled.div``;

const Tooltips = withStyles({
  tooltip: {
    color: 'var(--text-color)',
    backgroundColor: 'var(--header-title-color)',
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.15)',
    fontSize: '13px',
    padding: '5px 8px',
    marginLeft: '8px'
  },
  arrow: {
    width: '10px !important',
    height: '15px !important',
    marginTop: '4px !important',
    marginLeft: '-9px !important',
    color: 'var(--header-title-color)',
    borderRadius: 1
  }
})(Tooltip);

export {
  EmptyImage,
  TextEmpty,
  TextDetail,
  MainWrapper,
  SearchInput,
  TableIt,
  MainTab,
  MainTable,
  EmptyDetail,
  EmptyContain,
  MainContent,
  ContentBlock,
  HeadTitle,
  InvoiceNub,
  SpanTag,
  AvtarSupplier,
  ProductNav,
  CatName,
  LeftWrap,
  RightWrap,
  Thead,
  Td,
  Tr,
  Tbody,
  Th,
  PlaceHoldImage,
  PlaceImage,
  MinContact,
  MenuView,
  WrapperPlace,
  AmountNub,
  HeaderWrapper,
  ItemLeft,
  CloseView,
  HeaderHeading,
  ImgSku,
  RText,
  LText,
  SkuRow,
  SkuModal,
  SkuDetails,
  SkuText,
  Tooltips,
  AmountDiv,
  AmountTag,
  SearchView,
  SearchIconView,
  SerchWrap,
  SearchViewMobile,
  ErrorWrap,
  LoadView
};
