import styled, { css } from 'styled-components';
import {
  Headertitle,
  Mediumtext,
  Normaltext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MainWrapper = styled.div`
  height: 100%;
  background: var(--secondary-color);
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  padding: 24px 24px;
  z-index: 2;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const CatName = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 0;
  text-transform: capitalize;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    ${flex};
  }
`;

const RightWrap = styled.div`
  ${flex};
  gap: 15px;
  position: relative;
  @media (max-width: 767px) {
    gap: 12px;
  }
  .pdf {
    margin: 0 4px;
  }
`;

const AccountChartIconBlock = styled.div`
  border: 1px solid var(--secondary-border-color);
  border-radius: 4px;
  width: 32px;
  height: 32px;
  /* margin: 0 5px; */
  cursor: pointer;
  ${flexcenter};
  :hover {
    border: 1px solid var(--input-border-hover-color);
  }
  :last-child {
    margin-right: 0;
  }
  a {
    display: flex;
  }
  svg {
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    width: 28px;
    height: 28px;
  }
`;

const SerchWrap = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    .close {
      position: absolute;
      right: 26px;
      z-index: 11;
      top: 8px;
      vertical-align: middle;
    }
  }
`;

const SearchDataMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    background: var(--secondary-color);
    text-align: left;
    padding: 0px 5px 8px 5px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    position: absolute;
    top: 34px;
    right: 16px;
    left: 16px;
    z-index: 999;
    max-height: 520px;
    overflow: auto;
  }
`;

const SearchData = styled.div`
  background: var(--secondary-color);
  width: 230px;
  text-align: left;
  padding: 0px 5px 8px 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  top: 36px;
  /* right: 0; */
  z-index: 999;
  max-height: 600px;
  overflow: auto;
  @media (max-width: 767px) {
    display: none;
  }
`;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const CatalogBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 0;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: auto;
  padding: 0 24px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const ProductReady = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  border-end-start-radius: 4px;
  border-top-right-radius: 4px;
  padding: 4px 8px;
  z-index: 1;
  background: var(--ready-to-ship-color);
  ${Normaltext};
  color: var(--primary-color);
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const CatalougeName = styled.h3`
  ${Mediumtext};
  color: var(--header-title-color);
  margin: 0 0 6px 0;
  text-transform: capitalize;
  ${Textdotdot};
  @media (max-width: 767px) {
    ${Smalltext};
  }
`;

const ProductImg = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 767px) {
    object-fit: contain;
  }
`;

const ContentBoxCatalog = styled.div`
  position: relative;
  width: 100%;
  &.outofstock {
    margin: 12px 0 12px;
    @media (max-width: 767px) {
      margin: 0;
    }
  }
  @media (max-width: 767px) {
    z-index: 0;
  }
`;

const ContentBoxProducts = styled.div`
  .dateview {
    ${Mediumtext};
    color: var(--grey-color);
    text-transform: uppercase;
    border-bottom: 1px solid var(--secondary-border-color);
    padding-bottom: 10px;
    margin: 0;
    @media (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const ContentBoxProductsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 0 24px;
  padding-top: 4px;
  padding-bottom: 12px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
    padding-bottom: 16px;
  }
`;

const ContentCatalog = styled.div`
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  border-radius: 6px;
  padding: 5px;
  width: 100%;
  :hover {
    border: 1px solid var(--input-border-hover-color);
  }
  ${(props) =>
    props.disabled &&
    css`
      position: relative;
    `}
`;

const ContentBottomCatalog = styled.div`
  width: 100%;
  padding: 10px 10px;
  @media (max-width: 767px) {
    padding: 8px 5px;
  }
`;

const ContentTopCatalog = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 330px;
  transition: 0.3s all;
  border-radius: 4px;
  ${flexcenter};
  :hover {
    .hideshow {
      display: block;
    }
    .change {
      margin-bottom: 40px;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
  .timer-text {
    margin-bottom: 40px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    height: 280px;
  }
  @media (max-width: 480px) {
    height: 208px;
  }
`;

const LButton = styled.div`
  ${flex};
  border: 0;
  padding: 0;
  cursor: pointer;
  color: var(--grey-color);
  svg {
    vertical-align: middle;
  }
  :hover {
    border: none !important;
    svg path {
      stroke: var(--black-light-color);
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: none;
  border-radius: 4px;
  transition: 0.3s all;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  button {
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
    z-index: 1;
    /* width: calc(100% - 10px); */
    @media (max-width: 767px) {
      display: none;
    }
  }
  @media (max-width: 767px) {
    display: flex !important;
    justify-content: flex-end;
    padding: 5px 8px;
  }
`;

const ProductInfoCatalog = styled.div`
  display: flex;
`;

const Product = styled.h4`
  ${Smalltext};
  color: var(--black-light-color);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 767px) {
    ${Normaltext};
  }
`;

const ButtonWrap = styled.div`
  height: 82px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 8px 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
  pointer-events: none;
  button {
    width: 100%;
    :disabled {
      opacity: 0.8;
    }
  }
  @media (max-width: 767px) {
    padding: 0 5px 5px;
    gap: 8px;
  }
`;

const LeftWrapper = styled.div`
  ${flex};
  justify-content: space-evenly;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-border-color);
  /* width: 100%; */
  height: 32px;
  padding: 8px;
  border-radius: 6px;
  pointer-events: all;
  button {
    ${Smalltext};
    ${flexcenter};
    box-shadow: none;
    border: 0;
    width: 100%;
    max-width: 33.33%;
    height: 100%;
  }
  button:hover {
    border: 0 !important;
    box-shadow: none !important;
  }
  input {
    ${Smalltext};
    color: var(--black-light-color);
    border: 0;
    padding: 0 5px;
    margin: 0;
    width: 100%;
    max-width: 33.33%;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    :hover {
      border: 0px;
    }
    :focus {
      border: 0px;
    }
    :active {
      border: 0px;
    }
  }
`;

const EmptyContain = styled.div`
  ${flexcenter};
  height: 100%;
`;

const EmptyDetail = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
`;

const EmptyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextEmpty = styled.h3`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 10px 0 0;
  text-transform: capitalize;
`;

const TextDetail = styled.p`
  ${Smalltext};
  color: var(--grey-color);
  width: 100%;
  max-width: 380px;
  margin: 10px 0;
`;

const ContentBLockWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 24px;
  padding-top: 4px;
  padding-bottom: 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
    padding-bottom: 16px;
  }
`;

const Outofstock = styled.h4`
  ${Mediumtext};
  color: var(--red-color);
  background: var(--out-ot-stock-color);
  border: 1px solid var(--red-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 12px;
  margin: 0;
`;

const OutList = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  background: rgba(255, 255, 255, 0.45);
  height: 100%;
  width: 100%;
  ${flexcenter};
`;

const MobileIcon = styled.div`
  display: none;
  @media (max-width: 767px) {
    background: var(--primary-color);
    border-radius: 4px;
    display: inline-flex;
    padding: 6px;
    pointer-events: all;
    &.prebook {
      background: var(--red-color);
    }
  }
`;

const AddCartView = styled.div`
  display: none;
  @media (max-width: 767px) {
    background: var(--primary-color);
    border-radius: 4px;
    padding: 6px;
    display: inline-flex;
    pointer-events: all;
    &.prebook {
      background: var(--red-color);
    }
  }
`;

const AddView = styled.div`
  width: 100%;
  pointer-events: all;
  button {
    width: 100%;
    white-space: nowrap;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const SearchList = styled.a`
  margin: 8px 0px 0px;
  border-radius: 6px;
  text-decoration: none;
  padding: 7px 10px;
  display: block;
  :hover {
    background-color: #f4f6f8;
    cursor: pointer;
  }
`;

const TextSearch = styled.span`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0 0 10px 0;
`;

const SearchView = styled.div`
  display: block;
  input {
    ${Smalltext};
    background: var(--secondary-color);
    border: 1px solid var(--secondary-border-color);
    margin-right: 0px;
    padding: 7px 10px;
    border-radius: 4px;
    outline: 0;
    color: var(--black-light-color);
    ::placeholder {
      color: var(--placeholder-color);
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const SearchInput = styled.input``;

const SearchViewMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: relative;
    input {
      ${Smalltext};
      margin-right: 0px;
      color: var(--black-light-color);
      background: var(--secondary-color);
      border: 1px solid var(--secondary-border-color);
      border-radius: 4px;
      outline: 0;
      padding: 8px 10px;
      margin: 4px 24px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 11;
      ::placeholder {
        color: var(--placeholder-color);
      }
    }
  }
  @media (max-width: 767px) {
    input {
      margin: 0 16px;
    }
  }
`;

const SearchIconView = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: inline-flex;
    margin-right: 5px;
  }
`;

const SvgIconWrap = styled.div`
  position: relative;
  svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    cursor: pointer;
  }
`;

const ErrorWrap = styled.div``;

export {
  MainWrapper,
  MainContent,
  ProductNav,
  CatName,
  SearchInput,
  RightWrap,
  LeftWrap,
  CatalogBlock,
  ContentWrapper,
  ProductInfoCatalog,
  Product,
  ProductImg,
  CatalougeName,
  ContentBottomCatalog,
  ContentBoxCatalog,
  ContentCatalog,
  ContentTopCatalog,
  ButtonWrapper,
  ContentBLockWrapper,
  EmptyContain,
  EmptyDetail,
  EmptyImage,
  TextEmpty,
  TextDetail,
  MenuView,
  ButtonWrap,
  LeftWrapper,
  SearchData,
  SearchList,
  TextSearch,
  Outofstock,
  OutList,
  MobileIcon,
  AddCartView,
  AddView,
  SearchView,
  SearchIconView,
  SearchViewMobile,
  SerchWrap,
  SearchDataMobile,
  SvgIconWrap,
  ContentBoxProducts,
  ContentBoxProductsList,
  ProductReady,
  LButton,
  ErrorWrap,
  AccountChartIconBlock,
  LoadView
};
