import styled from 'styled-components';
const MainSection = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 10px;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: #ff4b4b;
  }
`;
export { MainSection };
