import { addToCart, clearCartData } from '../actions/CartAction';
import { clearCatalogueData } from '../actions/catalogsActions';
import { clearCustomerData } from '../actions/customerActions';
import { clearResponseMessage } from '../actions/messageActions';
import { clearOrderListData } from '../actions/orderActions';
import { clearPaginationData } from '../actions/paginationActions';
import { clearReportData } from '../actions/reportActions';
import UserPreferenceSingleton from '../helper/UserPreferenceSingleton';
import { getCompanyList } from './authService';
import { getCustomerItem, getCustomerSettings } from './customerService';

/**
 * @desc  Load required data
 */
export const loadRequiredData = (history) => async (dispatch, getState) => {
  try {
    dispatch(clearResponseMessage());
    await dispatch(getCompanyList());
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    await dispatch(getCustomerItem(companyId));
    await dispatch(getCustomerSettings(companyId));
    const localCart = UserPreferenceSingleton.getInstance().getCartItems();
    if (localCart?.length > 0) dispatch(addToCart(localCart));
    return true;
  } catch (e) {
    console.log('error ', e);
    return false;
  } finally {
  }
};

export const clearAllItemData = () => async (dispatch) => {
  dispatch(clearCartData());
  dispatch(clearCatalogueData());
  dispatch(clearCustomerData());
  dispatch(clearOrderListData());
  dispatch(clearPaginationData());
  dispatch(clearReportData());
  UserPreferenceSingleton.getInstance().setCartItems(undefined);
};
