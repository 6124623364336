import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { messageReducer } from './messageReducer';
import { catalogsReducer } from './catalogsReducer';
import { orderListReducer } from './orderListReducer';
import { paginationReducer } from './paginationReducer';
import { customerReducer } from './customerReducer';
import { SideMenuReducer } from './SideMenuReducer';
import { ReportsReducer } from './reportsReducer';
import { cartReducer } from './CartReducer';
import { dashboardReducer } from './dashboardReducer';
import { membershipReducer } from './membershipReducer';

const appReducer = combineReducers({
  auth: authReducer,
  message: messageReducer,
  catalog: catalogsReducer,
  orderList: orderListReducer,
  pagination: paginationReducer,
  customer: customerReducer,
  sideMenu: SideMenuReducer,
  reports: ReportsReducer,
  cart: cartReducer,
  dashboard: dashboardReducer,
  membership: membershipReducer
});

export default appReducer;
