export const SIDEBAR_MENU_DASHBOARD = 'dashboard';
export const SIDEBAR_MENU_ALL_CATALOGS = 'catalogue';
export const SIDEBAR_MENU_ORDERS = 'orders';
export const SIDEBAR_MENU_REPORTS = 'reports';
export const ZERO_QTY_CATALOG = '/zero-qty-catalogue';
export const PENDING_ORDERS = '/pending-orders';
export const CANCEL_ORDERS = '/cancel-orders';
export const LEDGER_REPORTS = '/ledger-report';
export const INVOICES = '/invoices';
export const CART = '/cart';
export const INVOICED_ORDERS = '/invoiced-orders';
export const ALL_CATALOGS = '/all-catalogue';
export const OUT_OF_STOCK_PRODUCTS = '/out-of-stock-products';

export const SALES_ORDER_STATUS = {
  QUEUE: 0,
  OPEN: 1,
  MARK_AS_CONFIRMED: 2,
  PARTIALLY_PROCESSED: 3,
  PROCESSED: 4,
  READY_TO_SHIP: 5,
  MANIFESTED: 5.1,
  PICKED_UP: 6,
  SHIPPED: 7,
  DELIVERED: 8,
  CLOSED: 9,
  CANCELLED: 10,
  RTO_ACKNOWLEDGE: 11,
  PARTIALLY_CANCELLED: 15
};

export const ORDER_STATUS = {
  0: 'Queue',
  1: 'Open',
  2: 'Confirmed',
  3: 'Partial',
  4: 'Processed',
  5: 'Ready to ship',
  5.1: 'Manifest',
  6: 'Picked up',
  7: 'Shipped',
  8: 'Delivered',
  9: 'Closed',
  10: 'Cancelled',
  11: 'Rto acknowledge'
};

export const PRODUCT_STATUS = {
  NONE: 0,
  ACTIVE: 1,
  INACTIVE: 2
};

export const INVOICE_TEMPLATE_TYPE = {
  NONE: 0,
  DEFAULT: 1,
  CLASSIC: 2,
  THERMAL: 3
};

export const REQUEST_CANCEL_MESSAGE = 'CANCEL';

export const TOTAL_ORDERS_TOOLTIP = {
  Cancelled: 'Cancelled',
  Fulfilled: 'Fulfilled'
};
