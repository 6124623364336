import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Button from '../../../components/Button/Button';
import { Smalltext, flex } from '../../../font-variable';
// import { ReactComponent as CloseIcon } from '../../../assets/images/svg/closeicon.svg';

const Form = styled.form``;
const LabelForm = styled.label`
  ${Smalltext};
  color: var(--grey-color);
  margin-bottom: 4px;
  display: block;
  span {
    color: var(--red-color);
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 16px;
  button {
    margin: 0 8px 0 0;
  }
`;
const DateView = styled.div`
  margin-right: 0px;
  .grid {
    ${flex};
    flex-wrap: wrap;
    margin-bottom: 16px;
  }
`;
// const CloseIconWrapper = styled.div`
//   cursor: pointer;
//   svg {
//     vertical-align: middle;
//     position: relative;
//     left: 8px;
//   }
// `;

class FilterModalItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todate: props.filterData?.todate || null,
      fromdate: props.filterData?.fromdate || null
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.fromdate !== this.state.fromdate || newProps.toDate !== this.state.toDate) {
      this.setState({ fromdate: newProps.fromdate, todate: newProps.toDate });
    }
  }

  handleFromDateChange = (date) => {
    this.setState({ fromdate: date });
  };
  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { fromdate, todate } = this.state;
    let filterData = {
      fromdate: fromdate ? moment(fromdate).format('DD-MMM-YYYY') : undefined,
      todate: todate ? moment(todate).format('DD-MMM-YYYY') : undefined
    };
    if (this.props.onApply) this.props.onApply(filterData);
  };

  clearDate = (e) => {
    this.setState({ [`${e.target.id}`]: null });
  };

  onClickClear = () => {
    this.setState(
      {
        fromdate: null,
        todate: null
      },
      () => {
        if (this.props.onClear) this.props.onClear();
      }
    );
  };

  render() {
    const { fromdate, todate } = this.state;
    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <DateView>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {
                <Grid className='grid'>
                  <LabelForm lebel>From date</LabelForm>
                  <KeyboardDatePicker
                    disableToolbar
                    margin='normal'
                    placeholder='DD/MM/YYYY'
                    id='date-picker-inline'
                    format='dd/MM/yyyy'
                    variant='inline'
                    autoOk={true}
                    value={fromdate ? fromdate : null}
                    onChange={this.handleFromDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <CloseIconWrapper id='fromdate' onClick={(e) => this.clearDate(e)}>
                    //       <CloseIcon id='fromdate' />
                    //     </CloseIconWrapper>
                    //   )
                    // }}
                    InputAdornmentProps={{
                      position: 'end'
                    }}
                  />
                </Grid>
              }
            </MuiPickersUtilsProvider>
          </DateView>
          <DateView>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {
                <Grid className='grid'>
                  <LabelForm lebel>To date</LabelForm>
                  <KeyboardDatePicker
                    disableToolbar
                    margin='normal'
                    placeholder='DD/MM/YYYY'
                    id='date-picker-inline'
                    format='dd/MM/yyyy'
                    variant='inline'
                    autoOk={true}
                    value={todate ? todate : null}
                    onChange={this.handleToDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <CloseIconWrapper id='todate' onClick={(e) => this.clearDate(e)}>
                    //       <CloseIcon id='todate' />
                    //     </CloseIconWrapper>
                    //   )
                    // }}
                    InputAdornmentProps={{
                      position: 'end'
                    }}
                  />
                </Grid>
              }
            </MuiPickersUtilsProvider>
          </DateView>

          <ButtonWrapper>
            <Button type='submit' small isPrimary={true} title='Apply' />
            <Button type='button' createbtn isPrimary={true} title='Clear' onClick={this.onClickClear} />
          </ButtonWrapper>
        </Form>
      </>
    );
  }
}

export default FilterModalItem;
