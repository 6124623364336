import styled, { css } from 'styled-components';
import {
  Headertitle,
  Mediumtext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../font-variable';

const SubmitButton = styled.div`
  display: inline-block;
`;

const MainWrapper = styled.div`
  height: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const MinContact = styled.div`
  height: 100%;
`;

const RightItem = styled.div`
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 16px 16px;
  }
`;

const ItemList = styled.div`
  ${flexspacebetween};
`;

const TextItem = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const RightList = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ItemRight = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  margin-left: 24px;
  @media (max-width: 767px) {
    margin-left: 16px;
  }
`;

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MainTable = styled.div`
  flex: 1 1 0;
  overflow: auto;
`;

const MainTab = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const TableIt = styled.table`
  width: 100%;
  border-spacing: 0px;
  padding-right: 24px;
  @media (max-width: 767px) {
    padding-right: 16px;
  }
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Tr = styled.tr`
  vertical-align: middle;
  &:first-child {
    border-top: 0;
  }
  :hover {
    background: var(--background-color);
  }
`;

const Th = styled.th`
  ${Smalltext};
  color: var(--grey-color);
  text-align: left;
  padding: 1px 8px 8px;
  text-transform: capitalize;
  background: var(--secondary-color);
  border-bottom: 1px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 2;
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:last-child {
    width: 5%;
    cursor: pointer;
    text-align: right;
  }
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border-bottom: 1px solid var(--border-color);
  padding: 12px 8px;
  &:first-child {
  }
  &:nth-child(2) {
  }
  &:last-child {
    cursor: pointer;
    text-align: right;
    button {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 15px;
      margin: 0;
    }
  }
`;

const HeadTitle = styled.div`
  display: grid;
  grid-gap: 2px;
  ${Mediumtext};
  color: var(--black-light-color);
  cursor: pointer;
  @media (max-width: 767px) {
    white-space: pre;
  }
`;

const EmptyContain = styled.div`
  ${flexcenter};
  height: 100%;
`;

const EmptyDetail = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
`;

const EmptyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TextEmpty = styled.h3`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 10px 0 0;
  text-transform: capitalize;
`;

const SaleWrapper = styled.div`
  background: var(--secondary-color);
  width: 100%;
  max-width: 500px;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${flexspacebetween};
  @media (max-width: 767px) {
    max-width: 450px;
  }
  @media (max-width: 470px) {
    max-width: 315px;
  }
`;

const SaleModalWrap = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  ${flexspacebetween};
  flex-direction: column;
  margin-bottom: 0px;
  background: var(--secondary-color);
  border-radius: 6px 6px 0 0;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 12px 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid var(--secondary-border-color);
  @media (max-width: 767px) {
    padding: 10px 16px;
    margin-bottom: 16px;
  }
`;

const OverViewDetailsBlock = styled.div`
  width: 100%;
  padding: 0 18px 18px 18px;
  @media (max-width: 767px) {
    padding: 0 16px 16px 16px;
  }
`;

const OverViewTabItemWrapper = styled.div`
  flex: 1 1 0px;
  z-index: 2;
  position: relative;
  height: 100%;
  display: flex;
  overflow: auto;
`;

const ProductDetailsTitle = styled.div`
  ${flex};
  margin-bottom: 12px;
  span {
    ${Mediumtext};
    font-weight: 600;
    color: var(--header-title-color);
    margin-right: 10px;
  }
`;

const Dl = styled.dl`
  margin: 0;
`;

const DT = styled.dt`
  width: 100%;
  ${Smalltext};
  color: var(--grey-color);
  word-wrap: break-word;
  white-space: pre-wrap;
  ${(props) =>
    props.description &&
    css`
      max-width: 100%;
    `}
`;

const HeaderBlock = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const ItemLeft = styled.div``;

const HeaderHeading = styled.h2`
  ${Mediumtext};
  font-weight: 600;
  color: var(--header-title-color);
  margin: 0;
  text-transform: capitalize;
`;

const ItemWrap = styled.div`
  ${flex};
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
`;

export {
  SubmitButton,
  MainWrapper,
  MainContent,
  MinContact,
  TextEmpty,
  RightItem,
  ItemList,
  TextItem,
  RightList,
  ItemRight,
  LoadView,
  MainTable,
  MainTab,
  TableIt,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HeadTitle,
  EmptyContain,
  EmptyDetail,
  EmptyImage,
  SaleWrapper,
  SaleModalWrap,
  HeaderWrapper,
  OverViewDetailsBlock,
  OverViewTabItemWrapper,
  ProductDetailsTitle,
  Dl,
  DT,
  HeaderBlock,
  ItemLeft,
  HeaderHeading,
  ItemWrap
};
