import React, { Component } from 'react';

class ClassicInvoiceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <h2> ClassicInvoiceTemplate </h2>
      </>
    );
  }
}

export default ClassicInvoiceTemplate;
