import styled from 'styled-components';
import { Maintitle, Mediumtext, Smalltext, flexcenter } from '../../../font-variable';

const MembershipBody = styled.div`
  ${flexcenter};
  background-color: var(--background-color);
  height: 100%;
`;

const MembershipWrap = styled.div`
  max-width: 420px;
  width: 100%;
  margin: auto;
  padding: 50px 10px;
  text-align: center;
`;

const MembershipBox = styled.div``;
const LoginForm = styled.form``;

const MemberLogin = styled.div`
  margin-bottom: 24px;
`;

const LoginText = styled.div`
  text-align: center;
  margin: 16px 0 32px;
`;

const LoginH2 = styled.h2`
  ${Maintitle};
  color: var(--header-title-color);
  margin: 0 0 10px;
`;

const LoginCaption = styled.p`
  ${Mediumtext};
  color: var(--black-light-color);
  margin: 0;
`;

const InputBox = styled.div`
  margin-bottom: 18px;
  input {
    :focus {
      border: 1px solid var(--primary-color);
      offset-distance: 100%;
    }
    :focus-visible {
      border: 1px solid var(--primary-color);
      outline: 3px solid var(--outline-blue-color);
      margin: auto;
      border-radius: 4px;
    }
  }
`;

const LabelView = styled.div`
  ${Smalltext};
  color: var(--grey-color);
  margin-bottom: 6px;
  text-align: left;
  display: block;
`;

const LoginBtn = styled.div`
  ${Smalltext};
  color: var(--black-light-color);
  ${flexcenter};
  gap: 6px;
  margin-top: 24px;
`;

const ButtonWrap = styled.div`
  margin: 24px 0;
  button {
    margin: 0;
  }
`;

const ButtonText = styled.div`
  cursor: pointer;
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
`;

export {
  MembershipBody,
  MembershipWrap,
  MembershipBox,
  LoginForm,
  MemberLogin,
  LoginH2,
  LoginBtn,
  LoginCaption,
  LabelView,
  LoginText,
  ButtonWrap,
  InputBox,
  ButtonText
};
