import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { CSVLink } from 'react-csv';
import Layout from '../../components/Layout/Layout';
import { connect } from 'react-redux';
import { toggleSideMenu } from '../../actions/SideMenuAction';
import { SIDEBAR_MENU_REPORTS } from '../../global/constants';
import { ReactComponent as MenuIcon } from '../../assets/images/svg/menuicon.svg';
import { ReactComponent as ExcelIcon } from '../../assets/images/svg/excel.svg';
import { ReactComponent as AccountChartIcon } from '../../assets/images/svg/downloadaccountchart.svg';
import { getLedgerReport } from '../../services/reportService';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import moment from 'moment';
import { clearResponseMessage } from '../../actions/messageActions';
import Loading from '../../components/Loading/Loading';
import { isEmpty } from 'lodash';
import { ReactComponent as FilterIconSvg } from '../../assets/images/svg/Filter.svg';
import { Menu, withStyles } from '@material-ui/core';
import FilterModalItem from '../LiveCatalogs/FilterModalItem/FilterModalItem';
import { currencyWithDecimal, getFormattedAddress, makePdf } from '../../helper/common';
import { ledgerReportCSVHeaders } from '../../data/raw';
import {
  Headertitle,
  Mediumtext,
  Smalltext,
  Textdotdot,
  flex,
  flexcenter,
  flexspacebetween
} from '../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MainWrapper = styled.div`
  height: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ProductNav = styled.div`
  ${flexspacebetween};
  background: var(--secondary-color);
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 24px 24px;
  @media (max-width: 767px) {
    @media (max-width: 767px) {
      padding: 14px 16px;
    }
  }
`;

const Heading = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  text-transform: capitalize;
  margin: 0;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  flex: 1 1 0;
  margin-left: 24px;
  @media (max-width: 767px) {
    margin-left: 16px;
  }
`;

const AdvanceWrapper = styled.div`
  ${Smalltext};
  color: var(--grey-color);
  display: block;
  margin: 0;
`;

const TableTransaction = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  flex: 1 1 0;
  overflow: auto;
`;

const TableTopBlock = styled.div`
  ${flexspacebetween};
  gap: 10px;
  border-radius: 6px;
  margin: 0 24px 24px 0;
  padding: 16px 16px;
  background: var(--background-color);
  @media (max-width: 767px) {
    margin: 0 16px 16px 0;
    padding: 12px 12px;
  }
`;

const MainTitle = styled.div`
  text-align: left;
  ${(props) =>
    props.textright &&
    css`
      text-align: right;
    `};
`;

const AdvanceBlock = styled.div`
  ${Headertitle};
  font-size: 17px;
  color: var(--header-title-color);
  margin: 0 0 4px 0;
  text-transform: capitalize;
`;

const TableResponsive = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const EmptyReport = styled.div`
  ${Mediumtext};
  color: var(--black-light-color);
  text-align: center;
`;

const TableIn = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  padding-right: 24px;
  margin-bottom: 24px;
`;

const TBody = styled.tbody``;
const Thead = styled.thead``;
const Tr = styled.tr``;

const Th = styled.th`
  ${Smalltext};
  text-align: left;
  padding: 7px 12px;
  border: 1px solid var(--secondary-border-color);
  text-transform: capitalize;
  background: var(--background-color);
  position: sticky;
  top: 0;
  z-index: 99;
  span {
    color: var(--red-color);
  }
  :first-child {
    border-radius: 3px 0 0 0;
  }
  :last-child {
    border-radius: 0 3px 0 0;
  }
  :nth-child(1) {
    width: 15%;
    border-right: 0;
  }
  :nth-child(2) {
    width: 22%;
    border-right: 0;
  }
  :nth-child(3) {
    width: 30%;
    border-right: 0;
  }
  :nth-child(4) {
    width: 12%;
    border-right: 0;
    text-align: right;
  }
  :nth-child(5) {
    width: 12%;
    text-align: right;
  }
`;

const Td = styled.td`
  ${Mediumtext};
  color: var(--black-light-color);
  border: 1px solid var(--secondary-border-color);
  padding: 10px 12px;
  @media (max-width: 1024px) {
    white-space: nowrap;
  }
  :nth-child(1) {
    border-right: 0;
    border-top: 0;
  }
  :nth-child(2) {
    border-right: 0;
    border-top: 0;
  }
  :nth-child(3) {
    border-right: 0;
    border-top: 0;
  }
  :nth-child(4) {
    border-top: 0;
    border-right: 0;
    text-align: right;
  }
  :nth-child(5) {
    border-top: 0;
    text-align: right;
  }
  &.totalwrapper {
    font-weight: 600;
  }
  &.border-blank {
    border-right: 0px;
  }
`;

const Tfoot = styled.tfoot`
  tr {
    :last-child {
      td {
        position: sticky;
        bottom: 0;
        z-index: 99;
        background: var(--secondary-color);
        :first-child {
          border-radius: 0 0 0 3px;
        }
        :last-child {
          border-radius: 0 0 3px 0;
        }
      }
    }
    td {
      font-weight: 600;
    }
  }
`;

const LeftWrap = styled.div`
  @media (max-width: 767px) {
    ${flex};
  }
`;

const RightWrap = styled.div`
  ${flex};
`;

const MenuView = styled.div`
  display: none;
  @media (max-width: 767px) {
    margin-right: 12px;
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
`;

const SvgIconWrap = styled.div`
  margin: 0 10px;
  position: relative;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
`;

const AccountChartIconBlock = styled.div`
  border: 1px solid var(--secondary-border-color);
  border-radius: 4px;
  width: 32px;
  height: 32px;
  margin: 0 5px;
  cursor: pointer;
  ${flexcenter};
  :hover {
    border: 1px solid var(--input-border-hover-color);
  }
  :first-child {
    margin-left: 15px;
  }
  :last-child {
    margin-right: 0;
  }
  a {
    display: flex;
  }
  svg {
    vertical-align: middle;
  }
  @media (max-width: 767px) {
    width: 28px;
    height: 28px;
  }
`;

const StyledMenu = withStyles({
  paper: {
    border: '1px solid var(--secondary-border-color)',
    padding: '12px',
    background: 'var(--secondary-color)',
    boxShadow: 'var(--box-shadow) !important',
    borderRadius: '6px',
    marginTop: '5px',
    width: (props) => (props?.isSummaryMenu ? '465px' : '220px'),
    maxHeight: (props) => (props?.isSummaryMenu ? '420px' : ''),
    overflowY: (props) => (props?.isSummaryMenu ? 'scroll' : '')
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
));

class LedgerReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenFilterModal: false,
      anchorEl: null,
      filterData: undefined
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  componentWillUnmount = () => {
    this.props.clearResponseMessage();
  };

  openFilterModal = (e) => {
    this.setState({ isOpenFilterModal: true, anchorEl: e.currentTarget });
  };

  closeFilterModal = () => {
    this.setState({ isOpenFilterModal: false, anchorEl: null });
  };

  onApplyFilter = (filterData) => {
    if (filterData) {
      this.setState({ filterData }, () => {
        this.loadData();
        this.closeFilterModal();
      });
    }
  };

  onClearFilter = async () => {
    this.setState({ filterData: undefined }, () => {
      this.loadData();
      this.closeFilterModal();
    });
  };

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    const { filterData } = this.state || {};
    const fromdate = filterData?.fromdate
      ? moment(filterData?.fromdate).format('YYYY-MM-DD')
      : moment().subtract(30, 'days').format('YYYY-MM-DD');
    const todate = moment(filterData?.todate).format('YYYY-MM-DD');
    if (companyId) {
      await this.props.getLedgerReport(companyId, fromdate, todate);
    }
  };

  onClickPrintDownload = (action, address, displayDate, first_name) => {
    const reportName = 'Ledger Report';
    const { ledgerReport } = this.props.reports;
    const { total_credit, total_debit, balance } = ledgerReport?.items || {};

    let { ledgers } = ledgerReport?.items || {};
    if (ledgers?.length > 0) {
      ledgers = ledgers?.map((x) => {
        return {
          ...x,
          debit: currencyWithDecimal(x?.debit),
          credit: currencyWithDecimal(x?.credit)
        };
      });
    }

    const columns = [
      {
        label: 'Date',
        field: 'date',
        width: '20%',
        dataRowStyle: {
          fontSize: 11
        }
      },
      {
        label: 'Number',
        field: 'number',
        width: '20%',
        dataRowStyle: {
          fontSize: 11
        }
      },
      {
        label: 'Description',
        field: 'description',
        width: '20%',
        dataRowStyle: {
          fontSize: 11
        }
      },
      {
        label: 'Debit',
        field: 'debit',
        width: '20%',
        headerStyle: {
          alignment: 'right'
        },
        dataRowStyle: {
          fontSize: 11,
          alignment: 'right'
        },
        footerRowStyle: {
          alignment: 'right'
        }
      },
      {
        label: 'Credit',
        field: 'credit',
        width: '20%',
        headerStyle: {
          alignment: 'right'
        },
        dataRowStyle: {
          fontSize: 11,
          alignment: 'right'
        },
        footerRowStyle: {
          alignment: 'right'
        }
      }
    ];
    const totalRowData = {
      date: 'Total',
      debit: currencyWithDecimal(total_debit),
      credit: currencyWithDecimal(total_credit),
      balance: currencyWithDecimal(balance),
      footerRowStyle: {
        color: '#4f566b',
        fontSize: 12
      }
    };
    makePdf(reportName, first_name, displayDate, address, columns, ledgers, action, totalRowData);
  };

  toggleSideMenu = () => {
    if (this.props.toggleSideMenu) this.props.toggleSideMenu();
  };
  render() {
    const { anchorEl, isOpenFilterModal, filterData } = this.state;
    const { fromdate, todate } = filterData || {};
    const fromDate = fromdate
      ? moment(fromdate).format('DD MMM YYYY')
      : moment().subtract(30, 'days').format('DD MMM YYYY');
    let toDate = moment(todate).format('DD MMM YYYY');
    const displayDate = fromDate + ' To ' + toDate;
    const { customerItem } = this.props.customer;
    const { loading, ledgerReport } = this.props.reports;
    const { first_name } = customerItem;
    const address = getFormattedAddress(customerItem?.addresses?.[0]);
    return (
      <Layout expanded={SIDEBAR_MENU_REPORTS}>
        <MainWrapper>
          <MainContent>
            <ProductNav>
              <LeftWrap>
                <MenuView onClick={this.toggleSideMenu}>
                  <MenuIcon />
                </MenuView>
                <Heading>Ledger Report</Heading>
              </LeftWrap>
              <RightWrap>
                <SvgIconWrap>
                  <FilterIconSvg onClick={this.openFilterModal}></FilterIconSvg>
                </SvgIconWrap>

                <AccountChartIconBlock>
                  <CSVLink
                    data={ledgerReport?.items?.ledgers || []}
                    headers={ledgerReportCSVHeaders}
                    filename={`Ledger_Report.csv`}>
                    <ExcelIcon />
                  </CSVLink>
                </AccountChartIconBlock>
                <AccountChartIconBlock>
                  <AccountChartIcon
                    onClick={() => {
                      this.onClickPrintDownload('print', address, displayDate, first_name);
                    }}
                  />
                </AccountChartIconBlock>
              </RightWrap>
            </ProductNav>
            <ContentBlock>
              <TableTransaction>
                <TableTopBlock>
                  <MainTitle>
                    <AdvanceBlock>{first_name}</AdvanceBlock>
                    <AdvanceWrapper>{address}</AdvanceWrapper>
                  </MainTitle>
                  <MainTitle textright>
                    <AdvanceBlock>Ledger Report</AdvanceBlock>
                    <AdvanceWrapper>From {displayDate}</AdvanceWrapper>
                  </MainTitle>
                </TableTopBlock>
                {loading && !ledgerReport ? (
                  <LoadView>
                    <Loading />
                  </LoadView>
                ) : (
                  <TableResponsive>
                    {isEmpty(ledgerReport?.items?.ledgers) ? (
                      <EmptyReport>No records available.</EmptyReport>
                    ) : (
                      <TableIn>
                        <Thead>
                          <Tr>
                            <Th>Date</Th>
                            <Th>Number</Th>
                            <Th>Description</Th>
                            <Th>Debit</Th>
                            <Th>Credit</Th>
                          </Tr>
                        </Thead>
                        <>
                          {ledgerReport?.items?.ledgers?.map((item, index) => {
                            return (
                              <TBody key={index}>
                                <Tr key={index}>
                                  <Td>{item?.date}</Td>
                                  <Td>{item?.number}</Td>
                                  <Td>{item?.description}</Td>
                                  {item?.debit === 0 ? <Td></Td> : <Td>₹ {currencyWithDecimal(item?.debit)}</Td>}
                                  {item?.credit === 0 ? <Td></Td> : <Td>₹ {currencyWithDecimal(item?.credit)}</Td>}
                                </Tr>
                              </TBody>
                            );
                          })}
                          <Tfoot>
                            <Tr>
                              <Td className='totalwrapper'>Total</Td>
                              <Td className='totalwrapper'></Td>
                              <Td className='totalwrapper'></Td>
                              <Td className='totalwrapper'>
                                ₹ {currencyWithDecimal(ledgerReport?.items?.total_debit)}
                              </Td>
                              <Td className='totalwrapper'>
                                ₹ {currencyWithDecimal(ledgerReport?.items?.total_credit)}
                              </Td>
                            </Tr>
                          </Tfoot>
                        </>
                      </TableIn>
                    )}
                  </TableResponsive>
                )}
              </TableTransaction>
            </ContentBlock>
          </MainContent>
        </MainWrapper>
        <StyledMenu id='customized-menu' anchorEl={anchorEl} open={isOpenFilterModal} onClose={this.closeFilterModal}>
          <FilterModalItem onApply={this.onApplyFilter} filterData={filterData} onClear={this.onClearFilter} />
        </StyledMenu>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports,
  message: state.message,
  customer: state.customer
});

export default connect(mapStateToProps, { toggleSideMenu, getLedgerReport, clearResponseMessage })(LedgerReport);
