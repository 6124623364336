import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import Message from '../../components/Message';
import { INVOICE_TEMPLATE_TYPE, SIDEBAR_MENU_REPORTS } from '../../global/constants';
import InvoiceTemplates from '../../components/InvoiceTemplates/InvoiceTemplates';
import { ReactComponent as LeftArrow } from '../../assets/images/svg/Leftarrow-back.svg';
import { ReactComponent as PrinterIcon } from '../../assets/images/svg/printericon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/svg/downloadaccountchart.svg';

import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getInvoicePreview } from '../../services/reportService';
import { REACT_APP_APIURL } from '../../global/Environment';
import { Headertitle, Mediumtext, Textdotdot, flex, flexcenter, flexspacebetween } from '../../font-variable';

const LoadView = styled.div`
  ${flexspacebetween};
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--secondary-color);
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 24px 24px;
  @media (max-width: 767px) {
    padding: 14px 16px;
  }
`;

const RightWrap = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  position: relative;
  overflow: hidden auto;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
  .pdf {
    margin: 0 4px;
  }
  .errorBox,
  .successBox {
    margin-left: 0;
  }
  input {
    margin-right: 0px;
    background: var(--secondary-color);
    padding: 7px 12px;
    border: 1px solid var(--secondary-border-color);
    border-radius: 4px;
    outline: 0;
    ${Mediumtext};
    color: var(--black-light-color);
    ::placeholder {
      color: var(--placeholder-color);
    }
    @media (max-width: 767px) {
      font-size: 12px;
      padding: 4px 6px;
      width: 100%;
      max-width: 145px;
    }
  }
`;

const InvoiceBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px 24px 24px;
  margin-left: 4px;
  @media (max-width: 1024px) {
    padding: 0;
    margin: 8px 0 16px;
    overflow: auto;
  }
`;

const ItemLeft = styled.div`
  ${flex};
`;

const LeftArrowWrapper = styled.div`
  ${flexcenter};
  cursor: pointer;
`;

const HeaderHeading = styled.h2`
  ${Headertitle};
  color: var(--header-title-color);
  margin: 0 0 0 8px;
  ${Textdotdot};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const HeaderRight = styled.div`
  ${flexcenter};
`;

const AccountChartIconBlock = styled.div`
  ${flexcenter};
  border: 1px solid var(--secondary-border-color);
  border-radius: 4px;
  padding: 6px;
  margin: 0 4px;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 4px;
  }
  :hover {
    border: 1px solid var(--input-border-hover-color);
  }
  :last-child {
    margin-right: 0;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

const ErrorWrap = styled.div``;

class ViewInvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const id = this.props.match.params.id;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      await this.props.getInvoicePreview(companyId, id);
    }
  };

  onClickPrint = () => {
    const id = this.props.match.params.id;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId && id) {
      const url = `${REACT_APP_APIURL}/sales/${companyId}/pdf-preview/${id}`;
      window.open(url, '_blank');
    }
  };

  onClickDownload = () => {
    const id = this.props.match.params.id;
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId && id) {
      const url = `${REACT_APP_APIURL}/sales/${companyId}/pdf-preview/${id}?download=true`;
      window.open(url, '_blank');
    }
  };

  onNavBack = () => {
    this.props.history.goBack();
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return (
        <ErrorWrap>
          <Message text={resError} type={'error'} />
        </ErrorWrap>
      );
    }
  }

  render() {
    const { invoicePreview, loading } = this.props.reports;
    return (
      <Layout expanded={SIDEBAR_MENU_REPORTS}>
        <MainWrapper>
          <Header>
            <ItemLeft>
              <LeftArrowWrapper onClick={this.onNavBack}>
                <LeftArrow />
              </LeftArrowWrapper>
              <HeaderHeading>{invoicePreview?.invoice?.number}</HeaderHeading>
            </ItemLeft>
            <HeaderRight>
              <AccountChartIconBlock onClick={this.onClickPrint}>
                <PrinterIcon />
              </AccountChartIconBlock>
              <AccountChartIconBlock onClick={this.onClickDownload}>
                <DownloadIcon />
              </AccountChartIconBlock>
            </HeaderRight>
          </Header>

          {this.renderErrors()}
          <RightWrap>
            {/* <Header></Header> */}
            {loading ? (
              <LoadView>
                <Loading />
              </LoadView>
            ) : (
              <>
                {invoicePreview && (
                  <InvoiceBoxWrapper>
                    <InvoiceTemplates type={INVOICE_TEMPLATE_TYPE.DEFAULT} invoicePreview={invoicePreview} />
                  </InvoiceBoxWrapper>
                )}
              </>
            )}
          </RightWrap>
        </MainWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  reports: state.reports
});

export default connect(mapStateToProps, { getInvoicePreview })(ViewInvoicePreview);
