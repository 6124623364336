import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Warning from '../../assets/images/svg/warning.svg';
import Checkmark from '../../assets/images/svg/checkmark.svg';
import { Smalltext } from '../../font-variable';

const MessageWrap = styled.div`
  ${Smalltext};
  padding: 8px 8px 8px 42px;
  width: 100%;

  &.successBox {
    color: var(--green-color);
    background: var(--success-message);
    border: 1px solid var(--green-color);
    width: auto;
    border-radius: 6px;
    margin: 0 24px 20px;
    background-position: 12px;
    background-repeat: no-repeat;
    background-image: url(${Checkmark});
    @media (max-width: 767px) {
      position: sticky;
      top: auto;
      left: 0;
      right: 0;
      z-index: 1;
      margin: 0 16px 4px;
    }
  }

  &.errorBox {
    color: var(--red-color);
    background: var(--error-message);
    border: 1px solid var(--red-color);
    width: auto;
    border-radius: 6px;
    margin: 0 24px 20px;
    background-position: 12px;
    background-repeat: no-repeat;
    background-image: url(${Warning});
    @media (max-width: 767px) {
      position: sticky;
      z-index: 1;
      left: 0;
      right: 0;
      top: auto;
      margin: 0 16px 4px;
    }
  }
`;

const Paragraph = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;

class Message extends Component {
  render() {
    const { text, type } = this.props;
    const isSuccess = type === 'success';
    return (
      <MessageWrap {...this.props} className={classNames('statusCover', isSuccess ? 'successBox' : 'errorBox')}>
        <Paragraph className='statusText'>{text === undefined ? (isSuccess ? 'Success' : 'Error') : text}</Paragraph>
      </MessageWrap>
    );
  }
}

export default Message;
