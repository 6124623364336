import React, { Component } from 'react';
import { Backdrop, Modal } from '@material-ui/core';
import { connect } from 'react-redux';
import classNames from 'classnames';
import UserPreferenceSingleton from '../../helper/UserPreferenceSingleton';
import { getAnnouncementList } from '../../services/dashboardService';
import Message from '../../components/Message';
import EmptyLogo from '../../assets/images/chartaccounts.svg';
import Loader from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';
import {
  SubmitButton,
  MainWrapper,
  MainContent,
  MinContact,
  TextEmpty,
  RightItem,
  ItemList,
  TextItem,
  RightList,
  ItemRight,
  LoadView,
  MainTable,
  MainTab,
  TableIt,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HeadTitle,
  EmptyContain,
  EmptyDetail,
  EmptyImage
} from './style';
import AnnouncementDetailModal from './AnnouncementDetailModal';
import { SIDEBAR_MENU_DASHBOARD } from '../../global/constants';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Pagination/Pagination';
import { loadListWithPagging } from '../../services/paginationService';

export class AnnouncementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAnnouncementDetailModal: false,
      selectedItem: undefined,
      filteredList: undefined
    };
  }

  componentDidMount = () => {
    this.loadData();
  };

  loadData = async () => {
    const companyId = UserPreferenceSingleton.getInstance().getCompanyId();
    if (companyId) {
      const result = await this.props.getAnnouncementList(companyId);
      if (result) this.setFilteredList();
    }
  };

  setFilteredList = () => {
    const { paginatedList } = this.props.pagination;
    this.setState({ filteredList: paginatedList });
  };

  loadListWithPaggingIndex = async (index, isSetTotalRecords = false) => {
    const { announcementList } = this.props.dashboard;
    await this.props.loadListWithPagging(announcementList, index, isSetTotalRecords);
    this.setFilteredList();
  };

  openAnnouncementDetailModal = (e, item) => {
    e.stopPropagation();
    this.setState({ isOpenAnnouncementDetailModal: true, selectedItem: item });
  };

  closeAnnouncementDatailModal = () => {
    this.setState({ isOpenAnnouncementDetailModal: false, selectedItem: undefined });
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <Message text={resError} type={'error'} />;
    }
  }

  render() {
    const { isOpenAnnouncementDetailModal, selectedItem, filteredList } = this.state;
    let { loading } = this.props.dashboard;
    const { currentPage, totalPage, totalRecords } = this.props.pagination;
    return (
      <>
        <Layout expanded={SIDEBAR_MENU_DASHBOARD}>
          <MainWrapper>
            <MainContent>
              <MinContact>
                <RightList>
                  <RightItem>
                    <ItemList>
                      <TextItem>Announcements</TextItem>
                    </ItemList>
                  </RightItem>
                  {this.renderErrors()}
                  {!loading && (!filteredList || filteredList.length === 0) && (
                    <EmptyContain>
                      <EmptyDetail>
                        <EmptyImage src={EmptyLogo} />
                        <TextEmpty>No Announcement</TextEmpty>
                      </EmptyDetail>
                    </EmptyContain>
                  )}
                  <ItemRight>
                    {loading && !filteredList ? (
                      <LoadView>
                        <Loader />
                      </LoadView>
                    ) : (
                      <>
                        {filteredList?.length > 0 && (
                          <MainTab>
                            <MainTable>
                              <TableIt>
                                <Thead>
                                  <Tr>
                                    <Th>Title</Th>
                                    <Th></Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {filteredList?.map((item, i) => {
                                    return (
                                      <Tr key={i}>
                                        <Td>
                                          <HeadTitle
                                            onClick={(e) => {
                                              this.openAnnouncementDetailModal(e, item);
                                            }}>
                                            {item?.title}
                                          </HeadTitle>
                                        </Td>
                                        <Td>
                                          <SubmitButton
                                            className={classNames('btn-save-loading', { loading: loading })}>
                                            <Button
                                              small
                                              isPrimary={true}
                                              type='button'
                                              title='View'
                                              disabled={loading}
                                              onClick={(e) => {
                                                this.openAnnouncementDetailModal(e, item);
                                              }}
                                            />
                                          </SubmitButton>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                                </Tbody>
                              </TableIt>
                            </MainTable>
                            <Pagination
                              textPerPage={'Orders'}
                              current={currentPage}
                              total={totalPage}
                              totalRecords={totalRecords}
                              onChange={this.loadListWithPaggingIndex}
                            />
                          </MainTab>
                        )}
                      </>
                    )}
                  </ItemRight>
                </RightList>
              </MinContact>
            </MainContent>
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              className='modalcenter'
              open={isOpenAnnouncementDetailModal}
              onClose={this.closeAnnouncementDatailModal}
              closeAfterTransition={true}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}>
              <AnnouncementDetailModal onClose={this.closeAnnouncementDatailModal} selectedItem={selectedItem} />
            </Modal>
          </MainWrapper>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.message,
  dashboard: state.dashboard,
  pagination: state.pagination
});

export default connect(mapStateToProps, { getAnnouncementList, loadListWithPagging })(AnnouncementList);
